import { Component, ElementRef, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ElBtnProperties, Eligibility, GBtnProperties, StudentDetailsFilters, PreviewEligibilityKeys, PassoutYearsOptions, DegreeTypes, Severity, Summary, Message, StudentTypeOptions, TableDefaults } from '@enum';
import { btnProperties, iconsList } from '@models';
import { DriveService, EligibilityService, GlobalService } from '@services';
import { ConfirmationService, MessageService } from 'primeng/api';
import { eligibilityAddIcon } from '@assets';
import { TableComponent } from 'src/app/shared/components/table/table.component';


export interface TemplateList {
  category: string;
  category_id: string;
  id: string;
  editable: boolean;
  template_title: string;
  subject_title: string;
  template_content: any;
  collapse: boolean;
  showIcons: boolean;
}

interface Filter {
  label: string;
  value: string;
  condition?: string;
}

interface PreviewData {
  basicCriteria?: Filter[];
  placementFilters?: Filter[];
  customCriteria?: Filter[];
  internshipFilters?: Filter[];
}

type FilterKey = 'campus' | 'department' | 'degree_type' | 'groups';

interface FilterOption {
  filterName: string;
  key: FilterKey;
  array: any[];
}

type SelectedFilters = {
  [key in FilterKey]: any[];
};


@Component({
  selector: 'app-eligibility-criteria',
  templateUrl: './eligibility-criteria.component.html',
  styleUrls: ['./eligibility-criteria.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class EligibilityCriteriaComponent {
  @Input() driveEligibility: any;
  @Input() drive_id: string | null = null;
  @ViewChild('dropdown') dropdown!: ElementRef;
  @ViewChild('multiselect') multiselect!: ElementRef;
  @ViewChild(TableComponent) tableComponent!: TableComponent;
  @Output() publishDrive: EventEmitter<any> = new EventEmitter();
  @Output() criteriaPreview: EventEmitter<any> = new EventEmitter();
  @Output() refreshInfo: EventEmitter<any> = new EventEmitter();
  @Input() criteriaFilters = [];
  freezedFilters: string = '';
  showTable: boolean = false
  isErrorDetected: boolean = false;
  customFieldsError: boolean = false
  isLoadingDetails: boolean = false
  userData: any = localStorage.getItem('user_details')
  accountData: any = localStorage.getItem('account_details')
  accountMetaData: any = localStorage.getItem('account_metadata')
  academicsOptions: any[] = []
  appliedFilterName: string = ''
  menuOptions: any[] = [];
  allCriteria: FormGroup[] = [];
  campusDetails: any[] = [];
  additionalFields: any[] = []
  filteredDepartments: any[] = []
  studentCampusOptions: any[] = []
  studentDetailsOption: any[] = []
  btnProperties: { [key: string]: btnProperties } = ElBtnProperties
  gbtnProperties: { [key: string]: btnProperties } = GBtnProperties
  previewEligibilityKeys: { [key: string]: any } = PreviewEligibilityKeys;
  filteredDegreeSpec: any[] = [];
  StudentDetailsFilters: iconsList[] = StudentDetailsFilters
  sideBarPurpose: string = '';
  eligibilityEnum: any = Eligibility
  placementFilterOptions: any[] = [];
  internShipFilterOptions: any[] = [];
  filterOptions: any[] = [];
  newTemplateName: string = ''
  visible: boolean = false;
  saveTemplateInput: string = ''
  selectedFilter: any[] = []
  filters: any[] = [];
  placementFilters: any[] = [];
  internshipFilters: any[] = [];
  columns: any[] = [];
  captionsList: any = { actions: false, columns: true, download: true, filter: false, search: true, pagination: true, header: true, limit: true, custom_sort: true, setTableDefault: true }
  dataList: any[] = [];
  dataCount: number = 0;
  pageLimits: any[] = [{ label: 10, value: 10 }, { label: 25, value: 25 }, { label: 50, value: 50 }, { label: 100, value: 100 }];
  tableHeight: string = '72vh'
  totalPage: number = 0;
  rowsize: number = 10;
  selectedPage: any = 1;
  searchValue: string = '';
  sort_by: string = 'primary_email';
  sort_order = 1;
  formedQuery: any = {}
  items: any[] = [];
  // dialogPurpose: string = ''
  dialogPurpose: string = '';
  dialogHeader: string = '';
  dialogInputs: { label: string; model: any }[] = [];
  dialogCallback: (() => void) | null = null;
  checkedValues: any[] = [];
  educationalDetailOptions = [
    { label: '10th Percentage/CGPA', value: '10th' },
    { label: '12th Percentage/CGPA', value: '12th' },
    { label: 'Diploma', value: 'diploma' },
    { label: 'UG', value: 'ug' },
    { label: 'PG', value: 'pg' }
  ];

  operationOptions = [
    { label: 'Group', value: 'Group' },
    { label: 'Individual', value: 'Individual' },
  ];

  historyBacklogsOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' }
  ];

  selectedOperation: any;
  groupInputPercentage: string = '';
  groupInputCGPA: string = '';
  selectedEducationalDetails: string[] = [];
  selectedEducationalDetailFields: any[] = [];
  showSelectedEducationalDetailFields = false;
  categories: Array<any> = [];
  templateList: Array<TemplateList> = [];
  _templateList: Array<TemplateList> = [];
  addTemplateSidebar: boolean = false;
  newCategoryName: string = '';
  isLoading: boolean = false;
  selectedCategoryId: string = '';
  showPlaceholder: boolean = false;
  placeholders: Array<any> = [];
  focusedElement: any;
  cursorPositionId: string = '';
  cursor: Array<number> = [];
  selectedGenders: string[] = [];
  selectAllChecked: boolean = false;
  _subjectTitle: string = '';
  _templateContent: string = '';
  _selectedCategory: any;
  _filteredCategory: any;
  _templateTitle: string = '';
  _selectedTemplateId: string = '';
  templateToEdit: string = '';
  saveBtnLoading: boolean = false;
  isActive: string = 'criteria';
  clicked: boolean = false;
  privilege: any = {};
  group_account_id: string = '';
  templateSearchTerm: string = '';
  eligibility: FormGroup;
  studentDetails: FormGroup;
  renderer: any
  placementLabelMap: any = {};
  companyNameMap: any = {};
  offerTypeMap: any = {};
  campushMap: any = {};
  academicMap: any = {};
  fieldMaps: any = {};
  genderOptions: any = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Others', value: 'Others' },
  ];
  StudentTypeOptions = StudentTypeOptions;


  addTemplateForm: FormGroup = new FormGroup({
    category: new FormControl(''),
    template_title: new FormControl('', [Validators.required]),
    subject_title: new FormControl('', [Validators.required]),
    template_content: new FormControl(),
    admissionYear: new FormControl
  });
  showPreviewCriteria: boolean = false;
  previewIndex = 0;
  previewData: PreviewData = {};
  eligibilityAddIcon = eligibilityAddIcon;
  showProgrammesOffered: boolean = false;
  eligibilityOptions: any = [];
  passoutYearsOptions = PassoutYearsOptions;
  filterData: FilterOption[] = [
    {
      filterName: "Campus",
      key: 'campus',
      array: []
    },
    {
      filterName: "Department",
      key: 'department',
      array: []
    },
    {
      filterName: "Degree Type",
      key: 'degree_type',
      array: DegreeTypes
    },
    {
      filterName: "Groups",
      key: 'groups',
      array: []
    }
  ];

  selectedFilters: SelectedFilters = {
    campus: [],
    department: [],
    degree_type: [],
    groups: []
  };
  programsOffersTableCols: any[] = [];
  programsOfferTableCaptions = { checkbox: true, actions: false, columns: false, download: false, filter: false, search: true, pagination: false, header: true, limit: false }
  programsData: any = [];
  listPrograms: any = [];
  programsSearchValue: string = '';
  programsTableHeight: string = '68vh';
  additionalOptions = [
    {
      value: false,
      label: 'Show Selected',
      type: 'selected'
    },
    {
      value: false,
      label: 'Show Not Selected',
      type: 'notSelected'
    }
  ];
  selectedAcademicPrograms: any[][] = [[]];
  currentOpenedIndex: number = 0;
  selectedArray: any[] = [];
  nonSelectedArray: any[] = [];
  disableCriteriaEdit: boolean = false;
  activeIndex: number = 0;
  tableLoader = false;
  downloadFields: any = [];
  showDownloadBar: boolean = false;
  isSelectedAll = false;
  downloadColumnAs = 'excel';
  otherFields = ['user_id', 'account_id', 'last_name', 'mobile_number', 'department_id', 'programme_id', 'degree_id', 'tenth_percentage_CGPA', 'twelfth_percentage_CGPA', 'diploma_percentage_CGPA', 'ug_percentage_CGPA', 'pg_percentage_CGPA'];

  constructor(
    private messageService: MessageService,
    public globalService: GlobalService,
    private fb: FormBuilder,
    private driveService: DriveService,
    private eligibilityService: EligibilityService,
  ) {
    this.accountMetaData = JSON.parse(this.accountMetaData);
    let fields = this.globalService.formatFields(this.accountMetaData.field_labeling);
    const { salary_label } = this.accountMetaData.labeling;
    const { academic_info, basic_info, additional_info } = fields;
    this.fieldMaps = { ...academic_info, ...basic_info, ...additional_info };
    this.eligibility = this.fb.group({
      pass_out_year: [[], Validators.required],
      gender: [[]],
      student_type: [[]],
      tenth_percentage: ['', [Validators.min(1), Validators.max(100)]],
      tenth_cgpa: ['', [Validators.min(1), Validators.max(10)]],
      twelfth_percentage: ['', [Validators.min(1), Validators.max(100)]],
      twelfth_cgpa: ['', [Validators.min(1), Validators.max(10)]],
      diploma_percentage: ['', [Validators.min(1), Validators.max(100)]],
      diploma_cgpa: ['', [Validators.min(1), Validators.max(10)]],
      ug_percentage: ['', [Validators.min(1), Validators.max(100)]],
      ug_cgpa: ['', [Validators.min(1), Validators.max(10)]],
      pg_percentage: ['', [Validators.min(1), Validators.max(100)]],
      pg_cgpa: ['', [Validators.min(1), Validators.max(10)]],
      selected_operation: [''],
      group_percentage: ['', [Validators.min(1), Validators.max(100)]],
      group_cgpa: ['', [Validators.min(1), Validators.max(10)]],
      current_backlogs: ['', Validators.min(1)],
      backlog_history: [''],
      selected_educational_details: [[]]
    });
    this.studentDetails = this.fb.group({
      admissions: ['', Validators.required],
      department: ['', Validators.required],
      degreeType: ['', Validators.required],
      degreeSpecialization: ['', Validators.required],
    });
    this.columns = [
      { field: 'primary_email', header: this.fieldMaps['Email'] ?? 'Email', freezable: true, sortable: true, tableDefault: true, width: '20vw' },
      { field: 'first_name', header: 'Name', freezable: false, sortable: true, tableDefault: true, width: '12vw', combineCols: 'last_name', type: 'combine' },
      { field: 'registration_number', header: this.fieldMaps['Registration Number'] ?? 'Registration Number', freezable: false, sortable: true, tableDefault: true, width: '15vw' },
      { field: 'campus', header: 'Campus', freezable: false, sortable: true, tableDefault: true, width: '15vw' },
      { field: 'passed_out_year', header: 'Pass Out Year', freezable: false, sortable: true, tableDefault: true, width: '12vw' },
      { field: 'department', header: 'Department', freezable: false, sortable: true, tableDefault: true, width: '20vw' },
      { field: 'degree_specialization', header: 'Degree & Specialization', freezable: false, sortable: true, tableDefault: true, width: '20vw' },
      { field: 'specialization_minor', header: 'Specialization Minor', freezable: false, sortable: true, tableDefault: true, width: '13vw' },
      { field: 'dob', header: this.fieldMaps['Date of Birth'] ?? 'Date of Birth', freezable: false, sortable: true, tableDefault: false, width: '10vw' },
      { field: 'country_code', header: this.fieldMaps['Mobile Number'] || 'Mobile Number', freezable: false, sortable: true, tableDefault: false, width: '15vw', type: 'combine', combineCols: 'mobile_number' },
      { field: 'gender', header: this.fieldMaps['Gender'] ?? 'Gender', freezable: false, sortable: true, tableDefault: false, width: '10vw' },
      { field: 'student_type', header: 'Student Type', freezable: false, sortable: true, tableDefault: false, width: '10vw' },
      { field: 'tenth_mark', header: this.fieldMaps['10th Mark'] ?? '10th Mark', freezable: false, sortable: true, tableDefault: false, width: '10vw', combineCols: 'tenth_percentage_CGPA', type: 'combine' },
      { field: 'twelfth_mark', header: this.fieldMaps['12th Mark'] ?? '12th Mark', freezable: false, sortable: true, tableDefault: false, width: '10vw', combineCols: 'twelfth_percentage_CGPA', type: 'combine' },
      { field: 'diploma_mark', header: this.fieldMaps['Diploma Mark'] ?? 'Diploma Mark', freezable: false, sortable: true, tableDefault: false, width: '10vw', combineCols: 'diploma_percentage_CGPA', type: 'combine' },
      { field: 'ug_mark', header: this.fieldMaps['UG Mark'] ?? 'UG Mark', freezable: false, sortable: true, tableDefault: false, width: '10vw', combineCols: 'ug_percentage_CGPA', type: 'combine' },
      { field: 'pg_mark', header: this.fieldMaps['PG Mark'] ?? 'PG Mark', freezable: false, sortable: true, tableDefault: false, width: '10vw', combineCols: 'pg_percentage_CGPA', type: 'combine' },
      { field: 'current_backlogs', header: this.fieldMaps['Current Backlogs'] ?? 'Current Backlogs', freezable: false, sortable: true, tableDefault: false, width: '14vw' },
      { field: 'backlog_history', header: this.fieldMaps['Backlog History'] ?? 'Backlog History', freezable: false, sortable: true, tableDefault: false, width: '14vw' },
      { field: 'internship_count', header: 'Internship Count', freezable: false, sortable: true, tableDefault: true, width: '11vw' },
      { field: 'internship_companies', header: 'Internship Companies', freezable: false, sortable: false, tableDefault: true, width: '14vw' },
      { field: 'internship_offers', header: 'Internship Offer Type', freezable: false, sortable: false, tableDefault: true, width: '14vw' },
      { field: 'internship_category', header: 'Internship Category', freezable: false, sortable: false, tableDefault: true, width: '14vw' },
      { field: 'highest_salary_internship', header: `Highest ${salary_label} Internship`, freezable: false, sortable: false, tableDefault: true, width: '14vw' },
      { field: 'highest_stipend_internship', header: 'Highest Stipend Internship', freezable: false, sortable: false, tableDefault: true, width: '14vw' },
      { field: 'placed_count', header: 'Placed Count', freezable: false, sortable: true, tableDefault: true, width: '11vw' },
      { field: 'placed_companies', header: 'Placed Companies', freezable: false, sortable: false, tableDefault: true, width: '14vw' },
      { field: 'placed_offers', header: 'Placed Offer Type', freezable: false, sortable: false, tableDefault: true, width: '14vw' },
      { field: 'placed_category', header: 'Placed Category', freezable: false, sortable: false, tableDefault: true, width: '14vw' },
      { field: 'highest_salary_placed', header: `Highest ${salary_label} Placed`, freezable: false, sortable: false, tableDefault: true, width: '14vw' },
      { field: 'highest_stipend_placed', header: 'Highest Stipend Placed', freezable: false, sortable: false, tableDefault: true, width: '14vw' }
    ];

    if (localStorage.getItem(TableDefaults.Eligibility)) {
      const defaultFilters = JSON.parse(localStorage.getItem(TableDefaults.Eligibility) ?? '');
      const selectedDefaultFields = defaultFilters.fields;

      this.columns.forEach((each) => {
        each.tableDefault = !!selectedDefaultFields[each.field]
      });
    };

    this.eligibilityOptions = [
      { label: 'Academic Details', value: 'educationalDetails' },
      { label: 'Placement Filters', value: 'placementDetails' },
      { label: 'Custom field Filters', value: 'configurableFilters' },
    ]

    this.programsOffersTableCols = [
      { field: 'full_name', header: 'Campus', freezable: false, sortable: true, tableDefault: true, width: '13vw' },
      { field: 'department', header: 'Department', freezable: false, sortable: true, tableDefault: true, width: '15vw' },
      { field: 'degree_type', header: 'Degree Type', freezable: false, sortable: true, tableDefault: true, width: '10vw' },
      { field: 'programmes_offered', header: 'Programme Offered', freezable: false, sortable: true, tableDefault: true, width: '27vw' },
      { field: 'grouping_name', header: 'Groups', freezable: false, sortable: true, tableDefault: true, width: '15vw' },
    ];

    this.placementFilterOptions = [
      { label: 'Placed Company', value: 'placedCompany' },
      { label: 'Placed Category', value: 'companyCategory' },
      { label: 'Offer Type', value: 'offerType' },
      { label: 'Placed Count', value: 'placedCount' },
      { label: `${salary_label}`, value: 'salary' },
      { label: 'Stipend', value: 'stipend' },
    ];
    
    this.internShipFilterOptions = [
      { label: 'Internship Company', value: 'placedCompany' },
      { label: 'Internship Category', value: 'companyCategory' },
      { label: 'Offer Type', value: 'offerType' },
      { label: 'Internship Count', value: 'placedCount' },
      { label: `${salary_label}`, value: 'salary' },
      { label: 'Stipend', value: 'stipend' },
    ];

    this.previewEligibilityKeys['salary'] = salary_label;

    this.userData = JSON.parse(this.userData)
    this.accountData = JSON.parse(this.accountData)
  }
  hideDropdownOverlay() {
    if (this.dropdown) {
      const overlay = this.dropdown.nativeElement.querySelector('.ui-dropdown-panel');
      this.renderer.setStyle(overlay, 'display', 'none');
    }
  }

  setRange(event: any, type: string, controlName: string, idx: number) {
    const value = event.target.value;
    if (value < 1) {
      this.allCriteria[idx].get(controlName)?.setValue('');
    }

    if (type == 'percentage') {
      if (value > 100) {
        this.allCriteria[idx].get(controlName)?.setValue(100);
      }
    }

    if (type == 'cgpa') {
      if (value > 10) {
        this.allCriteria[idx].get(controlName)?.setValue(10);
      }
    }
  }

  backlogsChange(event: any, idx: number) {
    const value = event.target.value;
    if ((value < 0 || value > 100) || !value) {
      this.allCriteria[idx].get('current_backlogs')?.setValue('');
    }
  }

  hideMultiselectOverlay() {
    if (this.multiselect) {
      const overlay = this.multiselect.nativeElement.querySelector('.ui-multiselect-panel');
      this.renderer.setStyle(overlay, 'display', 'none');
    }
  }

  async ngOnInit() {
    this.group_account_id = this.accountData.group_account_id;
    this.isLoading = true;

    await Promise.all(
      [this.fetchCampusDetail(), this.fetchFilterName(), this.fetchGroupings(), this.getTableDefaults()]
    )
    this.addCriteria();

    this.items = [
      {
        label: 'Save As Template',
        icon: false,
        expand: false,
        command: () => this.showDialog()
      }
    ];

    if (this.criteriaFilters && this.criteriaFilters.length > 0) {
      await this.patchDriveCriteriaFilters();
    }

    this.isLoading = false;
  }

  processStudentFilters(filters: any, idx: number) {
    this.allCriteria[idx].patchValue(filters);
    this.selectedAcademicPrograms[idx] = filters.programs_offered;
  }

  processCriteriaFilter(filter: any, idx: number) {
    this.allCriteria[idx].patchValue(filter);
    this.onEducationalDetailChange(this.allCriteria[idx]);
  }

  async processCustomFields(customFields: any[]) {
    for (const element of customFields) {
      await this.onChangeCampus(element, true);
      await this.academicsChange(element, true);
      this.customFieldsChange(element, true);
      this.filterConditionChange(element);
    }
  }

  async processPlacementFields(placementFields: any[]) {
    for (const element of placementFields) {
      await this.placementFieldsChange(element, true);
      this.filterConditionChanges(element);
    }
  }


  async patchDriveCriteriaFilters() {
    this.allCriteria = [];
    this.selectedFilter = [];

    this.criteriaFilters = JSON.parse(JSON.stringify(this.criteriaFilters));

    const tasks = this.criteriaFilters.map(async (
      filter: { template_id: string, filters: any, custom_fields: any[], placement_fields: any[], internship_fields: any[], studentFilters: any },
      idx: number
    ) => {
      this.addCriteria();
      this.processStudentFilters(filter.studentFilters, idx);
      this.processCriteriaFilter(filter.filters, idx);

      // custom fields
      this.filters[idx] = filter.custom_fields || [];
      // placement fields
      this.placementFilters[idx] = filter.placement_fields || [];
      this.internshipFilters[idx] = filter.internship_fields || [];
      this.selectedFilter.push(filter.template_id);

      await Promise.all([
        this.processCustomFields(this.filters[idx]),
        this.processPlacementFields(this.placementFilters[idx]),
        this.processPlacementFields(this.internshipFilters[idx])
      ]);
    });

    await Promise.all(tasks);
  }


  async fetchCampusDetail() {
    const response = await this.driveService.fetchCampusDetails(this.group_account_id);
    if (!response) return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.CAMPUSERR });

    this.campusDetails = response;
    this.updateFilterData();
  };

  private updateFilterData() {
    this.filterData[0].array = [];
    this.filterData[1].array = [];
    this.filteredDepartments = [];
    const programsList: any = [];

    const uniqueItems = {
      campus: new Set<string>(),
      department: new Set<string>()
    };

    this.campusDetails.forEach((item: any) => {
        this.addUniqueItem(uniqueItems.campus, item.account_id, 0, item.full_name);
        this.addUniqueItem(uniqueItems.department, item.department_id, 1, item.department);

      item.programmes_offered = item.degree_name + ' - ' + item.specialization + (item.specialization_minor ? ' - ' + item.specialization_minor : '');

      programsList.push({
        ...item,
        visible: true
      });
    });

    this.listPrograms = programsList;
    this.studentCampusOptions = this.filterData[0].array;
    this.sortFilter([0, 1]);
  };

  fetchGroupings() {
    this.globalService.fetchAllGrouping().then((response: any) => {
      if (response) {
        this.filterData[3].array = response.data;
      }
    });
  };

  async fetchFilterName() {
    const response = await this.eligibilityService.getAllEligibilityFilter(this.group_account_id);
    if (response.success) {
      this.filterOptions = response.data
    }
    else {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.FILTERERR,
      });
    }
  }

  async onSubMenuClick() {
    this.sideBarPurpose = 'student_details_filters';
    this.visible = true;
  }

  onEducationalDetailChange(item: any) {
    this.clicked = false;
    this.showSelectedEducationalDetailFields = false;
    this.selectedEducationalDetailFields = [];
    const selectedEducationalDetails = item.get('selected_educational_details')?.value;
    if (selectedEducationalDetails?.length) {
      selectedEducationalDetails.forEach((selectedDetail: any) => {
        const fields: { [key: string]: { label: string, controlName: string, unitType: string }[] } = {
          '10th': [{ label: '10th', controlName: 'tenth_percentage', unitType: 'percentage' }, { label: '10th', controlName: 'tenth_cgpa', unitType: 'cgpa' }],
          '12th': [{ label: '12th', controlName: 'twelfth_percentage', unitType: 'percentage' }, { label: '12th', controlName: 'twelfth_cgpa', unitType: 'cgpa' }],
          'diploma': [{ label: 'Diploma', controlName: 'diploma_percentage', unitType: 'percentage' }, { label: 'Diploma', controlName: 'diploma_cgpa', unitType: 'cgpa' }],
          'ug': [{ label: 'UG', controlName: 'ug_percentage', unitType: 'percentage' }, { label: 'UG', controlName: 'ug_cgpa', unitType: 'cgpa' }],
          'pg': [{ label: 'PG', controlName: 'pg_percentage', unitType: 'percentage' }, { label: 'PG', controlName: 'pg_cgpa', unitType: 'cgpa' }]
        };

        if (fields[selectedDetail]) {
          this.selectedEducationalDetailFields.push(...fields[selectedDetail]);
        }
      });
    }

    const operations = ['10th', '12th', 'diploma', 'ug', 'pg'];
    this.selectedEducationalDetailFields.sort((a, b) => {
      return operations.indexOf(a.label.toLowerCase()) - operations.indexOf(b.label.toLowerCase());
    });    
    this.showSelectedEducationalDetailFields = true;
    
    if (item) {
      const isIndividual = item.get('selected_operation')?.value === 'Individual';

      operations.forEach((op) => {
        const baseName = ['10th', '12th'].includes(op) ? (op === '10th' ? 'tenth' : 'twelfth') : op;
        const percentageControl = item.get(`${baseName}_percentage`);
        const cgpaControl = item.get(`${baseName}_cgpa`);

        if (percentageControl && cgpaControl) {
          if (isIndividual && selectedEducationalDetails.includes(op)) {
            percentageControl.setValidators([Validators.required]);
            cgpaControl.setValidators([Validators.required]);
          } else {
            percentageControl.clearValidators();
            cgpaControl.clearValidators();
            percentageControl.setValue('');
            cgpaControl.setValue('');
          }
          percentageControl.updateValueAndValidity();
          cgpaControl.updateValueAndValidity();
        }
      });
    }
  }


  async openCategory(name: string) {
    if (name === 'criteria') {
      this.isActive = 'criteria';
      this.categories.forEach((val: any) => (val.isActive = false));
      this.selectedCategoryId = '';
      this._selectedCategory = '';
    } else {
      this.isActive = 'eligible_students';
    }
  };

  async showDialog() {
    this.saveTemplateInput = ''
    this.dialogPurpose = 'save_template'
    this.visible = true;
  }

  async addFilter(index: any): Promise<any> {
    this.clicked = false;
    if (!this.filters[index]) {
      this.filters[index] = [];
    }
    let arrayIndex = this.filters[index].length - 1
    const previousData = this.filters[index][arrayIndex]
    if (arrayIndex >= 0) {
      if (!previousData.account_id ||
        !previousData.pass_out_year ||
        !previousData.attribute_id ||
        !previousData.condition ||
        !this.checkValidValue(previousData.value)
      ) {
        return this.messageService.add({
          severity: Severity.ERROR,
          summary: Summary.VALIDATION,
          detail: Message.CUSTOMFILTERWARN,
        });
      }
    }
    this.filters[index].push({ attribute_id: null, condition: null, value: null });
    return true
  }

  async placementFilter(index: any, type: 'placement' | 'internship'): Promise<any> {
    const selectedType = type === 'placement' ? this.placementFilters : this.internshipFilters;

    this.clicked = false;
    if (!selectedType[index]) {
      selectedType[index] = [];
    }
    let arrayIndex = selectedType[index].length - 1
    const previousData = selectedType[index][arrayIndex];
    if (arrayIndex >= 0) {
      if (!previousData?.placementField ||
        !previousData?.condition ||
        !this.checkValidValue(previousData.value)
      ) {
        return this.messageService.add({
          severity: Severity.WARN,
          summary: Summary.VALIDATION,
          detail: Message.PLACEMENTFILTER,
        });
      }
    };
    selectedType[index].push({ placementField: null, condition: null, value: '' });
    return true
  }

  async saveExists(item: any, index: any) {
    const [filters, studentFilters] = this.assignAndValidation(item, index);
    this.formedQuery = filters;

    const payload = {
      id: this.selectedFilter[index],
      group_account_id: this.group_account_id,
      filters,
      studentFilters,
      custom_fields: this.formCustomFields(this.filters[index]),
      placement_fields: this.formPlacementFields(this.placementFilters[index]),
      internship_fields: this.formPlacementFields(this.internshipFilters[index])
    };

    this.isLoading = true;
    const response: any = await this.eligibilityService.saveExistingFilter(payload);
    this.isLoading = false
    if (response.success) {
      this.messageService.add({
        severity: Severity.SUCCESS,
        summary: Summary.SUCCESS,
        detail: Message.TEMPLATESAVE,
      });
    }
    else {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.FILTERSAVEERR,
      });
    }
  }

  formPlacementFields(filter: any) {
    const filterArray: any[] = []
    filter?.forEach((each: any) => {
      const obj: any = {}
      obj.placementField = each.placementField
      obj.condition = each.condition
      obj.value = each.value
      filterArray.push(obj)
    });
    return filterArray
  }

  formCustomFields(filter: any) {
    const filterArray: any[] = []
    filter?.forEach((each: any) => {
      filterArray.push({
        attribute_id: each.attribute_id,
        value: each.value,
        condition: each.condition,
        account_id: each.account_id,
        pass_out_year: each.pass_out_year,
        type: each.type
      })
    });
    return filterArray
  }


  async saveTemplate(item: any, index: any) {
    const [filters, studentFilters] = this.assignAndValidation(item, index);
    this.formedQuery = filters;

    const payload = {
      group_account_id: this.group_account_id,
      filter_name: this.saveTemplateInput.trim(),
      filters,
      studentFilters,
      custom_fields: this.formCustomFields(this.filters[index]),
      placement_fields: this.formPlacementFields(this.placementFilters[index]),
      internship_fields: this.formPlacementFields(this.internshipFilters[index])
    };

    const response: any = await this.eligibilityService.saveFilter(payload);
    this.isLoading = false;
    if (response.success) {
      this.visible = false
      this.isLoadingDetails = false
      this.dialogPurpose = '';
      this.filterOptions.push({ label: payload.filter_name, value: response.data.template_id });

      this.messageService.add({
        severity: Severity.SUCCESS,
        summary: Summary.SUCCESS,
        detail: Message.TEMPLATECREATE,
      });
    }
    else {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.TEMPLATESAVEERR,
      });
    }
  };

  assignAndValidation(item: any, idx: number) {
    let obj: any = {};
    let studentFilters: any = {};
    for (const controlField in item.controls) {
      if (
        controlField === "pass_out_year"
      ) {
        studentFilters[controlField] = item.controls[controlField].value;
      }
      else {
        const value = item.controls[controlField].value;
        obj[controlField] = value;
      }
    }

    studentFilters['programs_offered'] = this.selectedAcademicPrograms[idx].map((item: any) => {
      return {
        academic_programme_id: item.academic_programme_id,
        account_id: item.account_id,
        department_id: item.department_id,
        degree_id: item.degree_id,
        programme_id: item.programme_id,
        degree_name: item.degree_name,
        specialization: item.specialization,
        specialization_minor: item.specialization_minor,
        degree_type: item.degree_type,
        department: item.department,
        full_name: item.full_name
      }
    });
    return [obj, studentFilters];
  };

  async filterChange(item: any, index: any) {
    this.clicked = false
    const body = { group_account_id: this.group_account_id, filter_id: this.selectedFilter[index] }
    this.isLoading = true
    const response = await this.eligibilityService.getFilterById(body)
    this.isLoading = false;

    if (!response.success) {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.FILTERERR,
      });
      return
    }

    this.processStudentFilters(response.data.studentFilters, index);
    this.processCriteriaFilter(response.data.filters, index);

    this.appliedFilterName = response.data.filter_name;
    this.filters[index] = response.data.custom_fields || [];
    this.placementFilters[index] = response.data.placement_fields || [];
    this.internshipFilters[index] = response.data.internship_fields || [];

    await Promise.all([
      this.processCustomFields(this.filters[index]),
      this.processPlacementFields(this.placementFilters[index]),
      this.processPlacementFields(this.internshipFilters[index])
    ]);
  }

  async rowLengthChange(length: any) {
    this.rowsize = length;
    this.selectedPage = 1
    this.viewEligibleStudents()
  }

  async pageChange(selectedPage: any) {
    this.selectedPage = selectedPage
    this.viewEligibleStudents()
  }

  async searchValueChange(searchValue: any) {
    this.searchValue = searchValue;
    this.selectedPage = 1;
    await this.viewEligibleStudents();
  };

  async searchChange(searchValue: any) {
    this.searchValue = searchValue;
    this.selectedPage = 1;
  };

  async sortChange(event: any) {
    this.sort_by = event.field;
    this.sort_order = event.order;
    await this.viewEligibleStudents();
  };

  removeFilter(i: number, index: number) {
    this.filters[index].splice(i, 1);
  }

  removePlacementFilter(i: number, index: number, type: 'placement' | 'internship') {
    if (type === 'placement') {
      this.placementFilters[index].splice(i, 1);
    }
    else {
      this.internshipFilters[index].splice(i, 1);
    }
  };

  async educationCategory(item: any) {
    this.clicked = false;
    item.get('selected_educational_details')?.setValue([])
    item.get('group_percentage')?.setValue('')
    item.get('group_cgpa')?.setValue('')
    item.get('tenth_cgpa')?.setValue('')
    item.get('tenth_percentage')?.setValue('')
    item.get('twelfth_percentage')?.setValue('')
    item.get('twelfth_cgpa')?.setValue('')
    item.get('diploma_percentage')?.setValue('')
    item.get('diploma_cgpa')?.setValue('')
    item.get('ug_percentage')?.setValue('')
    item.get('ug_cgpa')?.setValue('')
    item.get('pg_percentage')?.setValue('')
    item.get('pg_cgpa')?.setValue('')
    this.showSelectedEducationalDetailFields = false;
    this.selectedEducationalDetailFields = []

    if (item.get('selected_operation')?.value === 'Individual') {
      item.get('selected_educational_details')?.setValidators([Validators.required]);
      item.get('group_cgpa')?.clearValidators();
      item.get('group_percentage')?.clearValidators();
      item.get('tenth_cgpa')?.setValidators([Validators.min(1), Validators.max(10)]);
      item.get('tenth_percentage')?.setValidators([Validators.min(1), Validators.max(100)]);
      item.get('twelfth_percentage')?.setValidators([Validators.min(1), Validators.max(100)]);
      item.get('twelfth_cgpa')?.setValidators([Validators.min(1), Validators.max(10)]);
      item.get('diploma_percentage')?.setValidators([Validators.min(1), Validators.max(100)]);
      item.get('diploma_cgpa')?.setValidators([Validators.min(1), Validators.max(10)]);
      item.get('ug_percentage')?.setValidators([Validators.min(1), Validators.max(100)]);
      item.get('ug_cgpa')?.setValidators([Validators.min(1), Validators.max(10)]);
      item.get('pg_percentage')?.setValidators([Validators.min(1), Validators.max(100)]);
      item.get('pg_cgpa')?.setValidators([Validators.min(1), Validators.max(10)]);
    }
    else if (item.get('selected_operation')?.value === 'Group') {
      item.get('group_cgpa')?.setValidators([Validators.required]);
      item.get('group_percentage')?.setValidators([Validators.required]);
      item.get('selected_educational_details')?.clearValidators();
      item.get('tenth_cgpa')?.clearValidators();
      item.get('tenth_percentage')?.clearValidators();
      item.get('twelfth_percentage')?.clearValidators();
      item.get('twelfth_cgpa')?.clearValidators();
      item.get('diploma_percentage')?.clearValidators();
      item.get('diploma_cgpa')?.clearValidators();
      item.get('ug_percentage')?.clearValidators();
      item.get('ug_cgpa')?.clearValidators();
      item.get('pg_percentage')?.clearValidators();
      item.get('pg_cgpa')?.clearValidators();
    }
    else {
      item.get('group_cgpa')?.clearValidators();
      item.get('group_percentage')?.clearValidators();
      item.get('selected_educational_details')?.clearValidators();
    }
    item.get('group_cgpa')?.updateValueAndValidity();
    item.get('group_percentage')?.updateValueAndValidity();
    item.get('selected_educational_details')?.updateValueAndValidity();
    item.get('tenth_cgpa')?.updateValueAndValidity();
    item.get('tenth_percentage')?.updateValueAndValidity();
    item.get('twelfth_percentage')?.updateValueAndValidity();
    item.get('twelfth_cgpa')?.updateValueAndValidity();
    item.get('diploma_percentage')?.updateValueAndValidity();
    item.get('diploma_cgpa')?.updateValueAndValidity();
    item.get('ug_percentage')?.updateValueAndValidity();
    item.get('ug_cgpa')?.updateValueAndValidity();
    item.get('pg_percentage')?.updateValueAndValidity();
    item.get('pg_cgpa')?.updateValueAndValidity();
  }

  addCriteria() {
    this.clicked = false;
    let obj: any = {};
    const rawValues = this.eligibility.getRawValue();
    for (let [key, value] of Object.entries(rawValues)) {
      obj[key] = [value];
    }

    const clonedEligibility = this.fb.group(obj);
    this.allCriteria.push(clonedEligibility);
    this.selectedAcademicPrograms.push([]);
  }

  deleteCriteria(event: any, index: any) {
    if (this.disableCriteriaEdit) return;

    event.stopPropagation();
    this.allCriteria.splice(index, 1);
    this.filters.splice(index, 1)
    this.placementFilters.splice(index, 1)
    this.internshipFilters.splice(index, 1)
    this.selectedFilter.splice(index, 1)
    this.selectedAcademicPrograms.splice(index, 1);
  }

  customFieldsChange(filter: any, patchValue?: boolean) {
    filter.additional_info.forEach((option: any) => {
      if (option.value === filter.attribute_id) {
        filter.fieldOptions = option.fieldOptions;
        filter.type = option.type;
      }
    });
    if (!patchValue) {
      filter.conditionOptions = []
      filter.condition = ""
      filter.value = ""
    }
    const commonOptions = [
      { label: 'Equal', value: 'Equal' },
      { label: 'Not Equal', value: 'Not Equal' },
    ];
    filter.conditionOptions = filter.type === 'Dropdown - Single'
      ? commonOptions
      : [...commonOptions, { label: 'Contains', value: 'Contains' }, { label: 'Not Contains', value: 'Not Contains' }];
  }

  filterConditionChange(filter: any) {
    if (filter.type === 'Dropdown - Single' || filter.type === 'Dropdown - Multiple') {
      filter.inputType = 'multiselect'
    }
    else {
      filter.inputType = 'input'
    }
  };

  filterConditionChanges(filter: any) {
    if (filter.placementField === 'placedCount' || filter.placementField === 'salary' || filter.placementField === 'stipend') {
      filter.inputType = 'input'
    }
    else {
      filter.inputType = 'multiselect'
    }
  };

  showError() {
    this.messageService.add({
      severity: Severity.ERROR,
      summary: Summary.VALIDATION,
      detail: Message.MANDATORY,
    });

    return false;
  }

  checkValidValue(value: any) {
    return Array.isArray(value) ? value.length : (value == 0 || value);
  }

  clearAllSelectedFilters(idx: number) {

    const item = this.allCriteria[idx];

    const controlsToReset = [
      "pass_out_year",
      "gender",
      "student_type",
    ]

    controlsToReset.forEach(control => {
      item.get(control)?.setValue('');
      item.get(control)?.clearValidators();
      item.get(control)?.updateValueAndValidity();
    });

    this.selectedFilter = [];
    this.selectedAcademicPrograms = [];
    this.filters = [];
    this.placementFilters = [];
    this.internshipFilters = [];
    this.clearEducationFilters(idx);
  }

  clearEducationFilters(idx: number) {

    const item = this.allCriteria[idx];

    const controlsToReset = [
      'selected_operation',
      'selected_educational_details',
      'group_percentage',
      'group_cgpa',
      'tenth_cgpa',
      'tenth_percentage',
      'twelfth_percentage',
      'twelfth_cgpa',
      'diploma_percentage',
      'diploma_cgpa',
      'ug_percentage',
      'ug_cgpa',
      'pg_percentage',
      'pg_cgpa',
      'current_backlogs',
      'backlog_history',
    ];

    controlsToReset.forEach(control => {
      item.get(control)?.setValue('');
      item.get(control)?.clearValidators();
      item.get(control)?.updateValueAndValidity();
    });

  };

  validateEligibility() {
    this.clicked = true;
    let errorData = false;
    // Check for errors in each criteria
    errorData = this.allCriteria.some(element => !element.valid);
    if (errorData) {
      return this.showError();
    };

    this.allCriteria.forEach((_item: any, index: any) => {
      if (!this.selectedAcademicPrograms[index]?.length) {
        errorData = true;
      }
    });

    if (errorData) {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.VALIDATION,
        detail: Message.PROGRAMMEOFFERED,
      });
      return false;
    };

    // Check for errors in filters
    errorData = this.filters.some(each =>
      each.some((element: any) =>
        !(element.account_id && element.pass_out_year && element.attribute_id && element.condition && this.checkValidValue(element.value))
      )
    );

    if (errorData) {
      return this.showError();
    }


    errorData = this.placementFilters.some(each =>
      each.some((element: any) =>
        !(element.placementField && element.condition && this.checkValidValue(element.value))
      )
    );

    if (errorData) {
      return this.showError();
    };

    errorData = this.internshipFilters.some(each =>
      each.some((element: any) =>
        !(element.placementField && element.condition && this.checkValidValue(element.value))
      )
    );

    if (errorData) {
      return this.showError();
    };

    return true;
  }

  async viewEligibleStudents(refresh?: boolean) {
    const filterPayload = this.getEligibilityPayload();
    if(!filterPayload) return;

    if (refresh) {
      if (this.tableComponent) {
        this.tableComponent.currentPage = 1;
      }
      this.selectedPage = 1;
    };

    const payload = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
      sort_by: this.sort_by,
      sort_order: this.sort_order,
      user_id: this.userData.user_id,
      group_account_id: this.group_account_id,
      filter: filterPayload,
      fields: this.columns.map((col: any) => col.field).concat(this.otherFields)
    };

    if (!this.showTable) this.isLoading = true;
    this.tableLoader = true;
    const response = await this.eligibilityService.viewEligibleStudent(payload);
    this.tableLoader = false;
    this.isLoading = false;
    if (response.success) {
      this.showTable = true;
      this.clicked = true;
      this.freezedFilters = JSON.stringify(filterPayload);
      this.dataList = response.data.data;
      this.dataCount = response.data.total_count;
      this.totalPage = this.dataCount === 0 ? 1 : Math.ceil(this.dataCount / this.rowsize);
      setTimeout(() => {
        this.tableComponent.searchValue = this.searchValue;
      });
    }
  }

  formFilterPayload(formGroup: any) {
    return formGroup.map((item: any) => {
      const {
        selected_educational_details,
        department,
        selected_operation,
        campus,
        backlog_history,
        ...newItem
      } = item;

      return {
        ...newItem,
        backlog_history,
        selected_educational_details: selected_educational_details || {}
      };
    });
  }


  assign() {
    const array: any[] = [];
    for (const [index, item] of this.allCriteria.entries()) {
      let obj: any = {};
      const studentFilters: any = {};
      for (const controlField in item.controls) {
        if (controlField === 'pass_out_year') {
          studentFilters[controlField] = item.controls[controlField].value;
        }
        else {
          obj[controlField] = item.controls[controlField].value;
        }
      }

      studentFilters['programs_offered'] = this.selectedAcademicPrograms[index].map((item) => {
        return {
          academic_programme_id: item.academic_programme_id,
          account_id: item.account_id,
          department_id: item.department_id,
          degree_id: item.degree_id,
          programme_id: item.programme_id,
          degree_name: item.degree_name,
          specialization: item.specialization,
          specialization_minor: item.specialization_minor,
          degree_type: item.degree_type,
          department: item.department,
          full_name: item.full_name
        }
      });

      obj['index'] = index; // Add index to the object
      const custom_fields: any[] = []
      if (this.filters[index]?.length) {
        this.filters[index].forEach((element: any) => {
          const custom = {
            account_id: element.account_id,
            pass_out_year: Number(element.pass_out_year),
            condition: element.condition,
            attribute_id: element.attribute_id,
            value: element.value,
            type: element.type,
          }
          custom_fields.push(custom)
        });
      }
      const placement_fields: any[] = []
      if (this.placementFilters[index]?.length) {
        this.placementFilters[index].forEach((element: any) => {
          const filter = {
            placementField: element.placementField,
            condition: element.condition,
            value: element.value
          }
          placement_fields.push(filter)
        });
      }

      const internship_fields: any[] = [];
      if (this.internshipFilters[index]?.length) {
        this.internshipFilters[index].forEach((element: any) => {
          const filter = {
            placementField: element.placementField,
            condition: element.condition,
            value: element.value
          }
          internship_fields.push(filter)
        });
      }

      const filters = {
        custom_fields,
        placement_fields,
        internship_fields,
        studentFilters,
        filters: obj
      };

      array.push(filters);
    }
    return array;
  }

  handleFilterChange(selectedFilter: string, event: any) {
    if (selectedFilter === 'campus') {
      this.filterData[1].array = [];
      this.selectedFilters['department'] = [];
      this.handleCampusFilter(event.value);
    }
  };

  private handleCampusFilter(selectedCampuses: string[]) {
    const eventValueSet = new Set(selectedCampuses);

    this.filteredDepartments = this.campusDetails.filter((element: any) =>
      eventValueSet.has(element.account_id)
    );

    const departmentSet = new Set<string>();

    const selected = this.filteredDepartments.length ? this.filteredDepartments : this.campusDetails;

    selected.forEach((element: any) => {
      this.addUniqueItem(departmentSet, element.department_id, 1, element.department);
    });

    this.sortFilter([0, 1]);
  };

  addUniqueItem(set: Set<string>, id: string, index: number, label: string) {
    if (!set.has(id)) {
      set.add(id);
      this.filterData[index].array.push({ label, value: id });
    }
  };

  private sortFilter(sortItems: number[]) {
    sortItems.forEach((each) => {
      this.filterData[each].array.sort((a: any, b: any) => a.label.localeCompare(b.label));
    })
  };


  async onChangeCampus(filter: any, patchValue?: boolean) {
    if (!patchValue) {
      filter.attribute_id = null;
      filter.condition = null;
      filter.value = ""
      filter.pass_out_year = null;
      filter.additional_info = [];
      filter.customFieldOptions = [];
    }
    filter.academicOptions = PassoutYearsOptions;
  };

  async academicsChange(filter: any, patchValue?: any) {
    if (!patchValue) {
      filter.attribute_id = null
      filter.condition = null
      filter.value = ""
      filter.additional_info = [];
      filter.customFieldOptions = [];
    };

    const payload = {
      group_account_id: this.group_account_id,
      account_id: filter.account_id,
      pass_out_year: filter.pass_out_year
    };
    this.isLoading = true;
    const key = `${payload.account_id}-${payload.pass_out_year}`;
    if (!this.academicMap[key]) {
      this.academicMap[key] = await this.globalService.fetchMappedFields(payload);
    };
    
    const result = this.academicMap[key];
    this.isLoading = false;
    if (result.success && result.data) {
      filter.additional_info = result.data.additional_info
        .filter((custom: any) => custom.field_type !== "Terms & Conditions" && custom.field_type !== "File Upload")
        .map(({ attribute_name, field_type, attribute_label, validation }: any) => {
          return {
            label: attribute_label ?? attribute_name,
            value: attribute_name,
            type: field_type,
            fieldOptions: validation.field_options
          };
        });
      
      filter.customFieldOptions = filter.additional_info.map((each: any) => {
        return {
          label: each.label,
          value: each.value
        }
      });
    }
    else {

      this.messageService.add({
        severity: Severity.WARN,
        summary: Summary.EDITFIELDS,
        detail: Message.FIELDMAP,
      });
    }
  };

  clearInputs() {
    this.downloadFields = [];
    this.showDownloadBar = false;
    this.isSelectedAll = false;
    this.downloadColumnAs = 'excel';
  }

  downloadEligibilityData() {
    this.downloadFields = this.columns.map((each) => {
      return {
        label: each.header,
        value: each.field,
        active: false
      }
    });

    this.showDownloadBar = true;
  }

  selectAllDownloadData() {
    this.downloadFields.forEach((element: any) => {
      element.active = this.isSelectedAll;
    });
  }

  onDropDownChange() {
    this.isSelectedAll = this.downloadFields.every((element: any) => element.active);
  }

  async downloadData() {

    const downloadHeaderMap: any = {};
    const fieldSelectedForDownload: any = [];

    this.downloadFields.forEach((element: any) => {
      if (element.active) {
        downloadHeaderMap[element.value] = element.label;
        fieldSelectedForDownload.push(element.value);
      }
    });

    if (!fieldSelectedForDownload.length) {
      return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.SELECTFIELDSDWNLD });
    };

    const filterPayload = this.getEligibilityPayload();
    if (!filterPayload) return;

    const payload = {
      group_account_id: this.group_account_id,
      fields: fieldSelectedForDownload.concat(this.otherFields),
      filter: filterPayload,
      limit: this.dataCount,
      page: 1,
      type: 'download',
      user_id: this.userData.user_id,
      downloadHeader: downloadHeaderMap,
      downloadType: this.tableComponent.downloadColumnAs,
      sort_by: this.sort_by,
      sort_order: this.sort_order,
      search: this.searchValue
    };

    this.isLoading = true;
    const response = await this.eligibilityService.viewEligibleStudent(payload)
    this.isLoading = false;
    if (response.success) {
      this.clearInputs();
      return this.messageService.add({ severity: Severity.SUCCESS, summary: Summary.SUCCESS, detail: Message.BACKGROUNDPROCESS });
    }
    this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.DATADWNLDERR })
  };

  async placementFieldsChange(filter: any, patchValue?: boolean) {
    if (!patchValue) {
      filter.conditionOptions = []
      filter.condition = ""
      filter.value = ""
      filter.field_options = ""
    }
    this.isLoading = true;
    const commonOptions = [
      { label: 'Equal', value: 'Equal' },
      { label: 'Not Equal', value: 'Not Equal' },
    ];
    filter.conditionOptions = (filter.placementField === 'placedCount' || filter.placementField === 'salary' || filter.placementField === 'stipend')
      ? [...commonOptions, { label: 'Less than or equal', value: 'lessThanOrEqual' }, { label: 'Greater than or equal', value: 'greaterThanOrEqual' }]
      : commonOptions

    if (filter.placementField === 'placedCompany') {
      filter.field_options = await this.driveService.getCompanies();;
      this.placementLabelMap['placedCompany'] = {
        label: 'company_name',
        value: 'company_id'
      }

      filter.field_options.forEach((company: any) => {
        this.companyNameMap[company.company_id] = company.company_name;
      });
    }
    else if (filter.placementField === 'companyCategory') {
      filter.field_options = await this.driveService.getCompanyCategories();
      this.placementLabelMap['companyCategory'] = {
        label: 'company_category',
        value: 'company_category'
      }
    }
    else if (filter.placementField === 'offerType') {
      filter.field_options = await this.driveService.getDriveTypes();
      this.placementLabelMap['offerType'] = {
        label: 'job_type',
        value: 'job_type_id'
      }

      filter.field_options.forEach((type: any) => {
        this.offerTypeMap[type.job_type_id] = type.job_type;
      });
    }
    this.isLoading = false;
  }

  preventInvalidCharacters(event: KeyboardEvent) {
    const invalidChars = ['-', 'e', 'E'];

    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  };

  validateInput(event: Event, filter: any) {
    const inputElement = event.target as HTMLInputElement;
    let value = parseInt(inputElement.value.trim(), 10);

    if (value > this.driveService.salaryLimit) {
      value = this.driveService.salaryLimit;
    } else if (value < 0) {
      value = 0;
    }

    inputElement.value = value.toString();
    filter.value = value;
  }

  getEligibilityPayload() {
    if (!this.validateEligibility()) {
      return false;
    }

    const formGroup: any = this.assign();
    return this.formFilterPayload(formGroup)
  };

  publishTheDrive(onlyChanges = false, existingFiler: any[] = []) {
    const filterPayload = this.getEligibilityPayload();
    if (!filterPayload) return;

    if (this.freezedFilters !== JSON.stringify(filterPayload)) {
      if (onlyChanges) {
        return this.refreshInfo.emit(this.formatPreviewCriteriaOnlyValuesChanged(existingFiler));
      }
      return this.refreshInfo.emit(this.formatPreviewCriteria());
    };

    let previewCriteria: any[] = [];

    for (let idx in this.allCriteria) {

      const filter = this.allCriteria[idx];
      const customFilters = this.filters[idx];
      const placementFilters = this.placementFilters[idx];
      const internshipFilters = this.internshipFilters[idx];
      const programsOffered = this.selectedAcademicPrograms[idx];

      if (!filter.valid) {
        this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.MANDATORY })
        return
      }

      previewCriteria.push(this.formatCriteria(filter.value, placementFilters, internshipFilters, customFilters, programsOffered));
    };


    if (onlyChanges) {
      previewCriteria = this.formatPreviewCriteriaOnlyValuesChanged(existingFiler);
    }

    this.publishDrive.emit({ total_count: this.dataCount, criteriaFilter: filterPayload, previewCriteria, type: onlyChanges ? 'republish' : 'publish' })
  };


  previewCriteria(event: any, index: number) {
    event.stopPropagation();
    this.previewIndex = index;

    const { basicCriteria, placementFilters, internshipFilters, customCriteria } = this.formatCriteria(this.allCriteria[index].value, this.placementFilters[index], this.internshipFilters[index], this.filters[index], this.selectedAcademicPrograms[index]);

    this.previewData = { basicCriteria, placementFilters, internshipFilters, customCriteria };
    this.showPreviewCriteria = true;
  }

  formatCriteria(basicFilters: any, placementFilters: any, internshipFilters: any, customFilters: any, selectedAcademicPrograms: any = []) {
    const formattedCriteria: Filter[] = []
    const formattedPlacementFilters: Filter[] = []
    const formattedInternshipFilters: Filter[] = []
    const formattedCustomFilters: Filter[] = []

    const criteria = [
      { label: 'Campus', key: 'full_name' },
      { label: 'Department', key: 'department' },
      { label: 'Degree Specialization', key: 'programmes_offered' }
    ];

    if (selectedAcademicPrograms.length) {
      const data = {
        full_name: new Set<string>(),
        department: new Set<string>(),
        programmes_offered: new Set<string>()
      };

      selectedAcademicPrograms.forEach((program: any) => {
        let programmes_offered = `${program.degree_name} - ${program.specialization}`;
        if (program.specialization_minor) {
          programmes_offered += ` - ${program.specialization_minor}`;
        };

        data.full_name.add(program.full_name);
        data.department.add(program.department);
        data.programmes_offered.add(programmes_offered);
      });

      criteria.forEach(({ label, key }) => {
        formattedCriteria.push({
          label,
          value: Array.from(data[key as keyof typeof data]).join(', ')
        });
      });
    }

    for (let key in basicFilters) {
      const mapKey = this.previewEligibilityKeys[key];
      if (!mapKey) continue;
      let value = '';

      if (key == 'pass_out_year') {
        value = basicFilters[key].join(', ');
      }
      else {
        value = this.formatValue(basicFilters[key]);
      }

      formattedCriteria.push({
        label: mapKey,
        value
      });
    };

    if (placementFilters) {
      for (let item of placementFilters) {
        if (!item.placementField || !item.conditionOptions) continue;
        let value = this.formatValue(item.value);
        if (item.placementField === 'placedCompany') {
          value = item.value.map((company: any) => this.companyNameMap[company]).join(', ');
        };
        if (item.placementField === 'offerType') {
          value = item.value.map((type: any) => this.offerTypeMap[type]).join(', ');
        };

        formattedPlacementFilters.push({
          label: this.previewEligibilityKeys[item.placementField],
          condition: item.conditionOptions.find((condition: any) => condition.value === item.condition).label,
          value
        })
      };
    };

    if (internshipFilters) {
      for (let item of internshipFilters) {
        if (!item.placementField || !item.conditionOptions) continue;
        let value = this.formatValue(item.value);
        if (item.placementField === 'placedCompany') {
          value = item.value.map((company: any) => this.companyNameMap[company]).join(', ');
        };
        if (item.placementField === 'offerType') {
          value = item.value.map((type: any) => this.offerTypeMap[type]).join(', ');
        };

        formattedInternshipFilters.push({
          label: this.previewEligibilityKeys[item.placementField],
          condition: item.conditionOptions.find((condition: any) => condition.value === item.condition).label,
          value
        })
      };
    }

    if (customFilters) {
      for (let item of customFilters) {
        if (!item.attribute_id) continue;
        formattedCustomFilters.push({
          label: item.attribute_id,
          condition: item.conditionOptions.find((condition: any) => condition.value === item.condition).label,
          value: this.formatValue(item.value)
        })
      };
    }

    return { basicCriteria: formattedCriteria, placementFilters: formattedPlacementFilters, internshipFilters: formattedInternshipFilters, customCriteria: formattedCustomFilters };
  };

  formatValue(value: any) {
    return Array.isArray(value) ? value.join(', ') : value;
  }

  onHidePreviewCriteria() {
    this.showPreviewCriteria = false;
  }

  formatPreviewCriteria() {
    const previewCriteria: any[] = [];
    for (let idx in this.allCriteria) {
      previewCriteria.push(this.formatCriteria(this.allCriteria[idx].value, this.placementFilters[idx], this.internshipFilters[idx], this.filters[idx], this.selectedAcademicPrograms[idx]));
    };

    return previewCriteria;
  };

  formatPreviewCriteriaOnlyValuesChanged(existingFilters: any[]) {
    const previewCriteria: any[] = [];

    for (let idx in this.allCriteria) {
      // current filters - changed filters
      const currentFiler = this.allCriteria[idx].value;
      const customFilters = this.filters[idx] || [];
      const placementFilters = this.placementFilters[idx] || [];
      const internshipFilters = this.internshipFilters[idx] || [];
      const programsFilters = this.selectedAcademicPrograms[idx] || [];

      // existing filters 
      const existingFilter = existingFilters[idx] || {};
      const filters = existingFilter.filters || {};
      const studentFilters = existingFilter.studentFilters || {};
      const custom_fields = existingFilter.custom_fields || [];
      const placement_fields = existingFilter.placement_fields || [];
      const internship_fields = existingFilter.internship_fields || [];

      // store modified filters
      const modifiedBasicCriteria: any = {};
      const modifiedPlacementFilters: any = [];
      const modifiedInternshipFilters: any = [];
      const modifiedCustomCriteria: any = [];
      let modifiedProgramsOffered: any = [];

      const criteria = ['account_id', 'department_id', 'degree_id']; // 'programmes_offered

      if (programsFilters.length) {

        const existingVals = {
          account_id: new Set<string>(),
          department_id: new Set<string>(),
          degree_id: new Set<string>()
        };

        const currentValue = {
          account_id: new Set<string>(),
          department_id: new Set<string>(),
          degree_id: new Set<string>()
        };

        programsFilters.forEach((program: any) => {
          currentValue.account_id.add(program.account_id);
          currentValue.department_id.add(program.department_id);
          currentValue.degree_id.add(program.degree_id);
        });

        studentFilters['programs_offered']?.forEach((program: any) => {
          existingVals.account_id.add(program.account_id);
          existingVals.department_id.add(program.department_id);
          existingVals.degree_id.add(program.degree_id);
        })

        criteria.forEach((each: any) => {
          const existingArray = Array.from(existingVals[each as keyof typeof existingVals]);
          const currentArray = Array.from(currentValue[each as keyof typeof currentValue]);
          if (this.checkValuesChanged(existingArray, currentArray)) {
            modifiedProgramsOffered = programsFilters;
          }
        });
      };


      for (let key in currentFiler) {
        const mapKey = this.previewEligibilityKeys[key];
        if (!mapKey) continue;

        if (key === 'pass_out_year') {
          const existingValue = studentFilters[key] || [];
          const newValue = currentFiler[key] || [];

          if (this.checkValuesChanged(existingValue, newValue)) {
            modifiedBasicCriteria[key] = newValue;
          }
        }
        else {
          const existingValue = filters[key];
          const newValue = currentFiler[key];

          if (this.checkValuesChanged(existingValue, newValue)) {
            modifiedBasicCriteria[key] = newValue;
          }
        }
      };


      for (let index in placementFilters) {
        const newValue = placementFilters[index];
        const existingValue = placement_fields[index] || {};

        for (let key of ['condition', 'value', 'placementField']) {
          if (this.checkValuesChanged(existingValue[key], newValue[key])) {
            modifiedPlacementFilters.push(newValue);
            break;
          }
        }
      };

      for (let index in internshipFilters) {
        const newValue = internshipFilters[index];
        const existingValue = internship_fields[index] || {};

        for (let key of ['condition', 'value', 'placementField']) {
          if (this.checkValuesChanged(existingValue[key], newValue[key])) {
            modifiedInternshipFilters.push(newValue);
            break;
          }
        }
      };

      for (let index in customFilters) {
        const newValue = customFilters[index];
        const existingValue = custom_fields[index] || {};

        for (let key of ['condition', 'value', 'placementField']) {
          if (this.checkValuesChanged(existingValue[key], newValue[key])) {
            modifiedCustomCriteria.push(newValue);
            break;
          }
        }
      };

      previewCriteria.push(this.formatCriteria(modifiedBasicCriteria, modifiedPlacementFilters, modifiedInternshipFilters, modifiedCustomCriteria, modifiedProgramsOffered));
    };

    return previewCriteria;
  };

  public arraysAreEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = [...arr1].sort((a, b) => a - b);
    const sortedArr2 = [...arr2].sort((a, b) => a - b);

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  }

  public checkValuesChanged(existingValue: any, newValue: any) {
    if (Array.isArray(existingValue)) {
      return !this.arraysAreEqual(existingValue, newValue)
    };

    return existingValue !== newValue;
  };

  public arraysOfObjectsAreEqual(arr1: any[], arr2: any) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      const keys = Object.keys(arr1[i]);
      if (keys.length !== Object.keys(arr2[i]).length) {
        return false;
      }
      for (let key of keys) {
        if (arr1[i][key] !== arr2[i][key]) {
          return false;
        }
      }
    }
    return true;
  }

  openSidebar(idx: number) {
    this.isLoading = true;
    this.showProgrammesOffered = true;
    this.currentOpenedIndex = idx;
    this.fillProgramsOfferTable();
    this.isLoading = false;
  };

  onHideSidebar() {
    this.showProgrammesOffered = false;
    this.currentOpenedIndex = 0;
    this.filterData[1].array = [];
    this.programsSearchValue = '';
    this.tableComponent.searchValue = '';

    this.selectedFilters = {
      campus: [],
      department: [],
      degree_type: [],
      groups: [],
    }

    this.programsData.forEach((item: any) => {
      item.selected = false;
    });

    this.additionalOptions.forEach((item: any) => {
      item.value = false;
    });
  };

  setProgrammsOffer() {

    let academicProgramId = this.listPrograms.filter((item: any) => item.selected);

    if (!academicProgramId.length) {
      return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.SELECTPROGRAMME });
    };

    this.selectedAcademicPrograms[this.currentOpenedIndex] = academicProgramId;
    this.onHideSidebar();
  };

  async clearAllFilters() {

    this.filterData[1].array = [];
    this.programsSearchValue = '';
    this.tableComponent.searchValue = '';

    this.selectedFilters = {
      campus: [],
      department: [],
      degree_type: [],
      groups: [],
    }

    this.filteredDepartments = [];
    this.updateFilterData();
    this.fillProgramsOfferTable([]);
  };

  applyFilter() {
    this.fillProgramsOfferTable([]);
  }

  onProgrammesSearch(event: any) {
    this.programsSearchValue = event;
    this.fillProgramsOfferTable([]);
  };

  onProgrammesSearchChange(event: any) {
    this.programsSearchValue = event;
  }

  private getSelectedFilters() {
    const { campus: selectedCampusIds, department: selectedDepartmentIds } = this.selectedFilters;

    const selectedFilters = { ...this.selectedFilters };

    const selectedCampuses = new Set(selectedCampusIds);
    const selectedDepartments = new Set(selectedDepartmentIds);

    selectedFilters.department = [];
    const isCampusSelected = selectedCampuses.size > 0;

    this.campusDetails.forEach((each: any) => {
      const campusMatch = selectedCampuses.has(each.account_id);
      const departmentMatch = selectedDepartments.has(each.department_id);

      if ((!isCampusSelected && departmentMatch) || (campusMatch && departmentMatch)) {
        selectedFilters.department.push({
          account_id: each.account_id,
          department_id: each.department_id
        });
      }
    });

    return selectedFilters;
  };

  fillProgramsOfferTable(event?: any) {
    const finalData = [];

    const { campus, department, degree_type, groups } = this.getSelectedFilters();
    const showOnlySelected = this.additionalOptions[0].value;
    const showOnlyUnselected = this.additionalOptions[1].value;
    const selectedAcademicPrograms = event || this.selectedAcademicPrograms[this.currentOpenedIndex] || [];

    const academic_programme_ids = new Set(selectedAcademicPrograms.map((item: any) => item.academic_programme_id));
    const searchValue = this.programsSearchValue ? this.programsSearchValue.toLowerCase() : null;
    const departmentMap = department.length ? new Set(department.map((item: any) => `${item.account_id}-${item.department_id}`)) : null;

    for (const item of this.listPrograms) {
      item.visible = true;
      if (academic_programme_ids.has(item.academic_programme_id)) item.selected = true;

      if (!(showOnlySelected && showOnlyUnselected)) {
        if (showOnlySelected && !item.selected) item.visible = false;
        if (showOnlyUnselected && item.selected) item.visible = false;
      }

      if (searchValue && !item.programmes_offered.toLowerCase().includes(searchValue)) item.visible = false;

      if (campus.length && !campus.includes(item.account_id)) item.visible = false;

      if (departmentMap && !departmentMap.has(`${item.account_id}-${item.department_id}`)) item.visible = false;

      if (degree_type.length && !degree_type.includes(item.degree_type)) item.visible = false;

      if (groups.length && !groups.includes(item.grouping_id)) item.visible = false;

      finalData.push(item);
    };

    finalData.sort((a, b) => a.full_name.localeCompare(b.full_name));

    this.programsData = finalData.filter((item: any) => item.visible);

  };

  async getTableDefaults() {

    const payload = {
      user_id: this.userData.user_id,
      type: TableDefaults.Eligibility,
      group_account_id: this.group_account_id
    };

    if (localStorage.getItem(TableDefaults.Eligibility)) {
      return this.applyDefaultFilters(JSON.parse(localStorage.getItem(TableDefaults.Eligibility) ?? ''));
    };

    const response = await this.globalService.getTableDefaults(payload);
    if (response.success && response.data) {
      localStorage.setItem(TableDefaults.Eligibility, JSON.stringify(response.data));
      this.applyDefaultFilters(response.data);
    };
  };

  applyDefaultFilters(defaultFilters: any) {
    const { searchValue } = defaultFilters;
    this.searchValue = searchValue;
    setTimeout(() => {
      if(this.tableComponent) this.tableComponent.searchValue = searchValue;
    });
  };

  async setTableDefaults() {

    const defaultCols: any = {};

    for (let col of this.columns) {
      defaultCols[col.field] = col.tableDefault;
    };

    const payload = {
      user_id: this.userData.user_id,
      type: TableDefaults.Eligibility,
      fields: defaultCols,
      searchValue: this.searchValue,
      group_account_id: this.group_account_id
    };

    localStorage.setItem(TableDefaults.Eligibility, JSON.stringify({
      fields: payload.fields,
      searchValue: payload.searchValue
    }));

    await this.globalService.setTableDefaults(payload);
    this.messageService.add({
      severity: Severity.SUCCESS,
      summary: Summary.SUCCESS,
      detail: Message.FILTERSAVE
    });
  };

}
