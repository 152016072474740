import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMessageComponent } from './custom-message.component';
import { CustomMessageRoutingModule } from './custom-message-routing.module';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [CustomMessageComponent],
  imports: [
    CommonModule,
    CustomMessageRoutingModule,
    DropdownModule,
    CalendarModule,
    FormsModule
  ],
  exports: [CustomMessageComponent]
})
export class CustomMessageModule { 
  constructor() {
  }
}
