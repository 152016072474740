export const fileSize = [
    { label: '100KB', value: '100kb' },
    { label: '500KB', value: '500kb' },
    { label: '1MB', value: '1mb' },
    { label: '5MB', value: '5mb' },
];

export const resumeTemplate = {
    attribute_name: 'Upload Resume',
    show: false,
    account_mandatory: false,
    size: '',
};

export function studentOptionsMenu() {
    return [
        { label: 'Basic Info', value: 'basic_info', isActive: true },
        { label: 'Academic Info', value: 'academic_info', isActive: false },
        { label: 'Additional Info', value: 'additional_info', isActive: false },
        { label: 'Resume', value: 'resume', isActive: false },
    ];
}

export function additionalInfoMenu() {
    return [
        { label: 'All', value: 'all_fields', isActive: false },
        { label: 'Active Fields', value: 'active_fields', isActive: true },
    ];
}

export function settingsOptions() {
    return [
        { label: 'Student Fields', value: 'student_fields', isActive: true },
        { label: 'Account Settings', value: 'account_settings', isActive: false },
        { label: 'Platform Settings', value: 'platform_settings', isActive: false },
        { label: 'College Profile', value: 'college_profile', isActive: false },
        { label: 'Edit Fields', value: 'edit_fields', isActive: false },
        { label: 'Whatsapp Settings', value: 'wp_settings', isActive: false }
    ];
}

export function accountSettingsInfo() {
    return [
        {label: 'Change Email', value: 'primary_email'},
        {label: 'New Password', value: 'password'},
    ]
}

export function psOptions() {
    return [
        { label: 'General', value: 'general', isActive: true },
        { label: 'Labeling', value: 'labeling', isActive: false },
        {
            label: 'Download File Format',
            value: 'download_file_format',
            isActive: false,
        },
        {
            label: 'Download Data Format',
            value: 'resume_data_format',
            isActive: false,
        }
    ];
}

export function generalOptions() {
    return [{ label: 'Email Notification', value: 'email_notification' }];
}

export function whatsappOptions() {
    return [{ label: 'Whatsapp Integration', value: 'wp_integration' }];
}

export function labelingOptions() {
    return [
        {
            label: 'Drive Label',
            value: 'drive_label',
            options: [
                { label: 'Drives', value: 'Drives' },
                { label: 'Jobs', value: 'Jobs' },
            ],
        },
        {
            label: 'Drive Category Label',
            value: 'categoty_label',
            options: [
                { label: 'Drive Category', value: 'Drive Category' },
                { label: 'Company Category', value: 'Company Category' },
            ],
        },
        {
            label: 'Drive Opt-In Label',
            value: 'opt_in_label',
            options: [
                { label: 'Opt-In', value: 'Opt-In' },
                { label: 'Apply', value: 'Apply' },
            ],
        },
        {
            label: 'Drive Opt-Out Label',
            value: 'opt_out_label',
            options: [
                { label: 'Opt-Out', value: 'Opt-Out' },
                { label: 'Decline', value: 'Decline' },
            ],
        },
        {
            label: 'Drive Salary Label',
            value: 'salary_label',
            options: [
                { label: 'Salary', value: 'Salary' },
                { label: 'CTC', value: 'CTC' },
            ],
        }
    ];
}


export function downloadFileFormatOptions() {
    return [
        { label: 'Platform Resume', value: 'resume_name_format' },
        { label: 'Additional Fields', value: 'student_additional_fields_file_format' },
        { label: 'Drive Resume', value: 'drive_resume_file_format' },
        { label: 'Drive Offer Letter', value: 'offer_letter_format' },
        { label: 'Drive Confirmation Letter', value: 'confirmation_letter_format' },
        { label: 'Drive Optional Form', value: 'drive_optional_form_file_format' }
    ]
}

export function formatListOptions() {
    return [
        { label: 'Email', value: '${email}', content: '  ---  Email of the student' },
        { label: 'Name', value: '${name}', content: '  ---  Name of the student' },
        { label: 'Registration No', value: '${registration_number}', content: '  ---  Registration number of the student' },
        { label: 'Campus Name', value: '${campus_name}', content: '  ---  Campus name of the student' },
        { label: 'Department Name', value: '${department_name}', content: '  ---  Department name of the student' },
        { label: 'Pass Out Year', value: '${pass_out_year}', content: '  ---  Pass Out Year of the student' },
        { label: 'Degree Name', value: '${degree_name}', content: '  ---  Degree name of the student' },
        { label: 'Drive Name', value: '${drive_name}', content: '  ---  Name of the drive (Used only inside drive download format)' },
        { label: 'Company Name', value: '${company_name}', content: '  ---  Name of the company (Used only inside drive download format)' },
    ]
}

export function resumeFormatOptions() {
    return [
        {
            label: 'Default',
            value: 'default',
            content: 'When you choose the default format, the student\'s resume will be downloaded irrespective of their degree'
        },
        {
            label: 'Degree & Specialization Based',
            value: 'degree_specialization',
            content: "The student's resume will be downloaded for the preferred degree and specialization where you have the privilege to choose them with or without campus details"
        },
    ]
}

export function resumeFormatSettings() {
    return [
        { label: 'Download File Format', value: 'download_file_format' },
        { label: 'Download Resume Campus', value: 'download_resume_campus' },
    ]
}

export const STBtnProperties = {
    upload: {
        btnName: 'Upload',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },

}

export const collegeProfileOptions = [
    { label: 'Upload Logo', value: 'upload_logo' },
    { label: 'College Name', value: 'college_name' },
    { label: 'Sub Domain', value: 'sub_domain' },
    { label: 'Custom Domain', value: 'custom_domain' },
    { label: 'College Email', value: 'college_email' },
    { label: 'From Email', value: 'from_email' },
    { label: 'Email Singature', value: 'email_signature' },
    { label: 'PWA Name', value: 'pwa_name' },
]