import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionOptions,
  DateStatus,
  DriveStatus,
  EBtnProperties,
  Eglobal,
  MenuOptions,
  ProfileDesignationOptions,
  RadioOptions,
  StipendRange,
  salaryTypeOptions,
  shortAnswerOptions,
  stdInterestedOptions,
  PatDriveStatus,
  CreateOptions,
  Severity,
  Summary,
  Message
} from '@enum';
import { actions, btnProperties, iconsList } from '@models';
import { DriveService, FileUploadService, GlobalService, ValidationService } from '@services';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { v4 as uuidv4 } from 'uuid';
import { DriveEmailTemplateComponent } from '../drive-email-template/drive-email-template.component';
import { EligibilityCriteriaComponent } from '../eligibility-criteria/eligibility-criteria.component';
import { RoundsComponent } from '../rounds/rounds.component';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { trashIcon, plusIcon, drivePattern } from '@assets';
export interface Field {
  fieldType: string;
  question: string;
  options: Array<any>;
  answer: string;
  mandatory: boolean;
  hide: boolean;
  edit: boolean;
}
interface DegreeDetail {
  account_id: string;
  degree_id: string;
  department_id: string;
  programme_id: string;
  degree_spec: string;
}
interface ProfileDetail {
  selectedCampus?: string[];
  degree: DegreeDetail[]
  clone: number;
  profile: string;
  salary: boolean;
  stipend: boolean;
  stipendBasis: string;
  salaryBasis: string;
  stipendType: string;
  salaryType: string;
  fixedStipend: null | number;
  rangeStipend: null | number;
  fixedSalary: number;
  rangeSalary: null | number;
  salaryBreak: boolean;
  salaryFixed: string;
  salaryVariable: string;
  fromRange: string;
  toRange: string;
}
interface SalaryInformation {
  [role: string]: ProfileDetail[];
}
interface DynamicProfileFormGroups {
  [profileKey: string]: FormGroup[];
}

type TabTypes = 'publish' | 'republish' | 'refresh';

type SubTitleMessages = {
  [key in TabTypes]: { title: string; subTitle: string };
};


@Component({
  selector: 'app-drive-details',
  templateUrl: './drive-details.component.html',
  styleUrls: ['./drive-details.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class DriveDetailsComponent implements OnChanges, OnDestroy{
  // variable declarations...
  @Input() createDrive: boolean = false;
  @Input() driveType: string = 'string';
  @Input() driveObjective: string = 'Placement';
  @Input() driveActivity: boolean = false;
  @Output() driveEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(DriveEmailTemplateComponent) driveEmailTemplateComponent!: DriveEmailTemplateComponent;
  @ViewChild(EligibilityCriteriaComponent) eligibilityCriteriaComponent!: EligibilityCriteriaComponent;
  @ViewChild(RoundsComponent) roundsComponent!: RoundsComponent;
  @ViewChild('accordion0') accordion!: ElementRef;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['driveType'] && this.driveType) {
      this.initializeCreateDriveFormGroup();
      this.createDriveInfo.get('profileType')?.setValue(this.driveType);
    }
  }
  accountData: any = localStorage.getItem('account_details');
  userData: any = localStorage.getItem('user_details');
  accountMetaData: any = localStorage.getItem('account_metadata');
  optionalInformation!: FormArray;
  driveEnum: any = {};
  globalEnum: any = Eglobal;
  menuOptions: iconsList[] = MenuOptions
  createOptions: iconsList[] = CreateOptions;
  createDriveOptions: iconsList[] = []
  dateStatus: iconsList[] = DateStatus
  stdInterestedOptions: iconsList[] = stdInterestedOptions
  shortAnswerOptions: iconsList[] = shortAnswerOptions
  driveStatus: actions[] = DriveStatus
  actionOptions: actions[] = ActionOptions
  radioOptions: actions[] = RadioOptions
  salaryTypeOptions: actions[] = salaryTypeOptions;
  profileDesignationColumns: any[] = ProfileDesignationOptions
  stipendRange: iconsList[] = StipendRange
  salaryRange: iconsList[] = [];
  btnProperties: { [key: string]: btnProperties } = EBtnProperties
  profileBasedOptions: { [key: string]: any } = { allow: 'single', profile: false, degree: false }
  group_account_id: string = '';
  currentDate: Date = new Date();
  currentMonthName: string = '';
  currentYear: number = 0;
  dates: any[] = [];
  editDrive: boolean = false;
  createDriveInfo!: FormGroup;
  originalDriveInfo: any = {};
  profileDesignation: string[] = []
  finalProfileDesignation: any = {};
  commonDegree: any = {};
  isLoading: boolean = false;
  isLoadingDetails: boolean = false;
  selectedAdmissionYear: string = '';
  fieldSelectedForDownload: any[] = [];
  downloadColumnAs: string = 'excel';
  driveTypes: any = [];
  companies: any = [];
  company_url: string = '';
  company_categories: any = [];
  pre_placement_talk: boolean = false;
  campusSpecificData: any;
  years: any = [];
  opt_out_reason: boolean = false;
  ismandatory: boolean = false;
  ishide: boolean = false;
  emailChecked: boolean = false;
  edit: boolean = false;
  documentChecked: boolean = false;
  spreedsheetChecked: boolean = false;
  imageChecked: boolean = false;
  videoChecked: boolean = false;
  presentationChecked: boolean = false;
  pdfChecked: boolean = false;
  audioChecked: boolean = false;
  currentTab: string = 'details';
  driveUpdatingData: any;
  campusDetails: any;
  filteredDegreeSpec: any[] = [];
  filteredDepartments: any[] = []
  selectedDegrees: any[] = [];
  clicked: boolean = false;
  fields: any[] = [
    { fieldType: '',
    question: '',
    options: [],
    answer: '',
    mandatory: false,
    hide: false,
    edit: false
  }
  ];
  //Dates
  driveStartDate: any;
  driveEndDate: any
  placementStartDate: any;
  placementEndDate: any;
  preferenceOption: any[] = [];
  prePlacementTalkDate: any;
  preferenceDisabled: boolean = true;
  studentDetailsCampusOptions = [];
  selectedDegreeOptions: any = {};
  internshipDuration = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
  ];
  internshipTimeLine = [
    { label: 'Week(s)', value: 'weekly' },
    { label: 'Month(s)', value: 'monthly' }
  ];
  activeIndex: number[] = [0, 1, 2, 3, 4, 5];
  dateStatusSelected: string = 'confirmedDate';
  shortAnswerOptionSelected: string = 'text';
  interestStatusSelected: string = 'single';
  formData: any[] = [];
  enteredChipsData: string[] = [];
  selectedFileSize: string = '';
  field_type: { type: string; code: string; }[] = [];
  file_size: string[] = [];
  profileFormGroups:{ [key: string]: FormGroup[] } = {};
  finalRound: number = 0;
  email_templates = {};
  drive_id: string = '';
  patternLoading = false;
  allEmailTemplates: any = {};
  patDriveStatus = PatDriveStatus;
  displayPublishDialog: boolean = false;
  sendEmailNotification: boolean = false;
  criteriaFilters: any = [];
  dateClashDrives: any = [];
  showDateClashDialog: boolean = false;
  tablecolumns: any = [];
  totalPage: number = 0;
  pageSize: number = 1;
  currentPage: number = 1;
  driveLabel: string = 'Drive';
  dataToDisplay: any = [];
  mapAccountIdWithCampus: any = {};
  showRepublishDialog: boolean = true;
  enableNotification: boolean = false;
  subTitleMessages: SubTitleMessages = {
    publish: {
      title: 'Confirmation',
      subTitle: 'After Publishing, Drive Eligibility mail will be sent to the Eligible Students. Do you wish to continue?'
    },
    republish: {
      title: 'Confirmation',
      subTitle: 'Republishing the drive will update the students list as per the newly updated eligibility criteria.'
    },
    refresh: {
      title: 'info',
      subTitle: 'Please refresh the drive to get the students list updated as per the eligibility criteria given below. '
    }
  };
  currentTabSelected: TabTypes = 'publish';
  criteriaToDisplay: any = [];
  breadcrumbs: any[] = [];
  payloadToPublish: any = null;
  trashIcon = trashIcon;
  plusIcon = plusIcon;
  drivePattern = drivePattern;
  label_singular: string = 'Drive';
  isEmailEnabled: boolean = false;
  originalDriveLastDate: any;
  initialOptionalForm: any[] = []
  initialProfileObject: any = {};
  isDriveInRepublishQueue: boolean = false;
  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],     
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }], 
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }]    
    ]
  };  
  defaultEditorPlaceholder: string = '';
  isFileUploading: boolean = false;
  reasons = [
    {
      label: "Salary is not satisfactory",
      controlName: "opt_salary"
    },
    {
      label: "Location is not suitable",
      controlName: "opt_location"
    },
    {
      label: "Role is not suitable",
      controlName: "opt_role"
    },
    {
      label: "Uninterested in the work",
      controlName: "opt_work"
    }
  ];

  fileUploadOptions = [
    { label:  'Document', value: 'documentChecked' },
    { label:  'Spreadsheet', value: 'spreadsheetChecked' },
    { label:  'CSV', value: 'csvChecked' },
    { label:  'Image', value: 'imageChecked' },
    { label:  'Video', value: 'videoChecked' },
    { label:  'Presentation', value: 'presentationChecked' },
    { label:  'PDF', value: 'pdfChecked' },
    { label:  'Audio', value: 'audioChecked' }
  ];

  constructor(public globalService: GlobalService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private validationService: ValidationService,
    private router: Router,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private fileUploadService: FileUploadService,
    private driveService: DriveService) {
      this.formatLabelData();
    }

  formatLabelData() {
    this.globalService.driveLabelSubject.subscribe((label) => {
      const { label_singular } = this.globalService.getLabels(label.labeling);
      this.label_singular = label_singular;
      const { drive_label, salary_label } = label.labeling;
      this.driveLabel = drive_label;
      this.defaultEditorPlaceholder = `${label_singular} Description`;
      this.tablecolumns = [
        { field: 'name', header: `${drive_label} Name`, width: '10vw' },
        { field: 'drive_objective', header: `${drive_label} Purpose`, width: '10vw' },
        { field: 'campus', header: 'Campus', width: '10vw' },
        { field: 'pass_out_year', header: 'Pass Out Year', width: '10vw' },
        { field: 'degree', header: 'Degree', width: '10vw' },
        { field: 'date_status', header: 'Date Status', width: '10vw' },
        { field: 'drive_status', header: `${drive_label} Status`, width: '10vw' },
      ];
      this.createDriveOptions = [
        { label: 'Company Information', value: 'companyInfo', isActive: true },
        { label: `${label_singular} Information`, value: 'driveInfo', isActive: false },
        { label: `Profile & ${salary_label} Information`, value: 'salaryInfo', isActive: false },
        { label: 'Placement Information', value: 'addInfo', isActive: false },
        { label: 'Optional Form', value: 'optForm', isActive: false }
      ];

      this.driveEnum = {
        ALLOW: 'Allow to select the interested profiles',
        PROFILEBASED: `Profile based ${salary_label} / Stipend`,
        DEGREEBASED: `Degree based ${salary_label} / Stipend`,
        ADDNEWFIELD: `Add New ${salary_label}/Stipend`,
        REMOVEFIELD: `Remove ${salary_label}/Stipend`,
        SALARYBREAK: `Enable ${salary_label} Breakup`,
      };

      this.salaryRange = [
        { label: `Fixed ${salary_label}`, value: 'fixed' },
        { label: 'Range', value: 'range' },
        { label: 'To be Announced', value: 'toBeAnnounced' }
      ]

    })
  }

  initializeCreateDriveFormGroup() {
    this.createDriveInfo = this.fb.group({
      allowToSelectedMultiple: [false],
      company: ['', Validators.required],
      companyCategory: ['', Validators.required],
      company_location: [[], [Validators.required, this.validationService.arrayMinLengthValidator(1)]],
      companyUrl: [''],
      degree: [false],
      description: [''],
      companyLogo: [''],
      driveName: ['', [Validators.required, Validators.maxLength(25), this.noInvalidCharacters]],
      driveStatus: [PatDriveStatus.saved],
      driveNumber: [''],
      driveSpoc: [''],
      driveType: ['', Validators.required],
      lastDate: ['', Validators.required],
      optional_form_email: [false],
      opt_out_reason: [false],
      placementDate: ['', Validators.required],
      pre_placement_talk: [false],
      preference_count: [null],
      allow_priority: [false],
      profile: [false],
      profileBased: [false],
      profileDesignation: [this.profileDesignation, this.validationService.arrayMinLengthValidator(1)],
      profileType: [''],
      resume_selection: [false],
      allowToSelectProfile: [false],
      internshipDuration: [''],
      internshipTimeLine: [''],
      selectedCampus: [[]],
      talk_date: [''],
      talk_venue: [''],
      objective: [''],
      optionalInformation: this.fb.array([]),
      drive_attachments: [[]],
    });

    this.reasons.forEach((item) => {
      this.createDriveInfo.addControl(item.controlName, new FormControl(false));
    });

    this.optionalInformation = this.createDriveInfo.get(
      'optionalInformation'
    ) as FormArray;
  }

  noInvalidCharacters(control: AbstractControl): ValidationErrors | null {
    const invalidCharPattern = /[^a-zA-Z0-9 _-]/;
    const hasInvalidChar = invalidCharPattern.test(control.value);
    return hasInvalidChar ? { 'invalidCharacter': true } : null;
  }
  

  async fetchDriveSpecific() {
    const response = await this.driveService.getSpecificDrive({ drive_id: this.drive_id });
    this.driveUpdatingData = response.data[0];

    if(!this.driveUpdatingData) {
      this.router.navigate(["/drives"])
    }
  }

  async fetchEmailTemplates() {
    const response = await this.globalService.fetchAllTemplate({
      group_account_id: this.group_account_id,
      template_type: 'driveTemplates',
      category: 'all'
    });

    this.allEmailTemplates = response.data.reduce((acc: any, template: any) => {
      if (!acc[template.category]) {
        acc[template.category] = [];
      }
      acc[template.category].push(template);
      return acc;
    }, {});
  }


  async fetchingData(drive_id?: string) {
    this.isLoading = true;
    this.group_account_id = this.accountData.group_account_id;
  
    const fetchOperations = [
      this.fetchCampus(),
      this.fetchCampusDetails(),
      this.getDriveTypes(),
      this.getCompanies(),
      this.getCompanyCategories(),
    ];

    if(drive_id){
      fetchOperations.push(this.fetchDriveSpecific(), this.fetchEmailTemplates(), this.checkDriveInRepublishQueue());
    }

    await Promise.all(fetchOperations);
    this.isLoading = false;
  }

  async getDriveTypes() {
    this.driveTypes = await this.driveService.getDriveTypes();;
  }

  async getCompanies() {
     this.companies = await this.driveService.getCompanies();;
  }

  async getCompanyCategories() {
    this.company_categories = await this.driveService.getCompanyCategories();;
  }

  async checkDriveInRepublishQueue() {
    this.isDriveInRepublishQueue = await this.driveService.checkDriveInRepublishQueue(this.drive_id);
  };

  async ngOnInit() {
    this.isLoading = true;
    this.field_type = [
      { type: 'Radio Button', code: 'radiobutton' },
      { type: 'Long Answer', code: 'longanswer' },
      { type: 'Short Answer', code: 'shortanswer' },
      { type: 'Dropdown', code: 'dropdown' },
      { type: 'Checkboxes', code: 'checkbox' },
      { type: 'File Upload', code: 'fileupload' },
      { type: 'Terms & Conditions', code: 'termscondition' }
    ];
    
    this.file_size = [ "100kb", "500kb", "1mb", "5mb"]

    this.createDriveOptions.forEach((item: iconsList) => {
      item.isActive = (item.value === 'companyInfo')
    });
    this.activeIndex = [0];
    this.initializeCreateDriveFormGroup();
    if (this.driveType){
      this.createDriveInfo.get('profileType')?.setValue(this.driveType);
    }
    this.accountData = JSON.parse(this.accountData)
    this.userData = JSON.parse(this.userData)
    this.accountMetaData = JSON.parse(this.accountMetaData)
    this.preferenceDisabled = true;
    this.isEmailEnabled = this.accountMetaData.general?.email_notification;
    if(this.createDrive){
      await this.fetchingData();
    }
    
    this.route.queryParams.subscribe(async (params) => {
      if (params['id']) {
        this.patternLoading = true;
        this.switchCurrentTab(this.driveService.navigationState);
        this.driveService.navigationState = 0;
        this.isLoading = true;
        this.createDrive = false;
        this.drive_id = params['id'];
        this.initializeCreateDriveFormGroup();
        await this.fetchingData(this.drive_id);
        await this.populateDriveData(this.driveUpdatingData);
        this.originalDriveInfo = this.createDriveInfo.value;
        this.updateBredCrumb();
        this.checkDateClash(false);
        this.isLoading = false;
        this.patternLoading = false;
      }
    });
  }

  updateBredCrumb() {
    const breadcrumbs = [
      { label: `${this.driveLabel}`, url: `/${this.driveLabel.toLowerCase()}` },
      { label: this.originalDriveInfo.driveName , url: ''}
    ];
    this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  switchCurrentTab(state: number) {
    this.changeCreateInfo(this.createOptions[state]);
    this.createTabChange(this.createOptions[state]);
  }

  async populateDriveData(driveUpdatingData: any) {
    try {
      this.createDrive = false;
      if (this.createDriveInfo) {
        this.drivePathValue(driveUpdatingData);
        this.email_templates = driveUpdatingData.email_templates || {};
        this.criteriaFilters = driveUpdatingData.criteriaFilters || [];
        this.finalRound = driveUpdatingData.round_count + 1;
        this.originalDriveLastDate = driveUpdatingData.lastDate;
        this.initialOptionalForm = driveUpdatingData.optionalFormData;
        this.initialProfileObject = {
          profile_designation: driveUpdatingData.profile_designation,
          salary_information: driveUpdatingData.salary_information,
        }
      }
    } catch (error) {
      this.isLoading = false;
    }
  }

  async drivePathValue(driveUpdatingData: any) {
    this.pCalendarDateFormate(driveUpdatingData);
    this.patchOptionalInformationBasedOnFieldType(driveUpdatingData.optionalFormData);
    const transformedData = this.transformPatchValueSalaryInformation(driveUpdatingData.salary_information);
    this.createDriveFormPatchValue(driveUpdatingData);
    this.patchValueSalaryInformation(transformedData);
  }

  createDriveFormPatchValue(driveUpdatingData: any) {
    this.createDriveInfo.patchValue({
      allowToSelectedMultiple: driveUpdatingData.selectMultipleProfiles && driveUpdatingData.preference_count >= 2,
      company: {
        company_name: driveUpdatingData.company_name,
        company_url: driveUpdatingData.company_url,
        company_id: driveUpdatingData.company_id
      },
      companyLogo: driveUpdatingData.company_logo,
      companyUrl: driveUpdatingData.company_url,
      companyCategory: {
        company_category: driveUpdatingData.company_category,
        company_category_id: driveUpdatingData.company_category_id
      },
      degree: driveUpdatingData.degree,
      company_location: driveUpdatingData.company_location,
      drive_attachments: driveUpdatingData.drive_attachments,
      description: driveUpdatingData.drive_description,
      driveName: driveUpdatingData.name,
      driveStatus: driveUpdatingData.drive_status,
      driveNumber: driveUpdatingData.drive_number,
      driveSpoc: driveUpdatingData.drive_spoc,
      driveType: driveUpdatingData.drive_type,
      optional_form_email: driveUpdatingData.optional_form_email,
      opt_out_reason: driveUpdatingData.opt_out_reason,
      pre_placement_talk: driveUpdatingData.pre_placement_talk,
      preference_count: driveUpdatingData.preference_count,
      allow_priority: driveUpdatingData.allow_priority,
      profile: driveUpdatingData.profile,
      profileBased: driveUpdatingData.profileBased,
      profileDesignation: driveUpdatingData.profile_designation,
      profileType: driveUpdatingData.profileType,
      resume_selection: driveUpdatingData.resumeUpload,
      allowToSelectProfile: driveUpdatingData.selectMultipleProfiles,
      internshipDuration: driveUpdatingData.internshipDuration,
      internshipTimeLine: driveUpdatingData.internshipTimeLine,
      talk_venue: driveUpdatingData.talk_venue,
      objective: driveUpdatingData.drive_objective,
    });
    this.pre_placement_talk = driveUpdatingData.pre_placement_talk;
    this.profileDesignation = driveUpdatingData.profile_designation;
    this.opt_out_reason = driveUpdatingData.opt_out_reason;
    this.dateStatusSelected = driveUpdatingData.placementStatus;
    this.onDateStatusChange(this.dateStatusSelected);
    this.driveObjective = driveUpdatingData.drive_objective;

    driveUpdatingData.reasons?.forEach((reason: any) => {
      this.reasons.forEach((item) => {
        if(item.label === reason) {
          this.createDriveInfo.controls[item.controlName].setValue(true);
        }
      })
    });

    this.preferenceOption = [];
    if (this.profileDesignation.length >= 2) {
      for (let index = 1; index <= this.profileDesignation.length; index++) {
        this.preferenceOption.push({ label: index, value: index });
      }
    }
  }

  patchValueSalaryInformation(newData: any) {
    this.profileFormGroups = {};
    // Iterate over each profile key in the new data
    Object.keys(newData).forEach(profileKey => {
      // Check if this profile key exists in the current form groups
      if (this.profileFormGroups[profileKey]) {
        // Patch each profile group with new data
        newData[profileKey].forEach((profileData: any, index: number) => {
          // Ensure the form group exists for this profile at the current index
          if (this.profileFormGroups[profileKey][index]) {
            // Patch the form group with new profile data
            this.profileFormGroups[profileKey][index].patchValue(profileData);
          } else {
            // If no form group exists for this index, create a new one and add it
            const newFormGroup = this.createProfileFormGroup(profileData);
            this.patchSelectedCampus(newFormGroup, profileKey, index);
            this.profileFormGroups[profileKey].push(newFormGroup);
          }
        });
      } else {
        // If this profile key doesn't exist, create it and populate with new form groups
        this.profileFormGroups[profileKey] = newData[profileKey].map((profileData: any, idx: number) => {
          const newFormGroup = this.createProfileFormGroup(profileData);
          this.patchSelectedCampus(newFormGroup, profileKey, idx);
          return newFormGroup;
        });
      }
    });
  }

  private patchSelectedCampus(formGroup: any, key: any, idx: number): void {
    if(this.driveUpdatingData.degree) {
      const campusValue = new Set(formGroup.get('degree')?.value.map((degree: any) => degree.account_id));
      this.campus(Array.from(campusValue), formGroup, key, idx, true);
    };
  }

  private transformPatchValueSalaryInformation(existingSalaryInformation: any[]): any {
    const transformed = existingSalaryInformation.reduce((acc, item) => {
      const roleKey = item.role;
       // Prepare the degree object to push into the role's array
      // const degreeInfo = {
      //   degree_id: item.degree_id,
      //   programme_id: item.program_id,
      //   department_id: item.department_id,
      //   account_id: item.account_id,
      // };
      // Initialize the role key if not already present
      if (!acc[roleKey]) {
        acc[roleKey] = [];
      }

      if(!this.finalProfileDesignation[roleKey]) {
        this.finalProfileDesignation[roleKey] = [];
      }
      // Check if a similar object already exists in the array
      // const existingEntry = acc[roleKey].find((entry: { clone: any; profile: any; }) => entry.clone === item.clone && entry.profile === item.profile);
      // if (existingEntry) {
        // If similar object exists, just push the degreeObject into its degree array
        // existingEntry.degree.push(degreeInfo);
      // } else {
        // Remove properties that are moved into the degree object
        // const { degree_id, program_id, account_id, department_id, degree, ...rest } = item;
        acc[roleKey].push({
          ...item,
        });

        this.finalProfileDesignation[roleKey].push(item);

      return acc;
    }, {});

    return transformed;
  }

  pCalendarDateFormate(driveUpdatingData: any) {
    if(driveUpdatingData.pre_placement_talk) {
      this.prePlacementOption({checked: true});
      this.prePlacementTalkDate = this.dateFormate(driveUpdatingData.talk_date);
      this.createDriveInfo.patchValue({
        talk_date: this.prePlacementTalkDate
      });
    }

    this.placementStartDate = this.dateFormate(driveUpdatingData.placement_date);
    this.placementEndDate = this.dateFormate(driveUpdatingData.lastDate);
    this.createDriveInfo.patchValue({
      placementDate: this.placementStartDate,
      lastDate: this.placementEndDate,
    });
  };

  private dateFormate( dateString: string) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return null;  // Return null if the date is invalid
    }
    const ISTOffset = 5.5; // IST is UTC + 5:30
    const currentUTCTime = date.getTime() + (date.getTimezoneOffset() * 60000); // Convert local time to UTC time
    return new Date(currentUTCTime + (3600000 * ISTOffset));
  }
  patchOptionalInformationBasedOnFieldType(optionalFormData: any) {
    while (this.optionalInformation.length !== 0) {
      this.optionalInformation.removeAt(0);
    }
    optionalFormData.forEach((optionalField: any) => {
      this.optionalInformation.push(this.fb.group({
        id: [optionalField.id],
        fieldType: [optionalField.fieldType, Validators.required],
        question: [optionalField.question, Validators.required],
        options: [optionalField.options],
        mandatory: [optionalField.mandatory],
        hide: [optionalField.hide],
        edit: [optionalField.edit],
        allowSpecificFileTypes: [!!optionalField.allowSpecificFileTypes],
        fileTypes: [optionalField.fileTypes],
        selectedFileSize: [optionalField.selectedFileSize],
        type: [optionalField.type],
      }));
    });
  }

  showSpecificFileTypes(event: any, index: number) {
    const formGroup = this.optionalInformation.at(index) as FormGroup;
    if (event.checked) {
      formGroup.get('fileTypes')?.setValidators(Validators.required);
    } else {
      formGroup.get('fileTypes')?.clearValidators();
      formGroup.get('fileTypes')?.setValue([]);
    };

    formGroup.get('fileTypes')?.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.clicked = false;
    this.createDriveInfo?.get('profileType')?.setValue('')
    this.createDriveInfo?.get('profileDesignation')?.reset()
    this.createDriveInfo?.reset();
  }
  async createTabChange(event: any) {
    this.currentTab = event.value;
  }

  changeCreateInfo(info: iconsList) {
    this.createOptions.forEach((item: iconsList) => {
      item.isActive = (item.value === info.value)
    })
  };

  preventInvalidCharacters(event: KeyboardEvent) {
    const invalidChars = ['-', 'e', 'E'];
  
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  validateInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let value = parseInt(inputElement.value.trim(), 10);

    if (value > this.driveService.salaryLimit) {
      value = this.driveService.salaryLimit;
    }

    inputElement.value = value.toString();
  }

  changeInfo(info: iconsList, index: number) {
    this.createDriveOptions.forEach((item: iconsList) => {
      item.isActive = (item.value === info.value)
    })
    this.activeIndex = [index];
    this.scrollToAccordion(index);
  }

  closeAccordian(event: any) {
    this.activeIndex = [event.index];
  }

  openAccordian(event: any){
    this.activeIndex = [event.index];
  }

  scrollToAccordion(index: number) {
    if(this.accordion){
      this.accordion.nativeElement.scrollTo({
        top: index * 62,
        behavior: 'smooth'
      });
    }
  }

  async companyData(company: any) {
    this.createDriveInfo.controls['companyUrl'].setValue(company.value.company_url);
  }

  async preplacementOption(ppt: any) {
    this.pre_placement_talk = ppt.checked;
    this.createDriveInfo.controls['pre_placement_talk'].setValue(this.pre_placement_talk);
  }


  get optionalFormData() {
    return this.createDriveInfo.get('optionalInformation') as FormArray;
  }

  onSalaryCheckbox(event: any, index: number, type: string, profileKey: string): void {
    if (!this.profileFormGroups[profileKey]?.[index]) return;
  
    const formGroup = this.profileFormGroups[profileKey][index];
    const fixedStipend = formGroup.get('fixedStipend')
    if (!event.checked) {
      formGroup.patchValue({
        [`fixed${type}`]: null,
        [`range${type}`]: {from: null, to: null}
      });
    };
    if(event.checked && type === 'Stipend'){
      fixedStipend?.setValidators(Validators.required);
      formGroup.patchValue({
        stipendType: 'fixed'
      });
    }
    
    if(!event.checked && type === 'Stipend'){
      formGroup.patchValue({
        stipendType: 'fixed',
        stipendFixed: null,
        stipendVariable: null
      });
      this.onSalaryTypeChange('fixed', formGroup, 'stipendType');
      fixedStipend?.clearValidators();
    }

    fixedStipend?.updateValueAndValidity();

    const fixedSalaryControl = formGroup.get('fixedSalary');
    if(event.checked && type === 'Salary'){
      fixedSalaryControl?.setValidators(Validators.required);
    }

    if(!event.checked && type === 'Salary'){ 
      formGroup.patchValue({
        fromRange: null,
        toRange: null,
        salaryBreak: false,
        salaryType: 'fixed',
        salaryVariable: null,
        salaryFixed: null
      });

      this.onSalaryTypeChange('fixed', formGroup, 'salaryType');
      fixedSalaryControl?.clearValidators();
    };

    fixedSalaryControl?.updateValueAndValidity();
  };
  
  onSalaryBreakCheckbox(event: any, formGroup: FormGroup):void{
    if (!event.checked) {
      formGroup.patchValue({
        salaryFixed: null,
        salaryVariable: null
      });
    }
    const salaryFixedControl = formGroup.get('salaryFixed');
    const salaryVariableControl = formGroup.get('salaryVariable');
    if(event.checked){
      salaryFixedControl?.setValidators([Validators.required, Validators.min(0)]);
      salaryVariableControl?.setValidators([Validators.required, Validators.min(0)]);
    } else {
      salaryFixedControl?.clearValidators();
      salaryVariableControl?.clearValidators();
    }
    salaryFixedControl?.updateValueAndValidity();
    salaryVariableControl?.updateValueAndValidity();
    }

    onSalaryTypeChange(value: any, formGroup: FormGroup, controlName: string): void {
      formGroup.patchValue({
        [controlName]: value
      });
    
      const controls = this.getFormControls(formGroup);
    
      if (value === 'toBeAnnounced') {
        this.handleToBeAnnounced(value, controlName, controls);
      } else {
        this.handleDefaultCase(value, controlName, controls);
      }
    
      this.handleRangeAndFixedCases(value, controlName, controls, formGroup);
    
      this.updateControlValidity(controls);
    
      formGroup.updateValueAndValidity();
    }
    
    private getFormControls(formGroup: FormGroup) {
      return {
        fixedSalaryControl: formGroup.get('fixedSalary'),
        fixedStipendControl: formGroup.get('fixedStipend'),
        stipendControl: formGroup.get('stipend'),
        fromRangeControl: formGroup.get('fromRange'),
        toRangeControl: formGroup.get('toRange'),
        salaryBreakControl: formGroup.get('salaryBreak'),
        salaryVariable: formGroup.get('salaryVariable'),
        salaryFixed: formGroup.get('salaryFixed')
      };
    }
    
    private handleToBeAnnounced(value: any, controlName: string, controls: any) {
      if (controlName === 'salaryType') {
        this.resetSalaryControls(controls);
      }
      if (controls.stipendControl?.value && controlName === 'stipendType') {
        this.resetStipendControls(controls);
      }
    }
    
    private handleDefaultCase(value: any, controlName: string, controls: any) {
      if (controlName === 'salaryType') {
        controls.fixedSalaryControl?.addValidators(Validators.required);
        controls.fixedSalaryControl?.enable();
      }
      if (controlName === 'stipendType' && controls.stipendControl?.value) {
        controls.fixedStipendControl?.addValidators(Validators.required);
        controls.fixedStipendControl?.enable();
      }
    }
    
    private handleRangeAndFixedCases(value: any, controlName: string, controls: any, formGroup: FormGroup) {
      if (value === 'range' && controlName === 'salaryType') {
        this.setRangeValidators(controls, formGroup);
      } else if (value === 'fixed' && controlName === 'salaryType') {
        this.setFixedValidators(controls, formGroup);
      }
    }
    
    private resetSalaryControls(controls: any) {
      controls.fixedSalaryControl?.setValue(null);
      controls.fixedSalaryControl?.clearValidators();
      controls.fixedSalaryControl?.disable();
      controls.salaryVariable?.setValue(null);
      controls.salaryFixed?.setValue(null);
      controls.salaryBreakControl?.setValue(false);
      controls.salaryVariable?.clearValidators();
      controls.salaryFixed?.clearValidators();
    }
    
    private resetStipendControls(controls: any) {
      controls.fixedStipendControl?.setValue(null);
      controls.fixedStipendControl?.clearValidators();
      controls.fixedStipendControl?.disable();
    }
    
    private setRangeValidators(controls: any, formGroup: FormGroup) {
      controls.fromRangeControl?.setValidators([Validators.required, Validators.min(0)]);
      controls.toRangeControl?.setValidators([Validators.required, Validators.min(0)]);
      formGroup.setValidators([this.rangeValidator()]);
      controls.fixedSalaryControl?.clearValidators();
      controls.salaryBreakControl?.disable();
    }
    
    private setFixedValidators(controls: any, formGroup: FormGroup) {
      controls.fixedSalaryControl?.setValidators(Validators.required);
      controls.fromRangeControl?.clearValidators();
      controls.toRangeControl?.clearValidators();
      formGroup.setValidators(null); // Remove the custom range validator
      controls.salaryBreakControl?.enable();
    }
    
    private updateControlValidity(controls: any) {
      controls.fixedSalaryControl?.updateValueAndValidity();
      controls.fromRangeControl?.updateValueAndValidity();
      controls.toRangeControl?.updateValueAndValidity();
      controls.salaryVariable?.updateValueAndValidity();
      controls.salaryFixed?.updateValueAndValidity();
      controls.fixedStipendControl?.updateValueAndValidity();
    }
    
  rangeValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const fromRange = group.get('fromRange')?.value;
      const toRange = group.get('toRange')?.value;
  
      if (fromRange !== null && toRange !== null && toRange < fromRange) {
        return { rangeInvalid: true, message: 'To range must be greater than from range' };
      }
      return null;
    };
  }

  onChangeDesignation(event: any) {
    const list = event.value.trim();
    this.profileDesignation.push(list);
    this.checkProfileBased();
    this.populateProfileFromGroup();
  }

  private checkProfileBased() {
    this.finalProfileDesignation = {};

    if (this.profileDesignation.length > 1 && !this.createDriveInfo.get('profileBased')?.value) {
      this.finalProfileDesignation['multiple_profile'] = [{
        degree: null,
        clone: 0,
        profile: 'Multiple Profile',
        salary: true,
        stipend: false,
        stipendBasis: 'annually',
        salaryBasis: 'annually',
        stipendType: 'fixed',
        salaryType: 'fixed',
        fixedStipend: null,
        rangeStipend: null,
        fixedSalary: '',
        rangeSalary: null,
        salaryBreak: false,
        salaryFixed: '',
        salaryVariable: '',
        fromRange: '',
        toRange: '',
      }];
    } else {
      this.assignValuesOnProfileBased();
    }
    this.preferenceOption = [];
    if(this.profileDesignation.length >= 2){
      for (let index = 2; index <= this.profileDesignation.length; index++) {
        this.preferenceOption.push({ label: index, value: index });
      }
    }
  }
  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  populateProfileFromGroup(){
    this.profileFormGroups = {};
    Object.keys(this.finalProfileDesignation).forEach((key: string) => {
      this.profileFormGroups[key] = this.finalProfileDesignation[key].map((item: any) => 
        this.createProfileFormGroup(item))
    })

    this.createDriveInfo.get('selectedCampus')?.setValue('');
  }
  private createProfileFormGroup(profileData: any): FormGroup {
    return this.fb.group({
      selectedCampus: [profileData.campus || []],
      degree: [profileData.degree, this.createDriveInfo.get('degree')?.value ? Validators.required : null],
      clone: [profileData.clone || 0, Validators.required],
      profile: [profileData.profile, Validators.required],
      salary: [profileData.salary, Validators.required],
      stipend: [profileData.stipend, Validators.required],
      stipendBasis: [profileData.stipendBasis, Validators.required],
      salaryBasis: [profileData.salaryBasis, Validators.required],
      stipendType: [profileData.stipendType, Validators.required],
      salaryType: [profileData.salaryType, Validators.required],
      fixedStipend: [profileData.fixedStipend, (profileData.stipend && profileData.stipendType === 'fixed') ? Validators.required : null],
      rangeStipend: [profileData.rangeStipend],
      fixedSalary: [profileData.fixedSalary, (profileData.salary && profileData.salaryType === 'fixed') ? Validators.required : null],
      rangeSalary: [profileData.rangeSalary],
      salaryBreak: [profileData.salaryBreak],
      salaryFixed: [profileData.salaryFixed],
      salaryVariable: [profileData.salaryVariable],
      fromRange: [profileData.fromRange, (profileData.salary && profileData.salaryType === 'range') ? Validators.required : null],
      toRange: [profileData.toRange, (profileData.salary && profileData.salaryType === 'range') ? Validators.required : null],
    });
  }
  assignValuesOnProfileBased() {
    this.finalProfileDesignation = {}
    this.profileDesignation.forEach((ele: any) => {
      this.finalProfileDesignation[ele] = [{
        degree: null,
        clone: 0,
        profile: ele,
        salary: true,
        stipend: false,
        stipendBasis: 'annually',
        salaryBasis: 'annually',
        stipendType: 'fixed',
        salaryType: 'fixed',
        fixedStipend: null,
        rangeStipend: null,
        fixedSalary: '',
        rangeSalary: null,
        salaryBreak: false,
        salaryFixed: '',
        salaryVariable: '',
        fromRange: '',
        toRange: '',
      }]
    })
  }
  onRemoveDesignation(event: any) {
    if (this.createDriveInfo.get('singleProfile')?.value) {
      this.finalProfileDesignation = {}
      return;
    }

    const profileDesignationValue = this.createDriveInfo.get('profileDesignation')?.value;
    if (profileDesignationValue) {
      this.profileDesignation = [...profileDesignationValue];
    }
    delete this.finalProfileDesignation[event.value]
    this.checkProfileBased();
    this.populateProfileFromGroup()
  }
  async allowStdInterestedProfile() {
    this.preferenceOption = [];
    this.preferenceDisabled = false;
    if(this.profileDesignation.length >= 2){
      for (let index = 2; index <= this.profileDesignation.length; index++) {
        this.preferenceOption.push({ label: index, value: index });
      }
    }
  }

  resetPlacementEndDate(){
      this.placementEndDate = null;
  }

  async checkDateClash(showGrowl = true) {
    this.dateClashDrives = [];
    const placementDate = this.createDriveInfo.get('placementDate')?.value;
    if(!placementDate) return;

    const payload = {
      placement_date: this.dateFormate(placementDate),
      group_account_id: this.group_account_id
    };

    const response = await this.driveService.driveClashIndicator(payload);

    if(!response.data.clash) return;
    const formattedData = this.formatData(response.data.drive_data);
    if(!formattedData.length) return;

    this.dateClashDrives = formattedData;
    this.pageSize = 50;
    this.currentPage = 1;
    this.totalPage = Math.ceil(this.dateClashDrives.length / this.pageSize);
    this.loadCurrentPageData();
    if(!showGrowl) return;
    this.messageService.add({
      severity: Severity.WARN,
      summary: `${this.driveLabel==='Jobs' ? 'Job' : 'Drive'} Clash`,
      detail : `Another ${this.driveLabel==='Jobs' ? 'Job' : 'Drive'} has been scheduled on the same date.`
    });
  };

  formatData(data: any) {
    const formattedData: any = [];

    data.forEach((drive: any) => {

      if (drive.drive_id === this.drive_id) return;

      formattedData.push({
        name: drive.name,
        drive_objective: drive.drive_objective,
        campus: drive.campus,
        pass_out_year: drive.passed_out_year,
        degree: drive.degree_specialization,
        date_status: this.dateStatus.find((each) => each.value == drive.placementStatus)?.label,
        drive_status: drive.drive_status
      });
    });

    return formattedData;
  };

  async openDriveClashDetails() {
    this.showDateClashDialog = true;
  }

  async onHideDriveClashDialog() {
    this.showDateClashDialog = false;
  }
  async prePlacementOption(event: any) {
    this.pre_placement_talk = event.checked;
    this.createDriveInfo.controls['pre_placement_talk'].setValue(this.pre_placement_talk);
    let talkDateControl = this.createDriveInfo.get('talk_date');
    if (this.pre_placement_talk === true) {
      talkDateControl?.setValidators(Validators.required);
    } else {
      talkDateControl?.setValue('');
      talkDateControl?.clearValidators();
    }
    talkDateControl?.updateValueAndValidity();
  }
  get designationBoolean(): boolean {
    let value = (this.createDriveInfo.get('profileType')?.value === 'single')
    return (value && this.profileDesignation && this.profileDesignation.length === 1)
      || (!value && this.profileDesignation && this.profileDesignation.length > 1);
  }

  get MultipleProfile(): { [key: string]: any[] } {
    return this.finalProfileDesignation
  }

  trackByIndex(index: number, item: any) {
    return index;
  }

  trackByKey(index: number, keyValue: KeyValue<string, any>): string | number {
    return keyValue.key;
  }

  onchangeMultiselectDegree(event: any, profileKey: string, cloneIndex: number): void {
    if (this.profileFormGroups[profileKey]?.[cloneIndex]) {
      const formGroup = this.profileFormGroups[profileKey][cloneIndex];
      formGroup.patchValue({
        degree: event.value 
      });

    }
    
    // Update the model
    let profileArray = this.finalProfileDesignation[profileKey];
    if (profileArray && profileArray.length > cloneIndex) {
      profileArray[cloneIndex].degree = event.value;
    }
    
  }
  
  confirm() {
    this.confirmationService.confirm({
      message: 'Entered Details Will be Cleared',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ severity: Severity.SUCCESS, summary: Summary.CONFIRMED, detail: Message.DETAILS });
        this.commonDegree = {}
      },
      reject: (type: ConfirmEventType) => {
        this.createDriveInfo.get('degree')?.setValue(true)
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: Severity.ERROR, summary: Summary.REJECTED, detail: Message.REQUESTREJECT });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: Severity.WARN, summary: Summary.CANCELLED, detail: Message.REQUESTCANCEL });
            break;
        }
      }
    });
  }


  onDateStatusChange(status: string) {
    this.dateStatusSelected = status;
    let placementDate = this.createDriveInfo.get('placementDate') as FormControl;
    if (this.dateStatusSelected === 'toBeAnnounced') {
      placementDate.clearValidators();
      placementDate.disable();
    }else{
      placementDate.setValidators(Validators.required);
      placementDate.enable();
    }
    placementDate.updateValueAndValidity();
  }

  onShortAnswerOptionChange(status: string, index: number, key: string) {
    this.shortAnswerOptionSelected = status;
    const formGroup = this.optionalInformation.at(index) as FormGroup;
    formGroup.patchValue({
      [key]: status
    });
  }
  onChangeDegreeBased(event: any) {
    this.checkProfileBased();
    this.populateProfileFromGroup();
  }
  isValidProfileFormGroups(): [boolean, string] {
    for (const profileKey of Object.keys(this.profileFormGroups)) {
      const formGroupsArray = this.profileFormGroups[profileKey];
      for (const formGroup of formGroupsArray) {
        if (!formGroup.valid) {
          console.error(`Form group for ${profileKey} is invalid.`, formGroup);
          return [false, 'Fill all the mandatory fields in profile designation.'];
        }

        // add validation to check atleast salary or stipend is filled
        if (!formGroup.get('salary')?.value && !formGroup.get('stipend')?.value) {
          return [false, 'Fill either salary or stipend for each profile designation.'];
        };
      }
    }
    return [true, ''];
  }
  prepareDataForSubmission(): any {
    if (!this.isProfileFormGroupsValid()) {
      console.error('profileFormGroups is not defined or not an object.');
      return null;
    }
    const submissionData: DynamicProfileFormGroups = {};

    for (const profileKey of Object.keys(this.profileFormGroups)) {
      if (!submissionData[profileKey]) {
        submissionData[profileKey] = [];
      }
      const formGroups = this.profileFormGroups[profileKey];
      if (this.areFormGroupsValid(formGroups, profileKey)) {
        submissionData[profileKey] = this.getValidFormValues(formGroups);
      } else {
        return null;
      }
    }
    return submissionData;
  }

  private isProfileFormGroupsValid(): boolean {
    return this.profileFormGroups && typeof this.profileFormGroups === 'object';
  }

  private areFormGroupsValid(formGroups: any[], profileKey: string): boolean {
    const allValid = formGroups.every(formGroup => formGroup.valid);
    if (!allValid) {
      console.error(`One or more forms in ${profileKey} are invalid.`);
    }
    return allValid;
  }

  private getValidFormValues(formGroups: any[]): any[] {
    return formGroups.map(formGroup => {
      const formValue = formGroup.value;
      if (Array.isArray(formValue.degree)) {
        formValue.degree = formValue.degree.map((degree: { value: any; }) => degree.value ? degree.value : degree);
      }
      return formValue;
    });
  }
  
  
  transformSalaryInformation(salaryInformation: SalaryInformation): any[] {
    let result:any[] = [];
  
    Object.entries(salaryInformation).forEach(([role, details]) => {
      details.forEach((detail: ProfileDetail) => {
        delete detail['selectedCampus'];
        result.push({
          role: role.toLowerCase(),
          ...detail
        });
      });
    });
  
    return result;
  }

  async addDrive(redirect: boolean, update: boolean = false) {    
    if (!this.validateForm()) return false;

    const salaryInformation = this.prepareDataForSubmission();
    try {
      this.isLoading = true;

      if (await this.updateCompanyLogo(update)) {
        const driveData = this.constructDriveData(salaryInformation);
        await this.processDriveData(driveData, update, redirect);
        return true;
      }

      return false;
    } catch (error) {
      console.error("Error in addDrive:", error);
      this.isLoading = false;
      return false;
    }
  }

  private validateForm(): boolean {
    if (this.createDriveInfo.invalid) {
      this.showValidationMessage(Message.MANDATORY);
      return false;
    }

    const [validProfileFormGroups, profileFormGroupsMessage] = this.isValidProfileFormGroups();
    if (!validProfileFormGroups) {
      this.showValidationMessage(profileFormGroupsMessage);
      return false;
    }

    this.clicked = false;
    return true;
  }

  private showValidationMessage(detail: string): void {
    this.clicked = true;
    this.messageService.add({ severity: Severity.WARN, summary: Summary.VALIDATION, detail });
  }

  private async updateCompanyLogo(update: boolean): Promise<boolean> {
    if (!update || (update && this.originalDriveInfo.company.company_id !== this.createDriveInfo.get('company')?.value.company_id)) {
      const companyURL = this.extractDomain(this.createDriveInfo.get('companyUrl')?.value);
      const logos = await this.driveService.getCompanyLogo(companyURL);
      if (logos?.length) {
        this.createDriveInfo.patchValue({ companyLogo: logos[0].logo });
      }
    }
    return true;
  }

  private async processDriveData(driveData: any, update: boolean, redirect: boolean): Promise<void> {
    if (update) {
      await this.updateExistingDrive(driveData);
    } else {
      await this.createNewDrive(driveData, redirect);
    }
  }

  private async updateExistingDrive(driveData: any): Promise<void> {

    const driveStatus = this.createDriveInfo.get('driveStatus')?.value;
    const isActiveStatus = driveStatus === PatDriveStatus.ongoing || driveStatus === PatDriveStatus.upcoming;

    if (isActiveStatus) {
      this.originalDriveInfo.driveStatus = new Date(this.createDriveInfo.get('lastDate')?.value) < new Date() ? PatDriveStatus.ongoing : PatDriveStatus.upcoming;
    }

    Object.assign(driveData, {
      updatedBy: this.userData.primary_email,
      drive_status: this.originalDriveInfo.driveStatus,
      driveDateChanged: isActiveStatus && this.checkLastDateIncreased(this.originalDriveLastDate, driveData.lastDate),
      optionalFormUpdate: isActiveStatus && this.checkForOptFormUpdate(this.initialOptionalForm, driveData.optionalFormData),
      profileUpdate: isActiveStatus && this.checkForProfileUpdate(this.initialProfileObject, {profile_designation: driveData.profile_designation, salary_information: driveData.salary_information}),
    });

    this.initialOptionalForm = driveData.optionalFormData;
    this.originalDriveLastDate = driveData.lastDate;
    this.initialProfileObject = {
      profile_designation: driveData.profile_designation,
      salary_information: driveData.salary_information,
    }


    if (driveData.driveDateChanged) {
      this.messageService.add({
        severity: Severity.WARN,
        summary: Summary.WARNING,
        detail: Message.DRIVEELIGIBLE
      });
    };

    await this.updateDrive(driveData);
    this.updateDriveInfo(driveData);
  }

  checkLastDateIncreased(originalLastDate: string, newDate: string): boolean {
    if (new Date(originalLastDate) > new Date()) return false;
    if (new Date(newDate) > new Date()) return true;

    return false;
  };

  checkForOptFormUpdate(oldForm: any[], newForm: any[]): boolean {
    if (oldForm.length !== newForm.length) {
      return true;
    }
    for (let i = 0; i < oldForm.length; i++) {
      const oldItem = oldForm[i];
      const newItem = newForm[i];
      if (oldItem === newItem) continue;
      if (typeof oldItem !== 'object' || oldItem === null || typeof newItem !== 'object' || newItem === null) {
        return true;
      }
      const keys1 = Object.keys(oldItem);
      const keys2 = Object.keys(newItem);
      if (keys1.length !== keys2.length) return true;
      for (const key of keys1) {
        if (!keys2.includes(key) || oldItem[key] !== newItem[key]) {
          return true;
        }
      }
    }
    return false;
  }

  checkForProfileUpdate(oldObject: any, newObject: any): boolean {
    // Compare profile_designation
    if (oldObject.profile_designation.length !== newObject.profile_designation.length) {
      return true;
    }
    for (let i = 0; i < oldObject.profile_designation.length; i++) {
      if (oldObject.profile_designation[i] !== newObject.profile_designation[i]) {
        return true;
      }
    }
  
    // Compare salary_information
    if (oldObject.salary_information.length !== newObject.salary_information.length) {
      return true;
    }
    for (let i = 0; i < oldObject.salary_information.length; i++) {
      const oldSalary = oldObject.salary_information[i];
      const newSalary = newObject.salary_information[i];
      for (const key in oldSalary) {
        if(key == 'degree')
        {
          continue;
        }
        if (oldSalary[key] != newSalary[key]) {
          return true;
        }
      }
    }
  
    return false;
  }

  private updateDriveInfo(driveData: any): void {
    const driveStatus = this.originalDriveInfo.driveStatus;
    this.originalDriveInfo = this.createDriveInfo.value;
    this.originalDriveInfo.driveStatus = driveStatus;
    this.updateBredCrumb();
    this.driveUpdatingData = { ...this.driveUpdatingData, ...driveData };
  }

  private async createNewDrive(driveData: any, redirect: boolean): Promise<void> {
    driveData.createdBy = this.userData.primary_email;
    driveData.updatedBy = this.userData.primary_email;

    const response = await this.driveService.addDrive(driveData);
    if (redirect) {
      this.router.navigate(["/drives/drive-details"], { queryParams: { id: response.data.drive_id } });
      this.driveService.navigationState = 1;
    }

    this.handleDriveCreationResponse(response);
  }


  extractDomain(url: string) {
    let parsedUrl = new URL(url);
    let hostname = parsedUrl.hostname;

    let domainParts = hostname.split('.');
    if (domainParts.length > 2) {
        domainParts = domainParts.slice(-2);
    }
    return domainParts.join('.');
}

  async updateDrive(driveData: any) {
    let response = await this.driveService.updateDrive(this.drive_id, driveData);
    this.isLoading = false;
    if(response.success && response.data) {
      return this.messageService.add({ severity: Severity.SUCCESS, summary: Summary.SUCCESS, detail: Message.DRIVEUPDATE });
    }
    return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.DRIVEUPDATEERR });
  }

  handleDriveCreationResponse(response: ApiResponse) {
    this.isLoading = false;
    if (response.success && response.data) {
      this.messageService.add({ severity: Severity.SUCCESS, summary: Summary.SUCCESS, detail: Message.DRIVECREATED });
      this.createDriveInfo.reset();
      this.clicked = false;
      this.driveEvent.emit({ success: true, data: Message.DRIVECREATED });
    } else {
      this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.DRIVECREATEERR });
    }
  }
  getOptionalInformationValuesFiltered(): any[] {
    const keysToRemove = [
      'allowSpecificFileTypes',
      'selectedFileSize',
      'fileTypes'
    ];

    const filteredValues = this.optionalInformation.value.map((item: any, idx: number) => {
      if (item.fieldType !== 'fileupload') {
        const filteredItem = { ...item };
        if(item.fieldType !== 'shortanswer' ){
          delete filteredItem['type'];
        }
        keysToRemove.forEach(key => delete filteredItem[key]);
        return filteredItem;
      }
      return {...item };
    });
    
    return filteredValues;
  }
  
  constructDriveData(salaryInformation: any): any {
    let drive = this.createDriveInfo.value;
    let driveData:any = {
      profileType: drive.profileType,
      name: drive.driveName,
      drive_type: drive.driveType,
      drive_spoc: drive.driveSpoc,
      drive_description: drive.description,
      drive_objective: this.driveObjective,
      drive_attachments: drive.drive_attachments,
      company_id: drive.company.company_id,
      company_name: drive.company.company_name,
      company_url: drive.companyUrl,
      company_logo: drive.companyLogo,
      company_location: drive.company_location,
      company_category_id: drive.companyCategory.company_category_id,
      company_category: drive.companyCategory.company_category,
      profile_designation: drive.profileDesignation,
      group_account_id: this.group_account_id,
      placement_date: drive.placementDate,
      lastDate: drive.lastDate,
      placementStatus: this.dateStatusSelected,
      resumeUpload: drive.resume_selection,
      selectMultipleProfiles: drive.allowToSelectProfile,
      preference_count: drive.preference_count,
      allow_priority: drive.allow_priority,
      optionalFormData: this.getOptionalInformationValuesFiltered(),
      salary_information: this.transformSalaryInformation(salaryInformation),
      optional_form_email: drive.optional_form_email,
      pre_placement_talk: drive.pre_placement_talk,
      profileBased: drive.profileBased,
      degree: drive.degree,
      reasons: []
    }
    if(drive.pre_placement_talk){
      driveData['talk_venue'] = drive.talk_venue;
      driveData['talk_date'] = drive.talk_date;
    }
    if (drive.opt_out_reason) {
      driveData['opt_out_reason'] = drive.opt_out_reason;
      driveData['reasons'] = this.reasons
        .filter((reason) => this.createDriveInfo.get(reason.controlName)?.value) 
        .map((reason) => reason.label);
    };

    if(driveData.drive_objective === "Internship"){
      driveData['internshipDuration'] = drive.internshipDuration;
      driveData['internshipTimeLine'] = drive.internshipTimeLine;
    }
    return driveData;
  }

addNewFieldForOptionalForm():any {
  return this.optionalInformation.push(this.createField());
}

createField(): FormGroup {
  return this.fb.group({
    id: [uuidv4()],
    fieldType: ['', Validators.required],
    question: ['', Validators.required],
    options: [[]],
    mandatory: [false],
    hide: [false],
    edit: [false],
    allowSpecificFileTypes: [false],
    fileTypes: [[]],
    selectedFileSize: [null],
    type: ['text']
  });
}

removeField(index: number) {
  this.optionalInformation.removeAt(index);
}

  onOptionalFieldTypeChange(event: any, index: number, key: string) {
    const fieldTypeValue = event.value;
    const formGroup = this.optionalInformation.at(index) as FormGroup;
  
    if (fieldTypeValue !== 'fileupload' ) {
      if (fieldTypeValue !== 'shortanswer' && fieldTypeValue !== 'longanswer' && fieldTypeValue !== 'termscondition') {
        formGroup.get('options')?.setValidators([Validators.required, this.validationService.arrayMinLengthValidator(2)]);
      } else {
        formGroup.get('options')?.clearValidators();
      }
      if(fieldTypeValue === 'shortanswer'){
        formGroup.patchValue({
          [key]: fieldTypeValue,
          fileTypes: { value: [], disabled: true },
          allowSpecificFileTypes: { value: false, disabled: true },
          selectedFileSize: { value: null, disabled: true }
        });
      }else{
        formGroup.patchValue({
          [key]: fieldTypeValue,
          fileTypes: { value: [], disabled: true },
          allowSpecificFileTypes: { value: false, disabled: true },
          selectedFileSize: { value: null, disabled: true },
          type: { value: null, disabled: true }
        });
      }
    } else {
      formGroup.get('options')?.clearValidators();
      formGroup.patchValue({
        [key]: fieldTypeValue
      });
    }
    formGroup.get('options')?.updateValueAndValidity();
  }
  
  onOptionalFieldFileSizeChange(event: any, index: number, key: any) {
    this.optionalInformation.at(index).patchValue({
      [key]: event.value
    })
  }

  // Correctly adding a chip
  chipsAddOnOptionalField(event: any, index: number, key: string) {
    const currentOptions = this.optionalInformation.at(index).get(key)?.value || [];
    if (!currentOptions.includes(event.value)) {
      this.optionalInformation.at(index).get(key)?.setValue([...currentOptions, event.value]);
    }
  }

  // Correctly removing a chip
  chipsRemoveOnOptionalField(event: any, index: number, key: string) {
    const updatedOptions = this.optionalInformation.at(index).get(key)?.value.filter((option: string) => option !== event.value);
    this.optionalInformation.at(index).get(key)?.setValue(updatedOptions);
  }

  async campus(event: any, formGroup: any, key: any, idx: number, isPatchValue?: any){
    let values = isPatchValue ? event : event.value;
    const filteredDepartments: any = [];
    let departmentOptions = [];
    this.selectedDegreeOptions[`${key}-${idx}`] = [];

    formGroup.get('selectedCampus')?.setValue(values);
    if(!isPatchValue) {
      formGroup.get('degree')?.setValue('');
    };

    const campusDetails = this.campusDetails;
    const selectedCampus = values;
    const selectedCampusValues = new Set(selectedCampus);
    departmentOptions = campusDetails.reduce((result: any, item: any) => {
      filteredDepartments.push(item)
      if (
        selectedCampusValues.has(item.account_id)
      ) {
        let group = result.find((g: any) => g.label === item.full_name);
        if (!group) {
          group = {
            label: item.full_name,
            value: item.account_id,
            items: [],
          };
        }
        const existingItem = group.items.find((i: any) => i.label === item.department);
        if (!existingItem) {
        const itemData = {
            account_id: item.account_id,
            department_id: item.department_id,
        };
        result.push(itemData);
      }
    }
      return result;
    }, []);
    this.department(departmentOptions, filteredDepartments, `${key}-${idx}`);
  }

  async department(event: any, filteredDepartments: any, key: string) {
    let values = event;
    const selectedDepartments = values;
    const selectedDepartmentValues = new Set(selectedDepartments.map((item: any) => item.department_id));
    const selectedCampusValue = new Set(selectedDepartments.map((item: any) => item.account_id));
    this.filteredDegreeSpec = [];
    
    this.selectedDegreeOptions[key] = filteredDepartments.reduce((result: any, item: any) => {
      if (
        selectedCampusValue.has(item.account_id) &&
        selectedDepartmentValues.has(item.department_id)
      ) {
        let group = result.find((g: any) => g.label === `${item.full_name} - ${item.department}`);
        if (!group) {
          group = {
            label: `${item.full_name} - ${item.department}`,
            value: item.department_id,
            items: [],
          };
          result.push(group);
        }    
        const itemData = {
          label: `${item.degree_name} - ${item.specialization}`,
          value: {
            account_id: item.account_id,
            degree_id: item.degree_id,
            department_id: item.department_id,
            programme_id: item.programme_id
          },
        };
        group.items.push(itemData);
      }
      return result;
    }, []);
    this.selectedDegreeOptions[key].forEach((group: any) => {
      group.items.sort((a: any, b: any) => a.label.localeCompare(b.label));
    });
  
    // Sort the groups in ascending order based on label
    this.selectedDegreeOptions[key].sort((a: any, b: any) => a.label.localeCompare(b.label));
  };

  async optOutReason(opt: any) {
    this.opt_out_reason = opt.checked;
    this.createDriveInfo.controls['opt_out_reason'].setValue(this.opt_out_reason);  
  }

  onChangeProfileBased(event: any) {
    if (!event.checked) {
      this.checkProfileBased();
    } else {
      this.assignValuesOnProfileBased()
    }
    this.populateProfileFromGroup();
  }

  addCloneForProfileDesignation(profile: string,cloneName: string) {
    // Access the array for the given profile
    const profileArray = this.finalProfileDesignation[profile];

    // Determine the new clone number
    const newCloneNumber = profileArray.length;  // Assuming clone numbers start at 0

    // Initialize a new element with default values
    const newElement = {
      // Default values for the new element
      degree: '',
      clone: newCloneNumber,
      profile: `${cloneName} - ${newCloneNumber}`,
      salary: true,
      stipend: false,
      stipendBasis: 'annually',
      salaryBasis: 'annually',
      stipendType: 'fixed',
      salaryType: 'fixed',
      fixedStipend: null,
      rangeStipend: null,
      fixedSalary: '',
      rangeSalary: null,
      salaryBreak: false,
      salaryFixed: '',
      salaryVariable: '',
      fromRange: '',
      toRange: '',
    };

    // Add this new element to the array
    this.finalProfileDesignation[profile].push(newElement);
    this.profileFormGroups[profile].push(this.createProfileFormGroup(newElement));
  }

  removeCloneForProfileDesignation(profileKey: string, cloneNumber: number) {
    // Access the array for the given profile
    let profileArray = this.finalProfileDesignation[profileKey];

    // Find the index of the element to be removed
    const indexToRemove = profileArray.findIndex((item: any) => item.clone === cloneNumber);

    // Remove the element if found
    if (indexToRemove !== -1) {
      profileArray.splice(indexToRemove, 1);
      // Also remove the FormGroup for this clone
      this.profileFormGroups[profileKey].splice(indexToRemove, 1);
      // Update the clone numbers for subsequent elements
      for (let i = indexToRemove; i < profileArray.length; i++) {
        profileArray[i].clone--;
        // Update the profile value if needed
        const baseProfile = profileArray[i].profile.split(' - ')[0]; // Extract the base profile name
        profileArray[i].profile = `${baseProfile} - ${profileArray[i].clone}`;
        this.profileFormGroups[profileKey][i].patchValue({
          clone: i,
          profile: `${baseProfile} - ${i}`
        });
      }
    }
  }
  async fetchCampus() {
    const response = await this.driveService.fetchAccounts(this.group_account_id);

    this.studentDetailsCampusOptions = response.map((item: any) => {
      this.mapAccountIdWithCampus[item.account_id] = item.full_name;
      return {
        value: item.account_id,
        label: item.full_name
      }
    });

    this.studentDetailsCampusOptions.sort((a: any, b: any) => a.label.localeCompare(b.label));
  }

  async fetchCampusDetails() {
    this.campusDetails = await this.driveService.fetchCampusDetails(this.group_account_id);
  }

  async saveEmailTemplates() {
    const response = await this.driveEmailTemplateComponent.saveEmailTemplates(this.drive_id);

    if(response.status) {
      this.messageService.add({ severity: Severity.SUCCESS, summary: Summary.SUCCESS, detail: response.message });
      return true;
    }

    return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: response.message });
  }

  async openPublishDriveDialog(event: any) {

    this.payloadToPublish = {
      drive_id: this.drive_id,
      criteriaFilters: event.criteriaFilter,
      group_account_id: this.group_account_id,
      total_count: event.total_count,
      done_by: this.userData.primary_email,
      send_email: this.sendEmailNotification,
      drive_name: this.originalDriveInfo.driveName,
      drive_number: this.originalDriveInfo.driveNumber,
    };
    
    return this.showPublishDriveAlert(event);
  };


  updateEmailTemplete(event: any) {
    this.email_templates = event;
  }

  async saveActions() {

    switch(this.currentTab) {
      case 'details':
        let status = await this.addDrive(false, true);
        if(status) this.switchCurrentTab(1);
        break;
      case 'emailTemplate':
        await this.saveEmailTemplates();
        break;
      case 'eligibility':
          this.eligibilityCriteriaComponent.publishTheDrive();
        break;
    }
  }

  async onHoldDrive(holdDrive: boolean) {
      const payload = {
        drive_id: this.drive_id,
        holdDrive,
        group_account_id: this.group_account_id,
        drive_name: this.originalDriveInfo.driveName,
      }

      this.isLoading = true;
      const response = await this.driveService.driveHoldStatusChange(payload);

      this.originalDriveInfo.driveStatus = holdDrive ? PatDriveStatus.hold : PatDriveStatus.upcoming;
      this.isLoading = false;

      if(response.data) {
        this.messageService.add({ severity: Severity.SUCCESS, summary: Summary.SUCCESS, detail: Message.DRIVESTATUS });
        return true;
      }

      return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.DRIVECHANGEERR });
  }

  async onHoldConfirm() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to hold the drive?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => this.onHoldDrive(true)
    })
  };

  async endDialogConfirm() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to end the drive? This action cannot be undone.',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => this.endDrive()
    })
  }

  async endDrive() {

    const payload = {
      drive_id: this.drive_id
    }

    this.isLoading = true;
    const response = await this.driveService.endDrive(payload);
    this.roundsComponent.fetchStudentsInDrives();
    this.roundsComponent.getCountOfEveryStage();
    this.isLoading = false;

    if(response.data.success) {
      this.messageService.add({ severity: Severity.SUCCESS, summary: Summary.SUCCESS, detail: Message.DRIVEEND });
      this.originalDriveInfo.driveStatus = PatDriveStatus.completed;
      return true;
    }
    
    return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: response.data.message });
  };
  
  loadCurrentPageData() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.dataToDisplay = this.dateClashDrives.slice(startIndex, endIndex);
  }

  previousPagination() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadCurrentPageData();
    }
  }

  nextPagination() {
    if (this.currentPage < this.totalPage) {
      this.currentPage++;
      this.loadCurrentPageData();
    }
  }

  showCriteriaRefreshAlert(event: any) {
    this.criteriaToDisplay = event;
    this.currentTabSelected = 'refresh';
    this.displayPublishDialog = true;
  };

  showPublishDriveAlert(event: any) {
    this.criteriaToDisplay = event.previewCriteria;
    this.currentTabSelected = event.type;
    this.displayPublishDialog = true;
  }

  async publishDrive() {

    this.payloadToPublish.send_email = this.sendEmailNotification;
    this.onHidePublishDialog();
    this.isLoading = true;
    const response = await this.driveService.drivePublish(this.payloadToPublish);
    this.isLoading = false;
    
    if(response.data) {
      this.originalDriveInfo.driveStatus = new Date(this.createDriveInfo.get('lastDate')?.value) < new Date() ? PatDriveStatus.ongoing : PatDriveStatus.upcoming;
      
      this.criteriaFilters = this.payloadToPublish.criteriaFilters;
      this.formatCampusDetails();
      this.payloadToPublish = {};
      this.messageService.add({ severity: Severity.SUCCESS, summary: Summary.SUCCESS, detail: Message.DRIVEPUBLISH });
      this.switchCurrentTab(2);
      return true;
    };

    return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.DRIVEPUBLISHERR});
  };


  // Format the campus details for filters in interview process
  formatCampusDetails() {
    if (this.criteriaFilters?.length) {
      const camusNameSet = new Set();
      const degreeSet = new Set();
      const departmentSet = new Set();
      const passOutYearSet = new Set();
      const accountIdSet = new Set();

      for (const each of this.criteriaFilters) {
        const { studentFilters } = each;
        if (!studentFilters) continue;

        for (const each of studentFilters.programs_offered) {
          let programmes_offered = `${each.degree_name} - ${each.specialization}`;
          if (each.specialization_minor) {
            programmes_offered += ` - ${each.specialization_minor}`;
          }

          camusNameSet.add(each.full_name);
          accountIdSet.add(each.account_id);
          degreeSet.add(programmes_offered);
          departmentSet.add(each.department);
        };

        for (const each of studentFilters.pass_out_year) {
          passOutYearSet.add(each);
        };
      };

      this.driveUpdatingData.campus = Array.from(camusNameSet).join(', ');
      this.driveUpdatingData.degree_specialization = Array.from(degreeSet).join(', ');
      this.driveUpdatingData.department = Array.from(departmentSet).join(', ');
      this.driveUpdatingData.passed_out_year = Array.from(passOutYearSet);
      this.driveUpdatingData.account_id = Array.from(accountIdSet);
    };
  };


  republishDrive() {
    this.eligibilityCriteriaComponent.publishTheDrive(true, this.criteriaFilters);
  };

  onHidePublishDialog() {
    this.displayPublishDialog = false;
    this.sendEmailNotification = false;
  }

  async onFileUpload(event: any) {
    if (event.addedFiles.length === 0) return;

    const length = (this.createDriveInfo.get('drive_attachments')?.value || []).length;
    const maxFilesCount = 10; 

    if(length >= maxFilesCount) {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Message.MAXFILES,
        detail: `You can only upload maximum ${maxFilesCount} files.`
      });
      return;
    };

    const filesToUpload = event.addedFiles.slice(0, maxFilesCount - length);
    const maxFileSize = 10 * 1024 * 1024;  // 10 MB max file size
    this.isFileUploading = true;

    const uploadPromises = filesToUpload.map(async (file: any) => {
        if (file.size > maxFileSize) {
            this.messageService.add({
                severity: Severity.ERROR,
                summary: Message.FILELARGE,
                detail: `The file size of ${file.name} should not exceed 10MB.`
            });
            return;
        }

        const fileName = file.name.replace(/\s/g, '').replace(/[^\w.]/gi, '');
        const uploadFileName = `drive_attachments/${this.group_account_id}/${uuidv4()}/${fileName}`;
        const payload = {
            bucketName: this.globalService.studentBucket,
            fileName: uploadFileName,
            type: file.type,
        };

        try {
            const url = await this.globalService.getSignedUploadUrl(payload);
            if (url?.data) {
                await this.fileUploadService.uploadUsingSignedUrl(url.data.response, file);
                const attachments = this.createDriveInfo.get('drive_attachments')?.value || [];
                attachments.push(uploadFileName);
                this.createDriveInfo.get('drive_attachments')?.setValue(attachments);
            } else {
                throw new Error("Failed to obtain signed URL for " + file.name);
            }
        } catch (e) {
            this.messageService.add({
                severity: Severity.ERROR,
                summary: Summary.UPLOADERR,
                detail: `There was an error uploading ${file.name}`
            });
        }
    });
    
    await Promise.all(uploadPromises);
    this.isFileUploading = false; 
  };


  removeFile(idx: number) {
    const attachments = this.createDriveInfo.get('drive_attachments')?.value;
    attachments.splice(idx, 1);
    this.createDriveInfo.get('drive_attachments')?.setValue(attachments);
  }

  async openLink(url: string) {
    const signedUrl = await this.globalService.getSignedUrl({
      fileName: url,
      bucketName: this.globalService.studentBucket
    });

    window.open(signedUrl.data.response, '_blank');
  }

  async saveEligibility() {
    const criteriaFilter = this.eligibilityCriteriaComponent.getEligibilityPayload();
    if(!criteriaFilter) return; 

    const payload = {
      criteriaFilter,
      drive_id: this.drive_id
    };

    this.isLoading = true;
    const response = await this.driveService.saveDriveCriteria(payload);
    this.isLoading = false;

    if(response.data) {
      this.messageService.add({ severity: Severity.SUCCESS, summary: Summary.SUCCESS, detail: Message.CRITERIASAVED });
    }
    else {
      this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.CRITERIASAVEERR });
    }
  }

  notificationToggleChange($event:any) {
    this.enableNotification = $event.checked;
    this.driveService.driveSpecificNotification({
      drive_id: this.drive_id,
      sendNotification: this.enableNotification
    });
  }
}
