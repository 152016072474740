import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, UrlTree } from "@angular/router";
import { Observable } from 'rxjs';
import { GlobalService } from '@services';

@Injectable({
  providedIn: 'root'
})
class AdminGuard {
  public path: string = '';
  constructor(private globalService: GlobalService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable(observer => {

      const userData = this.globalService.getLocalStorage('user_details') ? JSON.parse(this.globalService.getLocalStorage('user_details') ?? '') : {};

      if (userData?.primary_email) {
        observer.next(true);
        observer.complete();
        return;
      } 

      this.globalService.resetLogin();
      observer.next(false);
      observer.complete();
    });
  }
}

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(AdminGuard).canActivate(route, state)
}
