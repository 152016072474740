import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root',
})
export class CustomMessageService {
  api_url = environment.HOST_LINK;

  options() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token') ?? '',
      Origin: window.location.origin,
    });
    return { headers };
  }

  constructor(private http: HttpService) {}

  async getCampaignStatus(payload: any) {
    let result : any = await this.http.post(`/campaignStatus`, payload);
    return result.data;
  }
  
  async getAllCampaign(payload: any) {
    let result : any = await this.http.post(`/getAllCampaign`, payload);
    return result.data;
  }

  async filterCampaignData(group_account_id: any, selectedFilters: any) {
    let result : any = await this.http.post(`/filterCampaigns`, {group_account_id, selectedFilters});
    return result.data;
  }
}
