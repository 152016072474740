<div class="custom-message">
  <div class="top-bar">
    <div class="action-fields">
      <div>
        <p-dropdown class="dropdown-width" [(ngModel)]="selectedFilters.messageType" inputId="dropdown" placeholder="Message Type" [options]="filterData[0].array">
        </p-dropdown>
      </div>
      <div>
        <p-dropdown class="dropdown-width"  [(ngModel)]="selectedFilters.sentBy" inputId="dropdown" placeholder="Sent By" [options]="filterData[1].array">
        </p-dropdown>
      </div>
      <div>
        <p-calendar 
            dataType="date"
            id="inputtext" 
            [(ngModel)]="selectedFilters.sentOn"     
            class="configure-date-picker-custom-msg"
            selectionMode="range" 
            [readonlyInput]="true"
            placeholder="Sent On">
        </p-calendar>
      </div>
      <div class="filter-fields">
        <!-- <div class="search-bar">
          <em class="pi pi-search" style="font-size: 13px;cursor: pointer"></em>
          <input #inputRef class="input-field" type="text" placeholder="Search by campaign type"
            (keyup.enter)="searchValueChange($event)"
            [style.color]="searchColor ? 'var(--clr-secondary)' : 'var(--clr-black)'" (input)="onSearchChange($event)">
        </div> -->
      </div>
    </div>
    <div class="action-fields">
      <div>
        <span class="clear-all" (click)="clearFilters()">Clear All</span>
      </div>
      <div>
        <span class="apply-filters" (click)="applyFilters()">Apply Filter</span>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="left-panel">
      <div *ngFor="let data of campaignData; let i = index" class="each-campaign"
        [class.activeCampaign]="selectedCampaignIndex === i" (click)="getSpecificCampaign(data, i)">
        <div>
          <img [src]="pushMessageIcon" *ngIf="data.message_type === 'push-message'" alt='pushMessage-icon'>
          <img [src]="emailBoxIcon" *ngIf="data.message_type === 'email'" alt='pushMessage-icon'>
        </div>
        <div>
          <div class="campaign-type">{{ data.campaign_type }}</div>
          <div class="campaign-date">{{ data.createdAt | date: globalService.defaultDateFormatWithTimeSeconds }}</div>
        </div>
      </div>
    </div>
    <div class="right-panel" *ngIf="selectedCampaign">
      <div class="header-content">
        <div>
          <img [src]="pushMessageIcon" *ngIf="selectedCampaign.message_type === 'push-message'" alt='pushMessage-icon'>
          <img [src]="emailBoxIcon" *ngIf="selectedCampaign.message_type === 'email'" alt='pushMessage-icon'>
        </div>
        <div class="campaign-name">{{ selectedCampaign.campaign_type }}</div>
      </div>
      <div class="email-info">
        <div class="details">
          <div class="each-item">
            <span class="label">Sent</span>
            <span class="value">{{ selectedCampaign.sentEmails ? selectedCampaign.sentEmails.length : 0 }}</span>
          </div>
          <div class="each-item">
            <span class="label">Delivered</span>
            <span class="value">{{ selectedCampaign.DeliveredEmails ? selectedCampaign.DeliveredEmails.length : 0 }}</span>
          </div>
          <div class="each-item">
            <span class="label">Failed</span>
            <span class="value">{{ selectedCampaign.FailedEmails ? selectedCampaign.FailedEmails.length : 0 }}</span>
          </div>
          <div class="each-item">
            <span class="label">Bounced</span>
            <span class="value">{{ selectedCampaign.BouncedEmails ? selectedCampaign.BouncedEmails.length : 0 }}</span>
          </div>
          <div class="each-item">
            <span class="label">Viewed</span>
            <span class="value">{{ selectedCampaign.ViewedEmails ? selectedCampaign.ViewedEmails.length : 0 }}</span>
          </div>
          <div class="each-item">
            <span class="label">Clicked</span>
            <span class="value">{{ selectedCampaign.ClickedEmails ? selectedCampaign.ClickedEmails.length : 0 }}</span>
          </div>
          <div class="each-item">
            <span class="label">Suppressed</span>
            <span class="value">{{ selectedCampaign.SuppressedEmails ? selectedCampaign.SuppressedEmails.length : 0 }}</span>
          </div>
          <div class="each-item">
            <span class="label">Quarantined</span>
            <span class="value">{{ selectedCampaign.QuarantinedEmails ? selectedCampaign.QuarantinedEmails.length : 0 }}</span>
          </div>
        </div>
      </div>
      <div class="email-content">
        <div class="email-header">
          <span class="value">{{ this.subjectTitle }}</span>
        </div>
        <div class="email-body">
          <span class="value" [innerHTML]="this.templateBody"></span>
        </div>
      </div>
    </div>
  </div>
</div>