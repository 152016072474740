<div class="emailTemplate-container">
    <div *ngIf="isLoading" class="loadingOverlay">
        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
        </p-progressSpinner>
    </div>
    <div *ngIf="isLoadingDetails" class="loading-feedback">
        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
        </p-progressSpinner>
    </div>
    <div class="add-criteria-cont">
        <div style="height: 38px;">
            <span *ngIf="!disableCriteriaEdit" class="add-criteria" (click)="addCriteria()">Add Criteria</span>
        </div>
    </div>
    <div class="emailTemplate-content">
        <ng-container *ngFor="let item of allCriteria; let index = index">
            <form [formGroup]="item">
                <div class="main-accordion">
                    <p-accordion [activeIndex]="activeIndex">
                        <p-accordionTab [disabled]="disableCriteriaEdit"
                            [ngClass]="{'accordion-disabled': disableCriteriaEdit}">
                            <ng-template pTemplate="header">
                                <div class="criteria-header-cont" aria-expanded="false">
                                    <span class="vertical-align-middle">Criteria {{index+1}}</span>
                                    <div class="btn-container">
                                        <button style="cursor: pointer !important"
                                            class="p-panel-header-icon p-link btn-box"
                                            (click)="previewCriteria($event, index)">
                                            <span style="cursor: pointer !important" class="pi pi-eye"></span>
                                        </button>
                                        <button class="p-panel-header-icon p-link btn-box"
                                            [ngClass]="{'disabled-button': allCriteria.length === 1}"
                                            [disabled]="allCriteria.length === 1"
                                            (click)="deleteCriteria($event, index)">
                                            <span class="pi pi-trash"></span>
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div class="criteria-cont">
                                    <div class="templates-container">
                                        <div class="choose-template-container">
                                            <div class="choose-template-container-title">
                                                <p class="header-content-title">Choose from Template</p>
                                                <div class="card flex justify-content-center p-float-label">
                                                    <p-dropdown [options]="filterOptions"
                                                        [(ngModel)]="selectedFilter[index]" [autoDisplayFirst]="false"
                                                        (onChange)="filterChange(item , index)"
                                                        [ngModelOptions]="{standalone: true}" optionLabel="label"
                                                        optionValue="value" [filter]="true"
                                                        filterBy="label"></p-dropdown>
                                                    <label for="admissionYear">Templates</label>
                                                </div>
                                            </div>

                                            <div>
                                                <span class="save-template" (click)="clearAllSelectedFilters(index)">Clear selection</span>
                                            </div>

                                            <div class="flex justify-end gap-20 choose-template-container-save">
                                                <p-splitButton 
                                                    *ngIf="selectedFilter[index]" 
                                                    label="Save" 
                                                    [model]="items" 
                                                    id="saveTemplate-split-button"
                                                    (onClick)="saveExists(item, index)" 
                                                    styleClass="p-button-raised mr-2 mb-2">
                                                </p-splitButton>
                                                <button 
                                                    *ngIf="!selectedFilter[index]"
                                                    (click)="showDialog()" 
                                                    pButton pRipple class="p-button-create blue-filled-btn" 
                                                    label="Save Template">
                                                </button>
                                            </div>
                                        </div>
                                        <div class="divider mb-40"></div>
                                        <div>
                                            <div>
                                                <p class="header-content">Student Details</p>
                                            </div>
                                            <div class="divider"></div>
                                            <div class="flex">
                                                <div
                                                    class="card flex flex-column justify-content-center p-float-label margin-bottom-20 width50">
                                                    <p-multiSelect [options]="passoutYearsOptions"
                                                        placeholder="Pass Out Year" formControlName="pass_out_year"
                                                        optionLabel="label" optionValue="value" [required]="true">
                                                        <ng-template let-group pTemplate="group">
                                                            <div class="flex align-items-center">
                                                                <span>{{ group.label }}</span>
                                                            </div>
                                                        </ng-template>
                                                    </p-multiSelect>
                                                    <label for="educationalDetails">Pass Out Year<span
                                                            class="imp">*</span></label>
                                                    <app-handle-error [clicked]="clicked"
                                                        [control]="item.get('pass_out_year')" name="Pass Out Year">
                                                    </app-handle-error>
                                                </div>
                                            </div>
                                            <div class="programmes-offered-con">
                                                <span class="programmes-offered-link" (click)="openSidebar(index)">
                                                    Configure Programmes Offered {{ selectedAcademicPrograms[index] &&
                                                    selectedAcademicPrograms[index].length ? '(' +
                                                    selectedAcademicPrograms[index].length + ')' : ''}}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <p class="header-content">Educational Details</p>
                                            </div>
                                            <div class="divider"></div>
                                            <div class="flex space-between">
                                                <div class="flex educational-container">
                                                    <div class="card flex justify-content-center p-float-label width50">
                                                        <p-dropdown [options]="operationOptions"
                                                            formControlName="selected_operation" optionLabel="label"
                                                            optionValue="value" [autoDisplayFirst]="false"
                                                            (onChange)="educationCategory(item)">
                                                        </p-dropdown>
                                                        <label for="educationalDetails">Filter Type</label>
                                                    </div>
                                                    <div class="flex flex-column p-float-label width50"
                                                        *ngIf="item.get('selected_operation')?.value === 'Individual'">
                                                        <p-multiSelect [options]="educationalDetailOptions"
                                                            formControlName="selected_educational_details"
                                                            optionLabel="label" optionValue="value"
                                                            placeholder="Select Education"
                                                            (onChange)="onEducationalDetailChange(item)"
                                                            [required]="true"></p-multiSelect>
                                                        <label for="educationalDetails">Select Education</label>
                                                        <app-handle-error [clicked]="clicked"
                                                            [control]="item.get('selected_operation')?.value === 'Individual' && item.get('selected_educational_details')"
                                                            name="Select Education">
                                                        </app-handle-error>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="save-template"
                                                        (click)="clearEducationFilters(index)">Clear selection</span>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="showSelectedEducationalDetailFields && item.get('selected_operation')?.value === 'Individual'">
                                                <div class="education-ctg"
                                                    *ngFor="let detail of selectedEducationalDetailFields; let i=index">
                                                    <span *ngIf="i % 2 === 0" class="text-content">{{ detail.label
                                                        }}</span>
                                                    <div class="p-inputgroup w-full md:w-30rem">
                                                        <span class="p-inputgroup-addon addon-size">
                                                            Greater than or equal to
                                                        </span>
                                                        <input
                                                            (input)="setRange($event, detail.unitType, detail.controlName, index)"
                                                            pInputText type="number" inputmode="numeric"
                                                            [formControlName]="detail.controlName">
                                                        <span class="p-inputgroup-addon addon-size icon-size"
                                                            *ngIf="detail.unitType === 'percentage'">%</span>
                                                        <span class="p-inputgroup-addon addon-size icon-size"
                                                            *ngIf="detail.unitType === 'cgpa'">CGPA</span>
                                                    </div>
                                                    <div class="error-container">
                                                        <app-handle-error
                                                            *ngIf="detail.unitType === 'percentage' || detail.unitType === 'cgpa'"
                                                            [clicked]="clicked" [control]="item.get(detail.controlName)"
                                                            name="{{detail.unitType === 'percentage' ? 'Percentage' : 'CGPA'}}">
                                                        </app-handle-error>
                                                    </div>
                                                    <div *ngIf="i % 2 !== 0" class="sub-divider"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="item.get('selected_operation')?.value === 'Group'">
                                                <div class="p-inputgroup w-full md:w-30rem">
                                                    <span class="p-inputgroup-addon addon-size">
                                                        Greater than or equal to
                                                    </span>
                                                    <input
                                                        (input)="setRange($event, 'percentage', 'group_percentage', index)"
                                                        pInputText type="number" inputmode="numeric"
                                                        formControlName="group_percentage" [required]="true">
                                                    <span class="p-inputgroup-addon addon-size icon-size">%</span>
                                                </div>
                                                <div class="p-inputgroup w-full md:w-30rem">
                                                    <span class="p-inputgroup-addon addon-size">
                                                        Greater than or equal to
                                                    </span>
                                                    <input (input)="setRange($event, 'cgpa', 'group_cgpa', index)"
                                                        pInputText type="number" inputmode="numeric"
                                                        formControlName="group_cgpa" required="true">
                                                    <span class="p-inputgroup-addon addon-size icon-size">CGPA</span>
                                                </div>
                                                <app-handle-error [clicked]="clicked"
                                                    [control]="item.get('selected_operation')?.value === 'Group' && item.get('group_percentage') && item.get('group_cgpa')"
                                                    name="Percentage and CGPA">
                                                </app-handle-error>
                                                <div class="sub-divider"></div>
                                            </div>
                                            <div class="backlog-container">
                                                <div class="card flex justify-content-center p-float-label">
                                                    <input 
                                                        (input)="backlogsChange($event, index)"
                                                        (keydown)="preventInvalidCharacters($event)"
                                                        type="number"
                                                        pInputText formControlName="current_backlogs"
                                                        inputmode="numeric">
                                                    <label for="current_backlogs">Allow Current Backlogs upto</label>
                                                </div>
                                                <div
                                                    class="card flex justify-content-center p-float-label margin-bottom-20">
                                                    <p-dropdown [options]="historyBacklogsOptions"
                                                        formControlName="backlog_history" optionLabel="label"
                                                        optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                                                    <label>Allow Students with History of
                                                        Backlogs</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <p class="header-content">Personal Profile Filters</p>
                                            </div>
                                            <div class="divider"></div>
                                            <div class="flex">
                                                <div
                                                    class="card flex flex-column justify-content-center p-float-label margin-bottom-20 width50 student-filter-multiselect">
                                                    <p-multiSelect [options]="genderOptions" formControlName="gender" placeholder="Gender" optionLabel="label"
                                                        optionValue="value">
                                                    </p-multiSelect>
                                                    <label for="gender">{{fieldMaps['Gender']}}</label>
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <div
                                                    class="card flex flex-column justify-content-center p-float-label margin-bottom-20 width50 student-filter-multiselect">
                                                    <p-multiSelect [options]="StudentTypeOptions" formControlName="student_type" placeholder="Student Type" optionLabel="label"
                                                        optionValue="value">
                                                    </p-multiSelect>
                                                    <label for="Student Type">Student Type</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="conf-filters">
                                            <div>
                                                <p class="header-content">Placement Filters</p>
                                            </div>
                                            <div class="divider"></div>
                                            <div *ngFor="let filter of placementFilters[index]; let i = index"
                                                class="placement-filter-body flex">
                                                <div class="placement-filter-content flex-column">
                                                    <div class="flex gap-10">
                                                        <div class="card flex justify-content-center p-float-label">
                                                            <p-dropdown [options]="placementFilterOptions"
                                                                [filter]="true" filterBy="label"
                                                                [(ngModel)]="filter.placementField"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                optionLabel="label" [autoDisplayFirst]="false"
                                                                optionValue="value"
                                                                (onChange)="placementFieldsChange(filter)"></p-dropdown>
                                                            <label>Placement Fields
                                                                <span class="imp">*</span>
                                                            </label>
                                                        </div>
                                                        <div class="card flex justify-content-center p-float-label"
                                                            *ngIf="filter.placementField">
                                                            <p-dropdown [options]="filter.conditionOptions"
                                                                [filter]="true" filterBy="label"
                                                                [(ngModel)]="filter.condition"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                optionLabel="label" [autoDisplayFirst]="false"
                                                                optionValue="value"
                                                                (onChange)="filterConditionChanges(filter)"></p-dropdown>
                                                            <label>Select the condition
                                                                <span class="imp">*</span>
                                                            </label>
                                                        </div>
                                                        <div class="card flex justify-content-center p-float-label"
                                                            *ngIf="filter.condition">
                                                            <input *ngIf="filter.inputType === 'input'" type="number"
                                                                inputmode="numeric" pInputText name="filterValue"
                                                                [(ngModel)]="filter.value"
                                                                (input)="validateInput($event, filter)"
                                                                (keydown)="preventInvalidCharacters($event)"
                                                                [ngModelOptions]="{ standalone: true }">
                                                            <label *ngIf="filter.inputType === 'input'">Enter value
                                                                <span class="imp">*</span>
                                                            </label>
                                                            <p-multiSelect *ngIf="filter.inputType === 'multiselect'"
                                                                optionLabel="{{ placementLabelMap[filter.placementField].label }}"
                                                                optionValue="{{ placementLabelMap[filter.placementField].value }}"
                                                                [(ngModel)]="filter.value" placeholder="Filter to add"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                [options]="filter.field_options"></p-multiSelect>
                                                            <label *ngIf="filter.inputType === 'multiselect'"
                                                                for="educationalDetails">Filter to add<span
                                                                    class="imp">*</span></label>
                                                        </div>
                                                    </div>
                                                    <app-handle-error [clicked]="clicked"
                                                        [ngModelValue]="filter.placementField && filter.condition && checkValidValue(filter.value)"
                                                        name="Fill all the mandatory fields!">
                                                    </app-handle-error>
                                                </div>
                                                <div class="flex" style="padding-top:25px;">
                                                    <button class="delete-icon"
                                                        (click)="removePlacementFilter(i, index, 'placement')"><span
                                                            class="pi pi-trash"></span></button>
                                                </div>
                                            </div>

                                            <div class="filter-cont">
                                                <div class="filter-container-box"
                                                    (click)="placementFilter(index, 'placement')">
                                                    <img class="flex justify-center" [src]="eligibilityAddIcon" alt=""
                                                        title="eligibile-students">
                                                    <p class="add-filter">Add Filter</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="conf-filters">
                                            <div>
                                                <p class="header-content">Internship Filters</p>
                                            </div>
                                            <div class="divider"></div>
                                            <div *ngFor="let filter of internshipFilters[index]; let i = index"
                                                class="placement-filter-body flex">
                                                <div class="placement-filter-content flex-column">
                                                    <div class="flex gap-10">
                                                        <div class="card flex justify-content-center p-float-label">
                                                            <p-dropdown [options]="internShipFilterOptions"
                                                                [filter]="true" filterBy="label"
                                                                [(ngModel)]="filter.placementField"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                optionLabel="label" [autoDisplayFirst]="false"
                                                                optionValue="value"
                                                                (onChange)="placementFieldsChange(filter)"></p-dropdown>
                                                            <label for="internship_fields">Internship Fields
                                                                <span class="imp">*</span>
                                                            </label>
                                                        </div>
                                                        <div class="card flex justify-content-center p-float-label"
                                                            *ngIf="filter.placementField">
                                                            <p-dropdown [options]="filter.conditionOptions"
                                                                [filter]="true" filterBy="label"
                                                                [(ngModel)]="filter.condition"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                optionLabel="label" [autoDisplayFirst]="false"
                                                                optionValue="value"
                                                                (onChange)="filterConditionChanges(filter)"></p-dropdown>
                                                            <label for="selected_conditon">Select the condition
                                                                <span class="imp">*</span>
                                                            </label>
                                                        </div>
                                                        <div class="card flex justify-content-center p-float-label"
                                                            *ngIf="filter.condition">
                                                            <input *ngIf="filter.inputType === 'input'" type="number"
                                                                inputmode="numeric" pInputText name="filterValue"
                                                                [(ngModel)]="filter.value"
                                                                (input)="validateInput($event, filter)"
                                                                (keydown)="preventInvalidCharacters($event)"
                                                                [ngModelOptions]="{ standalone: true }">
                                                            <label *ngIf="filter.inputType === 'input'" for="value">Enter
                                                                value
                                                                <span class="imp">*</span>
                                                            </label>
                                                            <p-multiSelect *ngIf="filter.inputType === 'multiselect'"
                                                                optionLabel="{{ placementLabelMap[filter.placementField].label }}"
                                                                optionValue="{{ placementLabelMap[filter.placementField].value }}"
                                                                [(ngModel)]="filter.value" placeholder="Filter to add"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                [options]="filter.field_options"></p-multiSelect>
                                                            <label *ngIf="filter.inputType === 'multiselect'"
                                                                for="educationalDetails">Filter to add<span
                                                                    class="imp">*</span></label>
                                                        </div>
                                                    </div>
                                                    <app-handle-error [clicked]="clicked"
                                                        [ngModelValue]="filter.placementField && filter.condition && checkValidValue(filter.value)"
                                                        name="Fill all the mandatory fields!">
                                                    </app-handle-error>
                                                </div>
                                                <div class="flex" style="padding-top:25px;">
                                                    <button class="delete-icon"
                                                        (click)="removePlacementFilter(i, index, 'internship')"><span
                                                            class="pi pi-trash"></span></button>
                                                </div>
                                            </div>

                                            <div class="filter-cont">
                                                <div class="filter-container-box"
                                                    (click)="placementFilter(index, 'internship')">
                                                    <img class="flex justify-center" [src]="eligibilityAddIcon" alt=""
                                                        title="eligibile-students">
                                                    <p class="add-filter">Add Filter</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="conf-filters">
                                            <div>
                                                <p class="header-content">Custom Field Filters</p>
                                            </div>
                                            <div class="divider"></div>
                                            <div *ngFor="let filter of filters[index]; let i = index"
                                                class="config-filter-body flex">
                                                <div class="config-filter-content flex-column">
                                                    <div class="flex gap-10 flex-wrap">
                                                        <div class="card flex justify-content-center p-float-label">
                                                            <p-dropdown [options]="studentCampusOptions"
                                                                [(ngModel)]="filter.account_id"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                optionLabel="label" optionValue="value"
                                                                (ngModelChange)="onChangeCampus(filter)"
                                                                [autoDisplayFirst]="false">
                                                                <ng-template let-option pTemplate="item">
                                                                    <div [pTooltip]="option.label.length > 30 ? option.label : null"
                                                                        tooltipPosition="top">
                                                                        {{ option.label }}
                                                                    </div>
                                                                </ng-template>
                                                            </p-dropdown>
                                                            <label for="campus">Campus
                                                                <span class="imp">*</span>
                                                            </label>
                                                        </div>
                                                        <div class="card flex justify-content-center p-float-label">
                                                            <p-dropdown [options]="filter.academicOptions"
                                                                [(ngModel)]="filter.pass_out_year"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                optionLabel="label"
                                                                (ngModelChange)="academicsChange(filter)"
                                                                [autoDisplayFirst]="false" optionValue="value">
                                                                <ng-template let-option pTemplate="item">
                                                                    <div [pTooltip]="option.label.length > 30 ? option.label : null"
                                                                        tooltipPosition="top">
                                                                        {{ option.label }}
                                                                    </div>
                                                                </ng-template>
                                                            </p-dropdown>
                                                            <label for="campus">Pass Out Year
                                                                <span class="imp">*</span>
                                                            </label>
                                                        </div>
                                                        <div class="card flex justify-content-center p-float-label">
                                                            <p-dropdown [options]="filter.customFieldOptions"
                                                                [filter]="true" filterBy="label"
                                                                [(ngModel)]="filter.attribute_id"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                optionValue="value"  [autoDisplayFirst]="false"
                                                                optionLabel="label" 
                                                                (onChange)="customFieldsChange(filter)"></p-dropdown>
                                                            <label>Custom Fields
                                                                <span class="imp">*</span>
                                                            </label>
                                                        </div>
                                                        <div class="card flex justify-content-center p-float-label"
                                                            *ngIf="filter.attribute_id">
                                                            <p-dropdown [options]="filter.conditionOptions"
                                                                [filter]="true" filterBy="label"
                                                                [(ngModel)]="filter.condition"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                optionLabel="label" [autoDisplayFirst]="false"
                                                                optionValue="value"
                                                                (onChange)="filterConditionChange(filter)"></p-dropdown>
                                                            <label>Select the condition
                                                                <span class="imp">*</span>
                                                            </label>
                                                        </div>
                                                        <div class="card flex justify-content-center p-float-label"
                                                            *ngIf="filter.condition">
                                                            <input *ngIf="filter.inputType === 'input'" type="text"
                                                                pInputText name="filterValue" [(ngModel)]="filter.value"
                                                                [ngModelOptions]="{ standalone: true }">
                                                            <label *ngIf="filter.inputType === 'input'">Enter value
                                                                <span class="imp">*</span>
                                                            </label>
                                                            <p-multiSelect *ngIf="filter.inputType === 'multiselect'"
                                                                [(ngModel)]="filter.value" placeholder="Filter to add"
                                                                [ngModelOptions]="{ standalone: true }"
                                                                [options]="filter.fieldOptions"></p-multiSelect>
                                                            <label *ngIf="filter.inputType === 'multiselect'"
                                                                for="educationalDetails">Filter to add<span
                                                                    class="imp">*</span></label>
                                                        </div>
                                                    </div>
                                                    <app-handle-error [clicked]="clicked"
                                                        [ngModelValue]="filter.pass_out_year&& filter.account_id && filter.attribute_id && filter.condition && checkValidValue(filter.value)"
                                                        name="Fill all the mandatory fields!">
                                                    </app-handle-error>
                                                </div>
                                                <div class="flex" style="padding-top:25px ;">
                                                    <button class="delete-icon" (click)="removeFilter(i, index)"><span
                                                            class="pi pi-trash"></span></button>
                                                </div>
                                            </div>

                                            <div class="filter-cont">
                                                <div class="filter-container-box" (click)="addFilter(index)">
                                                    <img class="flex justify-center" [src]="eligibilityAddIcon" alt=""
                                                        title="eligibile-students">
                                                    <p class="add-filter">Add Filter</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card flex justify-content-center">
                                    <div *ngIf="dialogPurpose === 'save_template'">
                                        <p-dialog class="save-template-dialog" header="Header" [(visible)]="visible"
                                            [style]="{ width: '30vw' }" [modal]="true" [draggable]="false"
                                            [resizable]="false">
                                            <ng-template pTemplate="header">
                                                <span class="text-xl font-bold header">Save Template</span>
                                            </ng-template>
                                            <div class="card flex justify-content-center p-float-label">
                                                <input type="text" [ngModelOptions]="{standalone: true}" pInputText
                                                    [(ngModel)]="saveTemplateInput">
                                                <label>Template Name</label>
                                            </div>
                                            <ng-template pTemplate="footer">
                                                <button pButton pRipple class="p-button-create blue-filled-btn"
                                                    label="Save" (click)="saveTemplate(item, index)"></button>
                                            </ng-template>
                                        </p-dialog>
                                    </div>
                                </div>
                            </ng-template>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </form>
        </ng-container>
        <ng-container>
            <div class="view-eligible-students">
                <div class="eligible-btn">
                    <app-button [btnLoading]="disableCriteriaEdit"
                        [btnProperties]="showTable ? btnProperties['refresh'] : btnProperties['viewEligibileStudents']"
                        (buttonClick)="viewEligibleStudents(true)"></app-button>
                </div>
                <div *ngIf="showTable">
                    <div class="table-cont table-relative table-style eligiblity-table">
                        <app-table [isLoading]="isLoading" [columns]="columns" [dataList]="dataList"
                            searchBy=' Email or Registration Number ' (sortBy)="sortChange($event)" (setTableDefaultsEvent)="setTableDefaults()"
                            [loadingSpinner]="tableLoader" [dataCount]="dataCount" [pageLimits]="pageLimits"
                            [captionsList]="captionsList" [tableheight]="tableHeight" [totalPage]="totalPage"
                            (downloadEvent)="downloadEligibilityData()" (rowLength)="rowLengthChange($event)" (searchChange)="searchChange($event)"
                            (pageNo)="pageChange($event)" [showDownload]="true" (search)="searchValueChange($event)">
                        </app-table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- preview criteria -->
<p-dialog header="Preview Criteria" [(visible)]="showPreviewCriteria" [modal]="true" [draggable]="false"
    [resizable]="false" [style]="{width: '1000px',  height: '500px'}" (onHide)="onHidePreviewCriteria()"
    class="preview-criteria-dialog">
    <ng-template pTemplate="header">
        <div class="header-cont remove-padding">
            <div class="header-container-divider">
                <span>Preview Criteria {{ previewIndex + 1 }}</span>
                <div class="preview-divider"></div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="body">
        <div class="preview-drive-clash">

            <div class="preview-title">
                <span>Basic Filters</span>
            </div>

            <div *ngFor="let item of previewData['basicCriteria']">
                <div class="preview-text-container">
                    <div class="preview-key">
                        <span>{{ item.label }}</span>
                    </div>
                    <div class="preview-value">
                        <span>{{ item.value || '-'}}</span>
                    </div>
                </div>
            </div>

            <div class="preview-title" *ngIf="previewData['placementFilters']?.length">
                <span>Placement Filters</span>
            </div>

            <div *ngFor="let item of previewData['placementFilters']">
                <div class="preview-text-container">
                    <div class="preview-key">
                        <span>{{ item.label }}</span>
                    </div>
                    <div class="preview-conditon">
                        <span> {{ item.condition }} </span>
                    </div>
                    <div class="preview-value-filter">
                        <span>{{ item.value || '-'}}</span>
                    </div>
                </div>
            </div>

            <div class="preview-title" *ngIf="previewData['internshipFilters']?.length">
                <span>Internship Filters</span>
            </div>

            <div *ngFor="let item of previewData['internshipFilters']">
                <div class="preview-text-container">
                    <div class="preview-key">
                        <span>{{ item.label }}</span>
                    </div>
                    <div class="preview-conditon">
                        <span> {{ item.condition }} </span>
                    </div>
                    <div class="preview-value-filter">
                        <span>{{ item.value || '-'}}</span>
                    </div>
                </div>
            </div>

            <div class="preview-title" *ngIf="previewData['customCriteria']?.length">
                <span>Custom Field Filters</span>
            </div>

            <div *ngFor="let item of previewData['customCriteria']">
                <div class="preview-text-container">
                    <div class="preview-key">
                        <span>{{ item.label }}</span>
                    </div>
                    <div class="preview-conditon">
                        <span> {{ item.condition }} </span>
                    </div>
                    <div class="preview-value-filter">
                        <span>{{ item.value || '-'}}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-dialog>

<!-- programmes offered sidebar -->
<p-sidebar class="programmes-offered-sidebar" [(visible)]="showProgrammesOffered" position="right" [dismissible]="false"
    (onHide)="onHideSidebar()">

    <ng-template pTemplate="header">
        <div class="programmes-offered-header">
            <span class="programmes-offered-text">Programme Offered</span>
            <div class="btn-container sidebar-top-btns">
                <div class="sidebar-top-btn">
                    <app-button [btnProperties]="btnProperties['cancel']" (buttonClick)="onHideSidebar()">
                    </app-button>
                </div>
                <div class="sidebar-top-btn">
                    <app-button [btnProperties]="btnProperties['confirm']" (buttonClick)="setProgrammsOffer()">
                    </app-button>
                </div>
            </div>
        </div>
        <p-divider layout="horizontal" class="p-divider-width"></p-divider>
    </ng-template>

    <ng-template pTemplate="body">
        <div class="programmes-offer-table">
            <div class="table-filters">
                <div class="filter-cont-table">
                    <span class="middle-left card flex justify-content-center p-float-label"
                        *ngFor="let row of filterData; let i = index">
                        <p-multiSelect [options]="row.array" [(ngModel)]="selectedFilters[row.key]" optionLabel="label"
                            optionValue="value" [maxSelectedLabels]="1"
                            [selectedItemsLabel]="'{selectedFilters[row.filterName.toLowerCase()].length} ' + 'item selected'"
                            [placeholder]="row.filterName" (onChange)="handleFilterChange(row.key, $event)">
                            <ng-template let-option pTemplate="item">
                                <div [pTooltip]="option.label.length > 30 ? option.label : null" tooltipPosition="top">
                                    {{ option.label.length > 40 ? option.label.substring(0, 40) + '...' : option.label
                                    }}
                                </div>
                            </ng-template>
                        </p-multiSelect>
                        <label style="font-size: var(--fs-medium);">{{row.filterName}}</label>
                    </span>
                    <div class="clear-container">
                        <span class="flex gap-5 align-center">
                            <div>
                                <span class="clear-all" (click)="clearAllFilters()">Clear All</span>
                            </div>
                        </span>

                        <span class="flex gap-5 align-center">
                            <div>
                                <span class="apply-filters" (click)="applyFilter()">Apply Filter</span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="table-cont table-relative">
                <app-table #programsOffered class="programms-list-table" [columns]="programsOffersTableCols"
                    [dataList]="programsData" [dataCount]="campusDetails.length" [additionalOptions]="additionalOptions"
                    [fillTableDataOnLoad]="true" [remValue]="'16'" [captionsList]="programsOfferTableCaptions"
                    [tableheight]="programsTableHeight" (search)="onProgrammesSearch($event)"
                    searchBy="Programmes Offered" (additionalOptionChange)="fillProgramsOfferTable($event)">
                </app-table>
            </div>
        </div>
    </ng-template>

</p-sidebar>

<!-- download bar -->
<p-sidebar class="download-sidebar" [(visible)]="showDownloadBar" position="right" (onHide)="clearInputs()"
    [dismissible]="false">
    <ng-template pTemplate="header">
        <div class="header-cont">
            <span>Download Eligible Students Data</span>
            <div class="flex gap-10">
                <button pbutton class="p-button-create blue-filled-btn" (click)="downloadData()" type="submit"
                    label="Save">Download
                </button>
            </div>
        </div>
        <p-divider class="divider-download" layout="horizontal"></p-divider>
    </ng-template>
    <ng-template pTemplate="body">
        <div class="sidebar-body">
            <div class="radioButton-group">
                <span>Download as</span>
                <div class="radioButton">
                    <p-radioButton value="excel" inputId="excel" [(ngModel)]="downloadColumnAs">
                    </p-radioButton>
                    <label for="excel"
                        [style]="{color: downloadColumnAs === 'excel' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                            class="pi pi-file"></em>Excel</label>
                </div>
                <div class="radioButton">
                    <p-radioButton value="csv" inputId="csv" [(ngModel)]="downloadColumnAs">
                    </p-radioButton>
                    <label for="csv"
                        [style]="{color: downloadColumnAs === 'csv' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                            class="pi pi-file-excel"></em>CSV</label>
                </div>
            </div>

            <div class="fields-container">
                <div class="title-box">
                    <span>Student Data</span>
                </div>

                <div>
                    <div class="selectAll-checkbox field-checkbox">
                        <p-checkbox [binary]="true" (onChange)="selectAllDownloadData()"
                            [(ngModel)]="isSelectedAll"></p-checkbox>
                        <span>Select All</span>
                    </div>

                    <div class="fields">
                        <div *ngFor="let field of downloadFields" class="field-checkbox">
                            <p-checkbox [binary]="true" [(ngModel)]="field.active"
                                (onChange)="onDropDownChange()"></p-checkbox>
                            <label [for]="field.label">{{ field.label }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-sidebar>

<p-toast></p-toast>