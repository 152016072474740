<div class="headerCont" #headerCont>
    <div class="rightcont">
        <div class="heading">
            <app-ng-dynamic-breadcrumb class="breadCrumb" bgColor="var(--clr-white)" fontSize="16px"
            fontColor="var(--fc-light-dark)" symbol=">"></app-ng-dynamic-breadcrumb>
        </div>
    </div>
    <p-sidebar [appendTo]="'body'" [style]="{width: '40vw'}" [(visible)]="profileSidebar" position="right" [dismissible]="false" (onHide)="onCloseSideBar()">
        <ng-template pTemplate="header">
            <div class="header-cont">
                <span style="font-size: 22px;">Profile</span>
                <button pbutton class="p-button-create blue-filled-btn" (click)="onEditProfile()" type="submit">
                    Update</button>
            </div>
            <p-divider [ngStyle]="{'width': '100%'}" layout="horizontal"></p-divider>
        </ng-template>
        <ng-template pTemplate="body">
            <ng-container *ngIf="isLoading ; then loader ;else nonLoader"></ng-container>
            <ng-template #loader>
                <p-progressSpinner class="loading-spinner"></p-progressSpinner>
            </ng-template>
            <ng-template #nonLoader>
                <div class="flex">
                    <input #fileInput type="file" (change)="onFileSelected($event , 'school')" accept="image/*"
                        style="display: none;">
                    <div *ngIf="selectedImage" class="image-preview" [ngStyle]="{'background-image': 'url(' + profilePicBackground + ')'}">
                        <img [src]="selectedImage" alt="Preview">
                    </div>
                    <button [ngClass]="{ 'side-upload': selectedImage}" label="Primary"
                        class="p-button-create blue-outlined-btn" (click)="fileInput.click()">
                        {{selectedImage ? 'Re-Upload' : 'Upload'}}
                    </button>
                </div>
                <div style="display: flex; flex-direction: column ;gap: 5px; margin-top: 30px;" >
                    <form style="display: flex; flex-direction: column;" class="createform" [formGroup]="profileFields"
                        type="submit" id="create-student">
                        <div class="flex-container" style="gap: 20px;" id="input-width">
                            <ng-container *ngFor="let row of profileDetails; let i = index">
                                <span *ngIf="row.type !== 'editor'" class="create-fields"
                                    style="display: flex; gap: 10px; font-size: var(--fs-medium);">
                                    <label for="inputtext">{{row.label}}<span class="imp">*</span></label>
                                    <input style="width: 30vw;font-size: var(--fs-medium);"
                                        [ngStyle]="{'cursor': !row.disable ? 'auto' : 'not-allowed'}"
                                        formControlName="{{row.controlfield}}" pInputText [readonly]="row.disable">
                                </span>
                            </ng-container>
                        </div>
                    </form>
                </div>
            </ng-template>
        </ng-template>
    </p-sidebar>
    <div class="leftcont">
        <div class="bell-container">
            <span class="bell-icon pi pi-bell" (click)="openNotification()"></span>
            <div class="badge" *ngIf="notificationService.notificationCount">{{notificationService.notificationCount}}</div>
        </div>
        <div class="user-profile"  style="cursor: pointer;">
            <div class="user-image">
                <img [src]="selectedImage" alt="user-profile">
            </div>
            <div (click)="moreFilter.toggle($event)" class="user-name">{{userData ? userData?.first_name+' '+userData?.last_name : ''}} <i class="down-arrow pi pi-chevron-down"></i></div>
        </div>

        
        <p-overlayPanel #moreFilter>
            <ng-template pTemplate="content">
                <div class="overlay-cnt">
                    <div *ngFor="let options of actionOptions">
                        <span class="action-option" (click)="onClick(options)"><i
                            class="pi {{options.icon}}"></i>
                        {{options.label}}</span>
                    </div>
                </div>
            </ng-template>
        </p-overlayPanel>
    </div>
</div>

 <div class="notification">
        <p-sidebar 
            [modal]="true"
            id="notification"
            [appendTo]="'body'"
            (onHide)="closeDialog()" 
            [style]="{width: '40vw'}"
            [(visible)]="showNotification" 
            position="right" 
            [dismissible]="false"
        >
            <ng-template pTemplate="header">
                <div class="flex" id="notification-header">
                    <div class="notify-cont">
                        <span>Notifications</span>
                        <label class="flex align-center" (click)="markAllAsRead()" style="color: var(--clr-primary); cursor: pointer; font-size: medium;"><u>Mark all as read</u></label>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="body">
                <div class="notification-cnt">
                    <div class="flex gap-10 justify-end">
                        <p-inputSwitch class="flex align-center" (click)="unreadOnly()"></p-inputSwitch>
                        <label class="flex align-center">Unread only</label>
                    </div>
                    <div *ngFor="let item of notificationService.notificationData" class="flex gap-30 width100 notification-loop">
                        <div class="notification-container" >
                          <div class="field-name">
                            <img style="width: 25px;" [src]="getIconSrc(item.s3link.split('.').pop())" alt="File Icon">
                            <div style="cursor: pointer;" [pTooltip]="item.title .length > 30 ? item.title  : null"
                                tooltipPosition="top">
                                {{ item.title.length > 40 ? item.title.substring(0, 40) + '...' :
                                item.title }}
                            </div>
                            <div *ngIf="item.status == 'unread'">
                                <span>New</span>
                            </div>
                          </div>
                          <div class="notification-download-box" >
                            <span class="download-notification-data" (click)="fileAction(item)">Download</span>
                            <span class="pi pi-trash delete-icon" (click)="deleteNotification(item)"></span>
                          </div>
                          <div class="date-time" >
                            <span>{{item.createdAt | date: dateFormat }}</span>
                          </div>
                        </div>
                      </div>    
                      <div *ngIf="notificationService.notificationData.length === 0" class="no-notification">
                        <span class="empty-notification">No New Notifications</span>
                      </div>                                      
                </div>
            </ng-template>
        </p-sidebar>
</div>
<p-toast></p-toast>