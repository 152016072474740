import { Component } from '@angular/core';
import { CustomMessageService } from 'src/app/core/services/custom-message.service';
import { FileUploadService } from 'src/app/core/services/fileupload.service';
import { emailBoxIcon, pushMessageIcon } from '@assets';
import { GlobalService } from 'src/app/core/services/global.service';

interface IFilterData {
  filterName: string;
  key: string;
  array: any[];
}
@Component({
  selector: 'app-custom-message',
  templateUrl: './custom-message.component.html',
  styleUrls: ['./custom-message.component.css']
})

export class CustomMessageComponent {
  searchValue: any = '';
  searchColor: boolean = true;
  accountData: any = localStorage.getItem('account_details')
  sendBy: any;
  pushMessageIcon: any = pushMessageIcon;
  emailBoxIcon: any = emailBoxIcon;
  correlationIds: any;
  campaignData: any;
  selectedCampaign: any;
  rangeDates: Date[] | undefined;
  group_account_id: any;
  selectedFilters: any = {
    messageType: [],
    sentBy: [],
    sentOn: [],
    searchValue: []
  };
  selectedCampaignIndex: number = 0; 
  isLoading: boolean = false
  filterData: IFilterData[] = [
    {
      filterName: "Message Type",
      key: 'messageType',
      array: [
        { label: 'Email', value: 'email' },
        { label: 'Push Message', value: 'push-message' },
        { label: 'Email & Push Message', value: 'email&push-message' },
      ]
    },
    {
      filterName: "Sent By",
      key: 'sentBy',
      array: []
    },
    {
      filterName: "Sent On",
      key: 'sentOn',
      array: []
    },
    {
      filterName: "Search",
      key: 'searchValue',
      array: []
    }

  ];
  filteredCampaignData: any;
  constructor(private customMessageService: CustomMessageService, public globalService : GlobalService,
    private fileService: FileUploadService) {
      this.accountData = JSON.parse(this.accountData);
      this.group_account_id = this.accountData.group_account_id;
  }
  templateBody : any;
  subjectTitle: any;
  senderEmail: any;

  ngOnInit() {
    this.isLoading= true;
    this.formatData();
    this.isLoading= false;
  }

  async formatData() {
    const payload1 = this.accountData.group_account_id; 
    const response = await this.customMessageService.getAllCampaign(payload1);
    this.sendBy = Array.from(new Set(response.map((campaign:any) => campaign.sender_email)));
    this.filterData[1].array = this.sendBy;
    this.campaignData = response;
    if (this.campaignData.length > 0) {
      this.getSpecificCampaign(this.campaignData[0], 0);
    }
  }

  async getSpecificCampaign(data: any, index: number) {
    this.selectedCampaignIndex = index;
    await this.fileService.getJsonFromUrl(data.object_link).subscribe(async ids => {
      this.correlationIds = ids;
      const payload = {
        "correlationIds": this.correlationIds
      }
      const response = await this.customMessageService.getCampaignStatus(payload);
      this.selectedCampaign = {...data, ...response[0]};
    }, error => {
      console.error('Error fetching JSON data:', error);
    });

    await this.fileService.getJsonFromUrl(data.template_body).subscribe(async template => {
      this.selectedCampaign = {...this.selectedCampaign, ...template};
      console.log("this.selectedCampaign",this.selectedCampaign);
      this.templateBody = this.selectedCampaign.template_content;
      this.subjectTitle = this.selectedCampaign.subject_title;
      this.senderEmail = this.selectedCampaign.sender_email;

    }, error => {
      console.error('Error fetching JSON data:', error);
    });

  }

  async applyFilters() {
    // api for filter
    this.campaignData = await this.customMessageService.filterCampaignData(this.group_account_id, this.selectedFilters);
  }

  isDateInRange(dateString: string): boolean {
    const date = new Date(dateString);
    if (this.selectedFilters.sentOn.length === 2) {
      const [startDate, endDate] = this.selectedFilters.sentOn;
      return date >= startDate && date <= endDate;
    }
    return true;
  }

  clearFilters() {
    this.selectedFilters = {
      messageType: [],
      sentBy: [],
      sentOn: [],
    };
    this.filteredCampaignData = this.campaignData;
  }

  onSearchChange(event: any) {
    let searchValue = event.target.value
    this.searchColor = searchValue.length > 0;
  }
  searchValueChange(event: any) {
    this.searchValue = event.target.value;
    let campaignDatas = this.campaignData;
    if(this.searchValue.length > 0) {
      this.campaignData = campaignDatas.filter((campaign: any) => {
        return campaign.campaign_type.toLowerCase().includes(this.searchValue.toLowerCase());
      });
    }
  }
}
