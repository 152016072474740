import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../../interfaces/api-response';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private api_url = environment.HOST_LINK;
  constructor(private httpClient: HttpClient) {
  }


  headerOptions(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: this.getAuthToken(),
    });
    return headers;
  }

  private getAuthToken(): string {
    const token: string | null = localStorage.getItem('token');
    return token ? JSON.parse(token) : '';
  }

  get(endpoint: string): Promise<ApiResponse> {
    return new Promise((resolve) => {
      this.httpClient.get<ApiResponse>(this.api_url + endpoint, { headers: this.headerOptions()}).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error: HttpErrorResponse) => {
          resolve(error.error);
        },
      });
    });
  }

  post(endpoint: string, body: any): Promise<ApiResponse> {
    return new Promise((resolve) => {
      this.httpClient
        .post<ApiResponse>(this.api_url + endpoint, body,{ headers: this.headerOptions()})
        .subscribe({ 
          next: (data) => {
            resolve(data);
          },
          error: (error: HttpErrorResponse) => {
            resolve(error.error);
          },
        });
    });
  }

  put(endpoint: string, body: any): Promise<ApiResponse> {
    return new Promise((resolve) => {
      this.httpClient
        .put<ApiResponse>(this.api_url + endpoint, body, {
          headers: this.headerOptions(),
        })
        .subscribe({
          next: (data) => {
            resolve(data);
          },
          error: (error: HttpErrorResponse) => {
            resolve(error.error);
          },
        });
    });
  }

  patch(endpoint: string, body: any): Promise<ApiResponse> {
    return new Promise((resolve) => {
      this.httpClient
        .patch<ApiResponse>(this.api_url + endpoint, body, {
          headers: this.headerOptions(),
        })
        .subscribe({
          next: (data) => {
            resolve(data);
          },
          error: (error) => {
            resolve(error);
          },
        });
    });
  }

  delete(endpoint: string): Promise<ApiResponse> {
    return new Promise((resolve) => {
      this.httpClient
        .delete<ApiResponse>(this.api_url + endpoint, {
          headers: this.headerOptions(),
        })
        .subscribe({
          next: (data) => {
            resolve(data);
          },
          error: (error) => {
            resolve(error);
          },
        });
    });
  }

  getTokens(endpoint: string): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.api_url + endpoint, { headers: this.headerOptions() });
  }

  // getFile(endpoint: string): Promise<any> {
  //   return new Promise((resolve) => {
  //     this.httpClient.get(endpoint ,{responseType: 'blob' }).subscribe({
  //       next: (data) => {
  //         resolve(data);
  //       },
  //       error: (error) => {
  //         resolve(error);
  //       },
  //     });
  //   });
  // }

  getFile(endpoint: string): Observable<any> {
    return this.httpClient.get(endpoint, { responseType: 'blob' });
  }

  uploadFile(endpoint: string, body: any): Observable<any> {
    return this.httpClient.put(endpoint, body, { observe: 'response' });
  }
}
