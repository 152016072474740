import { Component, OnInit, ViewChild } from '@angular/core';
import { actions, action } from "@models";
import { HttpService, GlobalService, FileUploadService, NotificationService } from '@services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Editor } from 'primeng/editor';
import { MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import { 
  notificationXlsx,
  notificationCsv,
  notificationDoc,
  notificationPdf,
  notificationZip,
  defaultProfile,
  profilePicBackground
}
from '@assets';
import { Breadcrumb, Eglobal, Severity, Summary, Message } from '@enum';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [MessageService]
})

export class HeaderComponent implements OnInit{
  @ViewChild('editor') editor!: Editor;
  userData: any = localStorage.getItem('user_details')
  accountData: any = localStorage.getItem('account_details')
  profileFields: FormGroup = new FormGroup({});
  profileDetails: any[] = []
  showNotification: boolean = false;
  spacePattern = /^\s*$/;
  spaceError = /^(\s.*|\s)$/;
  isLoading: boolean = false
  showUnRead: boolean = false
  profileSidebar: boolean = false
  imageChanged: boolean = false
  openNotificationAction: boolean = false
  breadCrumbObj: {[key: string]: string} = {
    'dashboard': Breadcrumb.DASHBOARD,
    'programme-mapping': Breadcrumb.PROGRAMMEMAPPING,
    'drives': Breadcrumb.DRIVES,
    'settings': Breadcrumb.SETTINGS,
    'eligibility': Breadcrumb.ELIGIBILITYCHECK,
    'email-template': Breadcrumb.EMAILTEMPLATE,
    'students': Breadcrumb.STUDENTS,
    'custom-message': Breadcrumb.CUSTOMMESSAGE,
  }
  selectedImage: string = defaultProfile;
  actionOptions: action[] = [
    { icon:'pi-user', label: 'View Profile', value: 'View Profile' },
    { icon:'pi-sign-out', label: 'Logout', value: 'Logout' }
  ]
  dateFormat: string = 'dd-MMM-YYYY hh:mm a';
  profilePicBackground: string = profilePicBackground;
  constructor(
      public httpService: HttpService, 
      public http: HttpService, 
      private messageService: MessageService, 
      public fileUploadService: FileUploadService,
      public globalService: GlobalService,
      public notificationService: NotificationService
    ) {
    
    this.userData = JSON.parse(this.userData)
    this.accountData = JSON.parse(this.accountData)
    this.profileDetails = [
      {
        label: 'First Name',
        disable: false,
        type: 'input',
        controlfield: 'first_name',
        mandatory: true,
      },
      {
        label: 'Last Name',
        disable: false,
        type: 'input',
        controlfield: 'last_name',
        mandatory: true,
      },
      {
        label: 'Email',
        disable: true,
        type: 'input',
        controlfield: 'primary_email',
        mandatory: true,
      }
    ];

    this.notificationService.requestNotificationsPermissions();
    this.notificationService.fetchNotifications();
  }

  async ngOnInit() {
    this.profileFields = this.createProfileFormGroup();
    if(this.userData.profile_picture) {
      const signedUrl = await this.globalService.getSignedUrl({ bucketName: this.globalService.bucketName, fileName: this.userData.profile_picture });
      this.selectedImage = signedUrl.data.response;
    };
  }

  createProfileFormGroup() {
    const formGroupConfig: any = {};
    this.profileDetails.forEach((data) => {
      formGroupConfig[data.controlfield] = new FormControl(
        '',
        data.mandatory ? Validators.required : null
      );
    });
    return new FormGroup(formGroupConfig);
  }

  openNotification(){
    this.showNotification = true;
  }

  closeDialog() {
    this.openNotificationAction = false;
    this.showNotification = false;
  }

  async markAllAsRead() {
    this.isLoading = true;
    this.notificationService.notificationData.forEach((item) => {
      item.status = 'read';
    });
    this.notificationService.notificationCount = 0;
    await this.httpService.post('/notifications/update', { data: this.notificationService.notificationData });
    this.isLoading = false;
  }

  onClickAction(options: actions){
    this.openNotificationAction = false; 
  }

  async onEditProfile() {
    if (this.isLoading) return;
  
    const payload = this.constructPayload();
    if (!payload) return;
  
    if (this.imageChanged) {
      const imageUploadSuccess = await this.uploadProfileImage(payload);
      if (!imageUploadSuccess) return;
    }
  
    if (!Object.keys(payload).length) {
      this.showWarningMessage(Message.UNCHANGED);
      return;
    }
  
    payload.primary_email = this.userData.primary_email;
    this.isLoading = true;
  
    const res = await this.httpService.post('/updateProfile', payload);
    this.handleProfileUpdateResponse(res, payload);
  }
  
  private constructPayload() {
    let payload: any = {};
    const userData = JSON.parse(localStorage.getItem('user_details') ?? '');
  
    for (let fields in this.profileFields.controls) {
      if (this.profileFields.controls[fields].value !== userData[fields]) {
        payload[fields] = this.profileFields.controls[fields].value;
      }
    }
  
    if (this.spacePattern.test(payload.first_name) || this.spaceError.test(payload.first_name)) {
      this.showErrorMessage(Message.VALIDFIRSTNAME);
      return null;
    } else if (this.spacePattern.test(payload.last_name) || this.spaceError.test(payload.last_name)) {
      this.showErrorMessage(Message.VALIDLASTNAME);
      return null;
    }
  
    return payload;
  }
  
  private async uploadProfileImage(payload: any) {
    const type: any = this.selectedImage.split(';base64')[0].split(':')[1];
    let blobData: any = this.dataURItoBlob(this.selectedImage, type);
    let pay = {
      bucketName: this.globalService.bucketName,
      fileName: `user_image/${this.accountData.group_account_id}/${this.userData.user_id}/${uuidv4()}`,
      type: type,
    };
    const s3SignedUrl = await this.globalService.getSignedUploadUrl(pay);
    let s3Response: any = await this.fileUploadService.uploadUsingSignedUrl(s3SignedUrl['data'].response, blobData);
    
    if (s3Response && s3Response.status !== 200) {
      this.showErrorMessage(Message.PROFILEUPDATEFAILED);
      this.profileSidebar = false;
      this.isLoading = false;
      return false;
    }
  
    payload.profile_picture = pay.fileName;
    return true;
  }
  
  private handleProfileUpdateResponse(res: any, payload: any) {
    if (res?.success && res?.data) {
      const { data } = res;
      this.showSuccessMessage(Message.PROFILEUPDATE);
      this.profileSidebar = false;
      this.isLoading = false;
      this.userData = { ...data };
      this.updateLocalStorage(data);
    } else {
      this.showErrorMessage(Message.PROFILEUPDATEFAILED);
      this.isLoading = false;
      this.profileSidebar = false;
    }
  }
  
  private showErrorMessage(detail: string) {
    this.messageService.add({
      severity: Severity.ERROR,
      summary: Summary.ERROR,
      detail: detail,
    });
  }
  
  private showWarningMessage(detail: string) {
    this.messageService.add({
      severity: Severity.WARN,
      summary: Summary.VALIDATION,
      detail: detail,
    });
  }
  
  private showSuccessMessage(detail: string) {
    this.messageService.add({
      severity: Severity.SUCCESS,
      summary: Summary.SUCCESS,
      detail: detail,
    });
  }
  
  private updateLocalStorage(data: any) {
    const localStorageData = JSON.parse(localStorage.getItem('user_details') ?? '');
    localStorageData.first_name = data.first_name;
    localStorageData.last_name = data.last_name;
    localStorageData.profile_picture = data.profile_picture;
    localStorage.setItem('user_details', JSON.stringify(localStorageData));
  }
  

  onFileSelected(event: any, purpose: any): void {
    if (event?.target?.files?.[0]) {
      this.imageChanged = true;
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  dataURItoBlob(dataURI: any, type: any) {
    dataURI = dataURI.split('" ')[0];
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: type });
  }

  onClick(options: actions){
    if (options.label === Eglobal.VIEWPROFILE) {
      this.userData = JSON.parse(localStorage.getItem('user_details') ?? '')
      this.profileFields.patchValue(this.userData)
      this.imageChanged = false
      this.profileSidebar = true
      return;
    }
    this.globalService.resetLogin();
  }


  getIconSrc(extension: string): any {
    switch (extension) {
      case 'xlsx':
        return notificationXlsx;
      case 'zip':
        return notificationZip;
      case 'csv':
        return notificationCsv;
      case 'pdf':
        return notificationPdf; 
      case 'docx':
        return notificationDoc; 
    }
  }  

  onOpenDialog() {
    this.openNotificationAction = !this.openNotificationAction;
  }

  async fileAction(item: any) {
    await this.httpService.post('/notifications/update', {
      data: [item]
    });
    if(item.status === 'unread') {
      item.status = 'read';
      this.notificationService.notificationCount -= 1;
    };
    const urls = new URL(item.s3link);
    const objectKey = decodeURIComponent(urls.pathname.substring(1));
    let relativePath = objectKey.replace(/^.*?\//, '');
    const filePath = relativePath;
    const bucketData = {
      bucketName: this.globalService.bucketName,
      fileName: filePath,
    };
    
    const url = await this.globalService.getSignedUrl(bucketData);

    window.open(url.data.response, '_blank');
  }

  async deleteNotification(item: any) {
    this.notificationService.deleteNotification(item);
  };

  async unreadOnly() {
    this.notificationService.unreadOnly(this.showUnRead);
    this.showUnRead = !this.showUnRead;
  };

  async onCloseSideBar() {
    this.profileSidebar = false;
    const signedUrl = await this.globalService.getSignedUrl({ bucketName: this.globalService.bucketName, fileName: this.userData.profile_picture });
    this.selectedImage = signedUrl.data.response;
  }

}
