

// adjust the values as per your requirement - adjust same in the backend as well
export const PassoutYearsOptions = [
    { label: 2024, value: 2024 },
    { label: 2025, value: 2025 },
    { label: 2026, value: 2026 },
    { label: 2027, value: 2027 },
    { label: 2028, value: 2028 },
    { label: 2029, value: 2029 },
    { label: 2030, value: 2030 },
];


export const AdmissionYearOptions = [
    { label: 2024, value: 2024 },
    { label: 2025, value: 2025 },
    { label: 2026, value: 2026 },
    { label: 2027, value: 2027 },
    { label: 2028, value: 2028 },
    { label: 2029, value: 2029 },
    { label: 2030, value: 2030 },
];

export const DegreeTypes = [
    { label: 'UG', value: 'UG' },
    { label: 'PG', value: 'PG' },
    { label: 'PhD', value: 'PhD' },
];