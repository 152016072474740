import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  api_url = environment.HOST_LINK;

  options() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token') ?? '',
      Origin: window.location.origin,
    });
    return { headers };
  }

  constructor(private http: HttpClient) {}

  public async putApiCall(endpoint: any, payload: any): Promise<any> {
    try {
      const response = await firstValueFrom(this.http.put(`${this.api_url}${endpoint}`, payload));
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async postApiCall(endpoint: any, payload: any): Promise<any> {
    try {
      const response = await firstValueFrom(this.http.post(`${this.api_url}${endpoint}`, payload));
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async uploadUsingSignedUrl(endpoint: any, payload: any): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': payload.type,
      }),
      observe: 'response' as 'body',
    };

    try {
      const response = await firstValueFrom(this.http.put(endpoint, payload, httpOptions));
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  getJsonFromUrl(url: string): Observable<any> {
    return this.http.get<any>(url);
  }
}
