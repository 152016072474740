export const environment = {
  production: false,
  // HOST_LINK: 'http://localhost:5000/pat',
  HOST_LINK: 'https://pat.exam.ly/pat',
  NODE_ENV: 'acceptance',
  firebaseConfig: {
    apiKey: "AIzaSyAG9-pIucokWHiV2DxkB8w43DP0ToECCWg",
    authDomain: "examly-dev.firebaseapp.com",
    databaseURL: "https://examly-dev.firebaseio.com",
    projectId: "examly-dev",
    storageBucket: "examly-dev.appspot.com",
    messagingSenderId: "97120710874",
    appId: "1:97120710874:web:83110c4ae6ad8d4aed75e3",
    measurementId: "G-VKCQ4F1TH7"
  },
  vapidKey: 'BLkcXguaEGcepitOsUkGtDD-RfXK9XUBkT3tx9baW4_J_hhJ7_pOnXqBrGtbE9uVpVEOyhPLK2zZDLcQd_rsD54',
  domain: '.iamneo.ai',
  domainType: 'ai',
  sameSite: 'None',
  loginPage: 'pat.iamneo.ai',
  protocol: 'https'
  // domain: '.iamneo.test',
  // domainType: 'test',
  // sameSite: 'Lax',
  // loginPage: 'pat.iamneo.test',
  // protocol: 'http'
};