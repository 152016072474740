export enum Eligibility{
    STUDENTDETAILSFILTER = 'Student Detail Filters',
}

export const ElBtnProperties = {
    clearEligibility: {
        btnName: 'Clear',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-white)',
        border: '1px solid var(--clr-primary)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    saveEligibility: {
        btnName: 'Save',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    viewEligibileStudents: {
        btnName: 'View Eligible Students',
        fontColor: '#326BEE',
        background: '#C4D5FC',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    addCriteria: {
        btnName: 'Add Criteria',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    refresh: {
        btnName: 'Refresh',
        fontColor: '#326BEE',
        background: '#C4D5FC',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    confirm: {
        btnName: 'Confirm',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    cancel: {
        btnName: 'Cancel',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-white)',
        border: '1px solid var(--clr-primary)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
}

export const StudentDetailsFilters = [
    { label: 'Campus', value: 'campus', isActive: true },
    { label: 'Department', value: 'department', isActive: false },
    { label: 'Degree & Specialization', value: 'degreeSpec', isActive: false },
    { label: 'Pass Out Year', value: 'passOutYear', isActive: false },
]


export const PreviewEligibilityKeys = {
    'campus': 'Campus',
    'degree_specialization': 'Degree & Specialization',
    'pass_out_year': 'Pass Out Year',
    'gender': 'Gender',
    'tenth_cgpa': '10th CGPA',
    'tenth_percentage': '10th Percentage',
    'twelfth_cgpa': '12th CGPA',
    'twelfth_percentage': '12th Percentage',
    'ug_cgpa':  'UG CGPA',
    'ug_percentage': 'UG Percentage',
    'group_cgpa': 'Group CGPA',
    'group_percentage': 'Group Percentage',
    'diploma_cgpa': 'Diploma CGPA',
    'diploma_percentage': 'Diploma Percentage',
    'pg_percentage': 'PG Percentage',
    'pg_cgpa': 'PG CGPA',
    'current_backlogs': 'Current Backlogs',
    'backlog_history': 'Backlog History',
    'placedCompany': 'Placed Company',
    'companyCategory': 'Company Category',
    'placedCount': 'Placed Count',
    'salary': 'Salary',
    'stipend': 'Stipend',
    'offerType': 'Offer Type',
    'studentType': 'Student Type'
}