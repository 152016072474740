<div class="table-relative">
    <p-sidebar [(visible)]="showFilter" [transitionOptions]="'0.3ms'" [style]="{padding: '0', border: '2px solid white'}"
        [modal]="false" [baseZIndex]="0" position="left" ariaCloseLabel="Filter" (onHide)="cancelFilterOptions()">
        <ng-template pTemplate="header">
            <h3>{{tableEnum.FILTERHEAD}}</h3>
        </ng-template>
        <ng-template pTemplate="body">
            <div class="filter-body">
                <p-accordion [activeIndex]="[0]" [multiple]="true">
                    <p-accordionTab *ngFor="let section of filterData | keyvalue" header={{section.key}}>
                        <div class="filter-names" *ngFor="let options of section.value">
                            <p-checkbox [(ngModel)]="options.selected"
                                (onChange)="checkFilterOptions($event,section.key , options , filterData)" [binary]="true"
                                inputId="binary"></p-checkbox>
                            <span>{{options.label?options.label:options.name}}</span>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </div>
            <div class="filter-save" [style]="{'gap': '15px','height': '90px' , 'margin-left': '0'}">
                <div class="filter-btn">
                    <button pButton pRipple label="Primary" class="p-button-filter blue-outlined-btn"
                        (click)="cancelFilterOptions()">{{globalEnum.BTNCANCEL}}</button>
                    <button pButton pRipple label="createnew" class="p-button-filter blue-filled-btn"
                        (click)="filtersApply()">{{globalEnum.BTNAPPLY}}
                    </button>
                </div>
            </div>
        </ng-template>
    </p-sidebar>
    <p-sidebar [(visible)]="showList" [transitionOptions]="'0.3ms'" [style]="{padding: '0', border: '2px solid white', height: tableheight}"
        [modal]="false" [baseZIndex]="0" position="left" ariaCloseLabel="Filter">
        <ng-template pTemplate="header">
            <h3>{{leftSidebarName}}</h3>
        </ng-template>
        <ng-template pTemplate="body">
            <div class="column-ctn">
                <div class="download-columns-select-all">
                    <div class="column-name mt-10" *ngIf="leftSidebarName === 'Download Columns'">
                        <p-checkbox [(ngModel)]="downloadColumnSelectAll"
                            (onChange)="downloadColumnAll($event)" [binary]="true" inputId="binary"></p-checkbox>
                        <span>Select All</span>
                    </div>
                </div>
                <ng-container  *ngFor="let col of columns">
                    <div class="column-list" *ngIf="col.header">
                        <div class="column-name">
                            <p-checkbox *ngIf="leftSidebarName === 'Download Columns'" [(ngModel)]="col.selected"
                                (onChange)="checkColumnName(col)" [binary]="true" inputId="binary"></p-checkbox>
                            <p-checkbox *ngIf="leftSidebarName === 'Column List'" [(ngModel)]="col.tableDefault" [binary]="true"
                                inputId="binary" [disabled]="col.freezable"></p-checkbox>
                            <span>{{col.header}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="btn-cnt" *ngIf="leftSidebarName != 'Column List'">
                <div class="filter-save"
                    [style]="leftSidebarName === 'Column List' ? {'gap': '15px','height': '90px'} :  {} ">
                    <div class="filter-text">
                        <span>{{ leftSidebarName === 'Column List' ? 'Save Selected Filters' :
                            'Download as' }}</span>
                    </div>
                    <ng-container
                        *ngIf="leftSidebarName === 'Column List'; then columnList else downloadColumns"></ng-container>
                    <ng-template #columnList>
                        <div class="filter-btn">
                            <button pButton pRipple label="Primary"
                                class="p-button-filter blue-outlined-btn">Cancel</button>
                            <button pButton pRipple label="createnew" class="p-button-filter blue-filled-btn">Apply
                            </button>
                        </div>
                    </ng-template>
                    <ng-template #downloadColumns>
                        <div class="radioButton-group">
                            <div class="radioButton">
                                <p-radioButton value="excel" inputId="excel" [(ngModel)]="downloadColumnAs">
                                </p-radioButton>
                                <label for="excel"
                                    [style]="{color: downloadColumnAs === 'excel' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                                        class="pi pi-file"></em>Excel</label>
                            </div>
                            <div class="radioButton">
                                <p-radioButton value="csv" inputId="csv" [(ngModel)]="downloadColumnAs">
                                </p-radioButton>
                                <label for="csv"
                                    [style]="{color: downloadColumnAs === 'csv' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                                        class="pi pi-file-excel"></em>CSV</label>
                            </div>
                        </div>
                        <div style="margin-left: 5%;">
                            <button style="width: 100%;" pButton pRipple
                                label="downloadColumnsAs" class="p-button-filter blue-filled-btn"
                                (click)="downloadColumnsAs()">Download
                            </button>
                        </div>
                        <div class="filter-text" *ngIf="dlCustomField" (click)="downloadCustomFieldData()">
                            <span style="cursor: pointer;">Download Custom Fields</span>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </p-sidebar>
    <div #leftIcons class="table-aciton-icons"></div>
    <p-table [ngClass]="{'moveTable': showFilter || showList}" #table [(selection)]="selectedOptions" [columns]="columns" [value]="dataList" scrollHeight="{{tableheight}}" scrollDirection="both" [customSort]="captionsList?.custom_sort"
    [resizableColumns]="true" [reorderableColumns]="true" columnResizeMode="expand" [rows]="pageSize" [scrollable]="true" selectionMode="single" [loading]="loadingSpinner" sortMode="single" (sortFunction)="customSort($event)" [sortField]="sortField" [sortOrder]="sortOrder">
        <ng-template pTemplate="caption" *ngIf="captionsList?.header">
        <div class="caption-cont">
                <div  class="left-icons" style="position: relative;">
                    <ng-container *ngIf="captionsList?.actions">
                        <span ><i class="pi pi-ellipsis-v  "
                                (click)="showFilter = false; showList = false ;openAction.toggle($event)" pTooltip="Action" tooltipPosition="top"></i>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="captionsList?.columns">
                        <span><i class="pi pi-sliders-h" (click)="openlist('Column List')"
                                [ngClass]="{'active-icon': leftSidebarName === 'Column List' && showList}" pTooltip="Columns" tooltipPosition="top"></i></span>
                    </ng-container>
                    <ng-container *ngIf="captionsList?.filter">
                        <span><i class="pi pi-filter" (click)="openFilter()"
                                [ngClass]="{'active-icon': showFilter}" pTooltip="Filters" tooltipPosition="top"></i></span>
                    </ng-container>
                    <ng-container *ngIf="captionsList?.upload">
                        <span><i class="pi pi-upload" (click)="tableOptions('upload')"
                                [ngClass]="{'active-icon': leftSidebarName === 'Bulk Update' && showList}" pTooltip="Bulk Update" tooltipPosition="top"></i></span>
                    </ng-container>
                    <ng-container *ngIf="captionsList?.download">
                        <span><i class="pi pi-download" (click)="openDownload('Download Columns')"
                                [ngClass]="{'active-icon': leftSidebarName === 'Download Columns'&& showList}" pTooltip="Download Data" tooltipPosition="top"></i></span>
                    </ng-container>
                    <ng-container *ngIf="captionsList?.sendEmail">
                        <span>
                            <i class="pi pi-send" (click)="clickSendEmail('sendEmail')"
                                [ngClass]="{'active-icon': leftSidebarName === 'sendEmail'&& showList}" pTooltip="Send Message" tooltipPosition="top">
                            </i>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="captionsList?.customPassword">
                        <span><i class="pi pi-lock" (click)="tableOptions('customPassword')"
                                [ngClass]="{'active-icon': leftSidebarName === 'Custom Password'&& showList}" pTooltip="Custom Password" tooltipPosition="top"></i></span>
                    </ng-container>
                    <ng-container *ngIf="captionsList?.setTableDefault">
                        <span pTooltip="Set Table Defaults" tooltipPosition="top">
                            <ng-container>
                                <img 
                                    [src]="setTableDefaultIcon" 
                                    alt="set-table-default" 
                                    (click)="showSetTableDefaults()" 
                                    [ngClass]="{'active-icon': leftSidebarName === 'Set Table Defaults'&& showList}"
                                >
                            </ng-container>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="captionsList?.manual_actions && manually_actions">
                        <span pTooltip="Add Students" tooltipPosition="top">
                            <ng-container>
                                <img 
                                    [src]="addStudentsIcon" 
                                    alt="set-table-default" 
                                    (click)="tableOptions('addStudents')" 
                                    [ngClass]="{'active-icon': leftSidebarName === 'Add Students'&& showList}"
                                >
                            </ng-container>
                        </span>
                        <span pTooltip="Remove Students" tooltipPosition="top">
                            <ng-container>
                                <img 
                                    [src]="removeStudentsIcon" 
                                    alt="set-table-default" 
                                    (click)="tableOptions('removeStudents')" 
                                    [ngClass]="{'active-icon': leftSidebarName === 'Remove Students'&& showList}"
                                >
                            </ng-container>
                        </span>
                    </ng-container>
                    <ng-template [ngIf]="captionsList?.customInput && customInputTemplate">
                        <p-divider layout="vertical" *ngIf="captionsList?.actions || captionsList?.columns || captionsList?.filter || captionsList?.download"></p-divider>
                        <div>
                            <ng-container *ngTemplateOutlet="customInputTemplate"></ng-container>
                        </div>
                    </ng-template>
                    <ng-container *ngIf="captionsList?.search">
                        <div class="search-bar">
                            <em class="pi pi-search" style="font-size: 13px;cursor: pointer"></em>
                            <input #inputRef [(ngModel)]="searchValue" class="input-field" type="text"
                                placeholder="{{searchBy}}"
                                (keyup.enter)="searchValueChange($event)"
                                [style.color]="searchColor ? 'var(--clr-secondary)' : 'var(--clr-black)'"
                                (input)="onSearchChange($event)">
                        </div>
                    </ng-container>
                    <p-divider layout="vertical" *ngIf="captionsList?.search || captionsList?.download || captionsList?.filter || 
                            captionsList?.columns || captionsList?.actions"></p-divider>
                    <div class="totallist-text total-count-size">{{tableEnum.TOTALLIST}} : {{dataCount}}</div>
                    <p-divider *ngIf="selectedOptions.length" layout="vertical"></p-divider>
                    <div *ngIf="selectedOptions.length" class="totallist-text total-selected-size">{{tableEnum.SCOUNT}} :
                        {{selectedAll ? dataCount : selectedOptions.length}}
                    </div>
                </div>
                <ng-container>
                    <div class="right-cont">
                        <div class="flex" *ngIf="captionsList?.limit">   
                            <p [style]="{paddingRight: '5px'}">{{globalEnum.VIEW}}</p>
                            <p-dropdown [options]="pageLimits" [(ngModel)]="pageSize" (onChange)="rowLimit($event)"
                                [style]="{ maxWidth: '6rem',maxHeight: '38px'}"></p-dropdown>
                            <p-divider layout="vertical"></p-divider>
                        </div>
                        <div *ngIf="captionsList?.pagination">    
                            <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                                {{ currentPage }} of {{ totalPage }}
                            </button>
                            <button pButton class="p-button-text p-button-secondary simple-btn"
                                (click)="previousPagination($event)"><i class="pi pi-angle-left"></i></button>
                            <button pButton class="p-button-text p-button-secondary simple-btn"
                                (click)="nextPagination($event)"><i class="pi pi-angle-right"></i></button>
                        </div>
                        <div *ngIf="buttonTemplate" class="floating-right">
                            <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
                        </div>
                        <div class="floating-right" *ngIf="additionalOptions.length">
                            <div class="additional-options" *ngFor="let item of additionalOptions">
                                <p-checkbox 
                                    [binary]="true"
                                    [(ngModel)]="item.value"
                                    (onChange)="additionalOptionOnChange(item)"
                                ></p-checkbox>
                                <span [ngClass]="{'font-light': !item.value}">{{ item.label }}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr pFrozenRow class="selection-operations-row" *ngIf="actionToSelect.length">
                <td  class="selection-operations-options" style="position: fixed !important;">
                    <span *ngIf="dataList.length && isShowSelectedAll && !selectedAll && pageSize < dataCount"
                        class="totallist-text underline total-all-seleced-size" (click)="checkSelectedAll()">
                        {{tableEnum.SELECTALL}} {{dataCount}}
                    </span>
                    <span *ngIf="isShowSelectedAll  && selectedAll && pageSize < dataCount" class="totallist-text underline"
                        (click)="checkSelectedAll()">
                        Clear Selection
                    </span>
                    <div class="dropdown-cont" *ngIf="dataList.length">
                        <ng-container *ngFor="let item of actionToSelect">
                            <ng-container *ngIf="item.item_type === 'button'">
                                <button pButton pRipple (click)="clickAction(item.type)" class="p-button" [ngClass]="{
                                    'active': selectedOptions.length,
                                    'inactive': !selectedOptions.length
                                }">{{item.item_name}}</button>
                            </ng-container>
                            <ng-container *ngIf="item.item_type === 'dropdown'">
                                <p-dropdown [options]="item.options" [(ngModel)]="item.item"
                                    (onChange)="selectedOptionInAction($event , item)" placeholder="{{item.item_name}}"
                                    [autoDisplayFirst]="false" optionLabel="label" [disabled]="!selectedOptions.length">
                                </p-dropdown>
                            </ng-container>
                        </ng-container>
                    </div>
                </td>
            </tr>
            <tr id="targetDiv" class="table-header">
                <th class="no-overflow" *ngIf="captionsList?.checkbox" scope="col" pFrozenColumn >
                    <p-checkbox [(ngModel)]="checkedAll" (onChange)="checkAll($event)" [binary]="true"
                        inputId="binary"></p-checkbox>
                </th>
                <ng-container *ngFor="let col of columns">
                    <ng-container *ngIf="col.freezable; then freezable; else nonfreezable">
                    </ng-container>
                    <ng-template #freezable>
                        <th *ngIf="col.tableDefault" class="hide-hover cell-shadow" [style.width]="col.width"
                            [pSortableColumn]="col.sortable ? col.field : null" pFrozenColumn
                            (mouseenter)="onMouseEnter(col.field)" (mouseleave)="onMouseLeave(col.field)" pReorderableColumn>
                            {{ col.header }}
                            <p-sortIcon *ngIf="col.sortable && sortIconVisible[col.field]" [field]="col.field"></p-sortIcon>
                        </th>
                    </ng-template>
                    <ng-template #nonfreezable>
                        <th *ngIf="col.tableDefault && (col.field != 'offer' && col.field != 'confirmation')" class="hide-hover" [style.width]="col.width"
                            [pSortableColumn]="col.sortable ? col.field : null" (mouseenter)="onMouseEnter(col.field)"
                            (mouseleave)="onMouseLeave(col.field)" pResizableColumn pReorderableColumn>
                            {{ col.header }}
                            <p-sortIcon *ngIf="col.sortable && sortIconVisible[col.field] && col.type !=='status'" [field]="col.field"></p-sortIcon>
                        </th>
                        <!-- <th style="width:11vw" *ngIf="(col.field === 'actions')" pResizableColumn pReorderableColumn>
                            {{ col.header }}
                        </th> -->
                        <th style="width:11vw" *ngIf="(col.field === 'offer' || col.field === 'confirmation') && !isCheckboxSelected" pResizableColumn>
                            {{ col.header }}
                        </th>
                    </ng-template>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <ng-container *ngIf="dataList.length">
                <tr class="table-body" style="border-radius: 5px;" [style]="{color: rowData.error ? 'red !important' : ''}">
                        <td class="no-overflow" *ngIf="captionsList?.checkbox" pFrozenColumn>
                            <p-checkbox (onChange)="checkedrow(rowData)" [(ngModel)]="rowData.selected" [binary]="true"
                                        inputId="binary" class="studentTable"></p-checkbox>
                        </td>
                    <ng-container *ngFor="let col of columns" pReorderableColumn>
                        <ng-container *ngIf="col.freezable; then freezableRow; else nonfreezableRow">
                        </ng-container>
                        <ng-template #freezableRow>
                            <td (click)="onRowClick(rowData)" class="truncate-cell cell-shadow" #textContainer *ngIf="col.tableDefault && (col.type != 'pen_icon')" [style.width]="col.width"
                                pFrozenColumn >
                                <span [pTooltip]="rowData[col.field]"
                                [tooltipPosition]="'top'">
                                    {{ rowData[col.field] ? rowData[col.field] : "-" | fieldLabelPipe }}
                                </span>
                            </td>
                            <td *ngIf="col.type === 'pen_icon'" [style.width]="col.width" pFrozenColumn class="cell-shadow">
                                <ng-container *ngIf="rowData[col.field]">
                                    <img [src]="manuallyAdded" alt="pen_icon">
                                </ng-container>
                                <ng-container *ngIf="!rowData[col.field]">
                                    <div style="width: 16px;"></div>
                                </ng-container>

                            </td>
                        </ng-template>
                        <ng-template #nonfreezableRow>
                            <td (click)="onRowClick(rowData)" class="truncate-cell" #textContainer 
                            *ngIf="col.tableDefault && col.field !== 'change_request' && 
                            (col.type !== 'driveStatus' && col.field != 'offer' && col.type !== 'combine' && col.type !== 'status'&& col.type !== 'verification' && col.type !== 'link' && col.type !== 'republish-btn' &&
                            col.field != 'confirmation' && col.type !== 'dateTime' && col.type !== 'driveCount' && col.type !== 'date' && col.type !== 'dateTimeSeconds')" 
                            [style.width]="col.width"
                            >
                                <span [pTooltip]="rowData[col.field] | displayText"
                                    [tooltipPosition]="'top'"> 
                                    {{ rowData[col.field] | displayText }}
                                </span>
                            </td>
                        </ng-template>

                        <ng-template  *ngIf="col.field === 'change_request' && (rowData['action'] === 'Change Request-Rejected' || rowData['action'] === 'Change Request-Approved')">
                            <td>
                                <span class="pi pi-info-circle" [pTooltip]="'Field Name: ' + rowData['field_name'] + '\n Old Value: ' + rowData['old_value'] + '\n New Value: ' + rowData['new_value']" [tooltipPosition]="'top'"></span>
                            </td>
                        </ng-template>

                        <ng-template class="truncate-cell" [ngIf]="col.type === 'status' && col.tableDefault">
                            <td (click)="onRowClick(rowData)" [style]="{width: col.width}">
                                <span [ngClass]="'student-label ' + rowData[col.field]">
                                    {{ rowData[col.field] || '-' }}
                                </span>
                            </td>
                        </ng-template>  

                        <ng-template class="truncate-cell" [ngIf]="col.type === 'link' && col.tableDefault">
                            <td [style]="{width: col.width }">
                                <div class="view-cont">
                                    <img *ngIf="rowData[col.field]" class="pdf-image" [src]="notificationPdf" alt="pdf-icon">
                                    <span [ngClass]="{'hyper-link': rowData[col.field]}" (click)="onClickFile(rowData, col.field)">{{rowData[col.field] ?
                                        'View' : '-'}}</span>
                                </div>
                            </td>
                        </ng-template>

                        <ng-template [ngIf]="col.type === 'combine' && col.tableDefault" *ngIf="col.tableDefault">
                            <td class="truncate-cell" (click)="onRowClick(rowData)" [style.width]="col.width">
                                <span [pTooltip]="rowData[col.field] | displayText: rowData[col.combineCols]" [tooltipPosition]="'top'"> 
                                    {{ rowData[col.field] | displayText: rowData[col.combineCols] }} 
                                </span>
                            </td>
                        </ng-template>

                        <ng-template class="truncate-cell" [ngIf]="col.type === 'driveCount' && driveCountTemplate && !isCheckboxSelected && col.tableDefault">
                            <td (click)="onRowClick(rowData)" [style]="{width: col.width, padding: col.padding ? col.padding : '0.5rem' }">
                                <ng-container *ngTemplateOutlet="driveCountTemplate ?? null; context: {$implicit: rowData, field: col.field}"></ng-container>
                            </td>
                        </ng-template>

                        <ng-template class="truncate-cell"
                            [ngIf]="col.type === 'republish-btn' && republishTemplate && !isCheckboxSelected && col.tableDefault">
                            <td (click)="onRowClick(rowData)" [style]="{width: col.width, padding: col.padding ? col.padding : '0.5rem' }">
                                <ng-container
                                    *ngTemplateOutlet="republishTemplate ?? null; context: {$implicit: rowData, field: col.field}"></ng-container>
                            </td>
                        </ng-template>
                        
                        <ng-template class="truncate-cell" [ngIf]="col.type === 'dateTimeSeconds' && col.tableDefault">
                            <td (click)="onRowClick(rowData)" [style.width]="col.width">
                                <span> 
                                    {{ rowData[col.field] ? (rowData[col.field] | date: globalService.defaultDateFormatWithTimeSeconds) : "-" }}
                                </span>
                            </td>
                        </ng-template>

                        <ng-template class="truncate-cell" [ngIf]="col.type === 'dateTime' && col.tableDefault">
                            <td (click)="onRowClick(rowData)" [style.width]="col.width">
                                <span> 
                                    {{ rowData[col.field] ? (rowData[col.field] | date: globalService.defaultDateFormatWithTime) : "-" }}
                                </span>
                            </td>
                        </ng-template>

                        <ng-template class="truncate-cell" [ngIf]="col.type === 'date' && col.tableDefault">
                            <td (click)="onRowClick(rowData)" [style.width]="col.width">
                                <span> 
                                    {{ rowData[col.field] ? (rowData[col.field] | date: globalService.defaultDateFormat) : "-" }}
                                </span>
                            </td>
                        </ng-template>

                        <ng-template [ngIf]="col.type === 'driveStatus' && actionTemplate && !isCheckboxSelected && col.tableDefault">
                            <td [style]="{width: col.width, padding: col.padding ? col.padding : '0.5rem' }">
                                <ng-container *ngTemplateOutlet="actionTemplate ?? null; context: {$implicit: rowData, field: col.field}"></ng-container>
                            </td>
                        </ng-template>
                                                
                        <!-- <ng-template [ngIf]="col.field === 'offer' && offerTemplate && !isCheckboxSelected && col.tableDefault">
                            <td [style]="{width: col.width, padding: col.padding ? col.padding : '0.5rem' }">
                                <ng-container *ngTemplateOutlet="offerTemplate; context: {$implicit: rowData}"></ng-container>
                            </td>
                        </ng-template>
                        <ng-template [ngIf]="col.field === 'confirmation' && confirmationTemplate && !isCheckboxSelected && col.tableDefault">
                            <td [style]="{width: col.width, padding: col.padding ? col.padding : '0.5rem' }">
                                <ng-container *ngTemplateOutlet="confirmationTemplate; context: {$implicit: rowData}"></ng-container>
                            </td>
                        </ng-template> -->
                        
                    </ng-container>
                </tr>
            </ng-container>
        </ng-template>
        
        <ng-template pTemplate="emptymessage">
            <tr class="no-record-display" *ngIf="!isLoading">
                <td [style]="{border:'unset'}">No Records Found</td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p-overlayPanel class="no-arrow-panel" #openAction [style]="{ width: '300px' }"
    [appendTo]="leftIcons">
    <div *ngFor="let options of actionOptions">
        <div class="action-option" (click)="onClickAction(options)">{{options.label}}</div>
    </div>
</p-overlayPanel>

  <!-- set table default -->
<p-dialog header="Header" [resizable]="false" [draggable]="false"  [modal]="true" [(visible)]="showSetDefaultDialog" [style]="{ width: '30vw' }">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold header">Set Table Default</span>
    </ng-template>
    <div class="card flex justify-content-center">
        <span>The given filter criteria will be saved as the default listing condition.</span>
    </div>
    <ng-template pTemplate="footer">
        <div class="center-content">
            <button pButton pRipple label="Confirm" class="p-button-filter blue-filled-btn btn-padding" (click)="setTableDefaults()">Confirm</button>
        </div>
    </ng-template>
</p-dialog>