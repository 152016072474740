import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { Breadcrumb } from '@enum';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('../app/module/components/login/login.module').then(m => m.LoginModule),
    canActivate: [],
  },
  {
    path: 'forgot',
    loadChildren: () => import('../app/module/components/login/login.module').then(m => m.LoginModule),
    canActivate: [],
  },
  {
    path: 'students',
    loadChildren: () => import('../app/module/components/students/students.module').then(m => m.StudentsModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.STUDENTS, url: '' },
      ]
    }
  },
  {
    path: 'students/profile',
    loadChildren: () => import('../app/module/components/student-profile/student-profile.module').then(m => m.StudentProfileModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.STUDENTS, url: '/students' },
        { label: "email name", url: '' }
      ]
    }
  },
  {
    path: 'students/change-request',
    loadChildren: () => import('../app/module/components/change-request/change-request.module').then(m => m.ChangeRequestModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.STUDENTS, url: '/students' },
        { label: Breadcrumb.CHANGEREQUEST, url: '' }
      ]
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('../app/module/components/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.SETTINGS, url: '' },
      ]
    }
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('../app/module/components/dashboard/dashboard.module').then(m => m.DashboardModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'eligibility',
    loadChildren: () => import('../app/module/components/eligibility-criteria/eligibility-criteria.module').then(m => m.EligibilityCriteriaModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.ELIGIBILITY, url: '' },
      ]
    }
  },
  {
    path: 'republish-queue',
    loadChildren: () => import('./module/components/republish-queue/republish-queue.module').then(m => m.RepublishQueueModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.REPUBLISHQUEUE, url: '' },
      ]
    }
  },
  {
    path: 'programme-mapping',
    loadChildren: () => import('../app/module/components/programme-offered/programme-offered.module').then(m => m.ProgrammeOfferedModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.PROGRAMMEMAPPING, url: '' },
      ]
    }
  },
  {
    path: 'email-template',
    loadChildren: () => import('../app/module/components/email-template/email-template.module').then(m => m.EmailTemplateModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.EMAILTEMPLATE, url: '' },
      ]
    }
  },
  {
    path: 'custom-message',
    loadChildren: () => import('../app/module/components/custom-message/custom-message.module').then(m => m.CustomMessageModule),
    canActivate: [AuthGuard],
    data: { breadcrumb: [
      { label: Breadcrumb.CUSTOMMESSAGE, url: '/' },
    ] }
  },
  {
    path: 'drives',
    loadChildren: () => import('../app/module/components/drives/drives.module').then(m => m.DrivesModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.DRIVES, url: '' },
      ]
    }
  },
  {
    path: 'drives/drive-details',
    loadChildren: () => import('../app/module/components/drive-details/drive-details.module').then(m => m.DriveDetailsModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.DRIVES, url: '/drives' },
        { label: 'Drive name', url: '' }
      ]
    }
  },
  {
    path: 'jobs',
    loadChildren: () => import('../app/module/components/drives/drives.module').then(m => m.DrivesModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.JOBS, url: '' },
      ]
    }
  },
  {
    path: 'jobs/job-details',
    loadChildren: () => import('../app/module/components/drive-details/drive-details.module').then(m => m.DriveDetailsModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.JOBS, url: '/jobs' },
        { label: 'Job name', url: '' }
      ]
    }
  },
  {
    path: 'redirect',
    loadChildren: () => import('../app/module/components/redirect/redirect.module').then(m => m.RedirectModule),
    canActivate: [],
  },
  {
    path: 'student-resume',
    loadChildren: () => import('../app/module/components/resume-viewer/resume-viewer.module').then(m => m.ResumeViewerModule),
    canActivate: [],
  },
  { path: '**', redirectTo: 'students', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
